import React, { useCallback, useState } from 'react'

import {
  Controller,
  ControllerRenderProps,
  ControllerProps,
  FieldValues,
  Path,
  FieldError,
  ControllerFieldState,
} from 'react-hook-form'

import {
  FrankieIcon,
  FrankieSelectField,
  FrankieTextField,
  IFrankieTextFieldProps,
} from 'frankify/src'

type Props<TFormValues extends FieldValues> = Omit<
  IFrankieTextFieldProps,
  keyof ControllerRenderProps
> &
  Omit<ControllerProps<TFormValues>, 'render'> & {
    showErrorText?: boolean
    countryName: Path<TFormValues>
    countryList: { label: string; value: string }[]
  }

export function PhoneFormField<TFormValues extends FieldValues>({
  name,
  control,
  defaultValue,
  countryName,
  countryList,
  rules,
  shouldUnregister,
  showErrorText = false,
  ...textFieldProps
}: Props<TFormValues>) {
  const [error, setError] = useState<FieldError | undefined>()
  const phoneRender = useCallback(
    ({
      field,
      fieldState: { error },
    }: {
      field: ControllerRenderProps<TFormValues, Path<TFormValues>>
      fieldState: ControllerFieldState
    }) => {
      if (error) setError(error)
      return (
        <FrankieTextField
          {...textFieldProps}
          {...field}
          error={!!error}
          onChange={e => {
            if (e.target.value.match(/^[0-9]*$/)) {
              field.onChange(e)
            } else {
              e.preventDefault()
            }
          }}
          // label already rendered in FrankieSelectField
          label={undefined}
          className="rounded-s-0"
          inputClassName="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50  rounded-l-none border"
        />
      )
    },
    [textFieldProps],
  )
  return (
    <div>
      <div className="flex items-end">
        <Controller
          render={({ field }) => (
            <FrankieSelectField
              // only label is required

              label={textFieldProps.label}
              // for current implementation, we are not allowing user to change the country code
              disabled
              {...field}
              error={!!error}
              options={countryList}
              className="!w-[130px] rounded-e-0 [&_.frankie-select-toggle]:!hidden"
              inputClassName=" block p-2.5 w-full z-20 !pr-0 text-sm text-gray-900 bg-gray-50 border-e-0 rounded-r-none border"
            />
          )}
          control={control}
          name={countryName}
          defaultValue={defaultValue}
          shouldUnregister={shouldUnregister}
        />

        <Controller
          render={phoneRender}
          control={control}
          name={name}
          defaultValue={defaultValue}
          rules={rules}
          shouldUnregister={shouldUnregister}
        />
      </div>
      {showErrorText && error && (
        <div className="grow text-mono-70 pt-2 text-sm">
          <div className="flex flex-initial items-center text-tertiary-red-700">
            <div className="p-0.5 mr-2">
              <FrankieIcon name="mdiInformationOutline" size="sm" />
            </div>
            <div>{error.message}</div>
          </div>
        </div>
      )}
    </div>
  )
}
