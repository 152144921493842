import React, { useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'
import { useNavigate, useOutletContext } from 'react-router-dom'

import { FrankieButton, FrankieLoader, FrankieVerticalTabs } from 'frankify/src'
import { Banner } from 'frankify/src/components/banner'

import {
  useEntityDataQuery,
  useFrankie2R2Customer,
  useGetWorkflowEventsData,
} from 'entities/entity'

import { useTriggerState } from 'shared/hooks'
import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { INDIVIDUAL_PROFILE_INSIGHT_KEY } from '../../individual-profile-insight.key'
import { individualProfileInsightEn } from '../../locale/individual-profile-insight.en'
import { ProfileTabTypes } from '../../model/individual-profile-insight.model'
import { IndividualProfileInsightQa } from '../../qa/individual-profile-insight.qa'
import { useInvalidationBanner } from '../../state/invalidate-banner.state'
import { IndividualProfileAml } from '../individual-profile-aml/individual-profile-aml'
import { IndividualProfileDocument } from '../individual-profile-document/individual-profile-documents'
import { IndividualProfileDocumentR2 } from '../individual-profile-document/individual-profile-documents-f2-r2'
import { IndividualProfilePersonalInfo } from '../individual-profile-personal-info/individual-profile-personal-info'
import { IndividualProfileSelfie } from '../individual-profile-selfie/indvidual-profile-selfie'

type Props = {
  entityId: string
  editEntityPathWithID: string
}

const clickEventMapper = {
  [ProfileTabTypes.personalInfo]:
    TrackingEventsTypes.ProfileInsightsPersonalInfoClickIndividual,
  [ProfileTabTypes.documents]:
    TrackingEventsTypes.ProfileInsightsIdentityDocumentsClickIndividual,
  [ProfileTabTypes.aml]: TrackingEventsTypes.ProfileInsightsAmlViewIndividual,
  [ProfileTabTypes.selfies]:
    TrackingEventsTypes.ProfileInsightsAmlViewIndividual,
}

export function IndividualProfileInsight({
  entityId,
  editEntityPathWithID,
}: Props) {
  const t = useI18n([INDIVIDUAL_PROFILE_INSIGHT_KEY], {
    keys: individualProfileInsightEn,
  })

  const { ref } = useOutletContext<{
    ref: React.RefObject<HTMLDivElement | null>
  }>()

  const [activeTab, setActiveTab] = useState(ProfileTabTypes.personalInfo)

  const [open, setOpen] = useState(true)
  const [scrollDisabled, setScrollDisabled] = useTriggerState(500)

  const navigate = useNavigate()

  const handleTabChange = (value: string) => {
    trackingManager.track(clickEventMapper[value as ProfileTabTypes])

    setActiveTab(value as ProfileTabTypes)
    setScrollDisabled()
  }

  const { data, isLoading: dataLoading } = useEntityDataQuery(
    entityId,
    'base64',
  )

  const isFrankie2R2 = useFrankie2R2Customer()

  const { data: workflowData } = useGetWorkflowEventsData({ entityId })

  const {
    isInvalidationBannerVisible,
    isPersonalInfoInvalid,
    isDocumentInvalid,
    isUnchecked,
    isLoading: isBannerLoading,
  } = useInvalidationBanner({ entityId })

  const tabItemsComponent: Record<
    ProfileTabTypes,
    { comp: React.ReactNode; isHidden: boolean; isInvalidated?: boolean }
  > = useMemo(
    () => ({
      [ProfileTabTypes.personalInfo]: {
        comp: (
          <IndividualProfilePersonalInfo
            entityId={entityId}
            isInvalidated={isPersonalInfoInvalid}
          />
        ),
        isHidden: false,
        isInvalidated: isPersonalInfoInvalid,
      },
      [ProfileTabTypes.documents]: {
        comp: isFrankie2R2 ? (
          <IndividualProfileDocumentR2
            entityId={entityId}
            isInvalidated={isDocumentInvalid}
          />
        ) : (
          <IndividualProfileDocument
            entityId={entityId}
            isInvalidated={isDocumentInvalid}
          />
        ),
        isInvalidated: isDocumentInvalid,
        isHidden: (data?.individual?.documents?.IDENTITY?.length ?? 0) === 0,
      },
      [ProfileTabTypes.selfies]: {
        comp: <IndividualProfileSelfie entityId={entityId} />,
        isHidden: !isFrankie2R2,
      },
      [ProfileTabTypes.aml]: {
        comp: <IndividualProfileAml entityId={entityId} />,
        isInvalidated: isDocumentInvalid,
        isHidden:
          !workflowData?.workflowSummaries?.[0]?.workflowResultData?.workflowStepResults?.find(
            step => step.stepName === 'AML',
          ) || !isFrankie2R2,
      },
    }),

    [
      data,
      isDocumentInvalid,
      isFrankie2R2,
      isPersonalInfoInvalid,
      workflowData,
      entityId,
    ],
  )

  function handleScroll(this: unknown, e: HTMLElementEventMap['scroll']) {
    if (scrollDisabled) return
    // Calculate which section is in view and set it as active
    const scrollPosition = (ref.current?.scrollTop ?? 0) + 200 // adjust offset as needed
    const sections = Object.values(ProfileTabTypes)
    for (const section of sections) {
      const domEl = document.getElementById(section)

      if (domEl) {
        const top = domEl.offsetTop
        const height = domEl.offsetHeight
        if (scrollPosition >= top && scrollPosition < top + height) {
          setActiveTab(section as ProfileTabTypes)
          return
        }
      }
    }
  }

  const isArchived = data?.serviceProfiles?.at(0)?.state === 'ARCHIVED'

  useEffect(() => {
    const divRef = ref.current

    divRef?.addEventListener<'scroll'>('scroll', handleScroll)
    return () => {
      divRef?.removeEventListener('scroll', handleScroll)
    }
  }, [ref, scrollDisabled])

  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.ProfileInsightsViewIndividual)
  }, [])

  const tabItems = useMemo(
    () =>
      Object.keys(ProfileTabTypes)
        .map((key, index) => ({
          value: key,
          label: t(`tab.${key as keyof typeof ProfileTabTypes}`),
          link: `#${ProfileTabTypes[key as keyof typeof ProfileTabTypes]}`,
          component: tabItemsComponent[key as ProfileTabTypes].comp,
          isHidden: tabItemsComponent[key as ProfileTabTypes].isHidden,
          isInvalidated:
            tabItemsComponent[key as ProfileTabTypes].isInvalidated,
          testId: `${IndividualProfileInsightQa.tabItem}-${index}`,
        }))
        .filter(tab => !tab.isHidden),
    [t, tabItemsComponent],
  )

  return (
    <FrankieLoader
      loading={dataLoading || isBannerLoading}
      className="z-20 min-h-full !h-max grow"
    >
      <div
        className="items-start gap-6 flex"
        data-qa={IndividualProfileInsightQa.container}
      >
        <div
          className={`sticky top-0 transition-[width] ease-out duration-200 ${
            !open ? 'min-w-[32px] overflow-hidden' : 'w-[190px] '
          }`}
        >
          <div
            className="flex gap-5 text-xl text-secondary-900 font-bold pb-2"
            data-qa={IndividualProfileInsightQa.title}
          >
            {open && t('title')}
            <div className=" ml-auto ">
              <FrankieButton
                noStyles
                className={`flex p-1.5 justify-center items-center text-tertiary-grey-500
                    w-8 h-8 rounded-sm
                    active:outline 
                    focus-visible:outline 
                    outline-mono-white 
                    focus-visible:outline-3 
                    active:outline-3
                    hover:bg-tertiary-grey-100
                    hover:text-tertiary-grey-800
                    active:bg-primary-50
                    focus-visible:bg-primary-50
                    active:text-tertiary-grey-800
                    focus-visible:text-tertiary-grey-800`}
                singleIcon={{
                  name: open ? 'mdiArrowCollapseLeft' : 'mdiArrowExpandRight',
                  size: 'sm',
                }}
                testId={{ button: IndividualProfileInsightQa.button }}
                onClick={() => setOpen(o => !o)}
              />
            </div>
          </div>
          {open && (
            <FrankieVerticalTabs
              activeTab={activeTab}
              onTabChange={handleTabChange}
              tabItems={tabItems}
            />
          )}
        </div>
        <div className="flex flex-col max-w-[calc(100%-214px)]">
          {isArchived && (
            <Banner
              heading={t('banner.archivedTitle')}
              theme="info"
              className="!pb-1.5"
            />
          )}
          {isInvalidationBannerVisible && (
            <Banner
              heading={t('banner.title')}
              description={t('banner.description')}
              theme="warning"
              className="border-0"
              button={{
                name: 'generate-report',
                label: t('banner.button'),
                onClick: () => !isArchived && navigate(editEntityPathWithID),
              }}
            />
          )}

          {isUnchecked && isFrankie2R2 && (
            <Banner
              heading={t('banner.titleUnchecked')}
              description={t('banner.descriptionUnchecked')}
              theme="warning"
              className="border-0"
              button={{
                className: '!mb-0',
                name: 'verify-profile',
                label: t('banner.button'),
                onClick: () => !isArchived && navigate(editEntityPathWithID),
              }}
            />
          )}

          <div
            className=" flex flex-col gap-y-6"
            data-qa={IndividualProfileInsightQa.tabItemContainer}
          >
            {tabItems.map(tab => (
              <div
                className={classNames(
                  'border p-6 pt-5 rounded-sm border-tertiary-grey-200 border-solid',
                  tab.isInvalidated && '!border-tertiary-yellow-300',
                )}
                id={tab.value}
              >
                {tab.component}
              </div>
            ))}
          </div>
        </div>
      </div>
    </FrankieLoader>
  )
}
