import {
  AmlIssuesTypes,
  ProcessResultManualStatusEnumAML,
  useEntityAmlData,
} from 'entities/entity'

import {
  ProfileAMLTileTypes,
  amlIssuesToSupplementaryDataMap,
} from '../model/individual-profile-insight.model'

type Props = {
  entityId: string
}

export const useAmlProfileState = ({ entityId }: Props) => {
  const { data } = useEntityAmlData({ entityId })

  const countOfIssues: ProfileAMLTileTypes = {
    [AmlIssuesTypes.PEP]: {
      TRUE_POSITIVE: 0,
      UNKNOWN: 0,
    },
    [AmlIssuesTypes.SANCTION]: {
      TRUE_POSITIVE: 0,
      UNKNOWN: 0,
    },
    [AmlIssuesTypes.WATCH_LIST]: {
      TRUE_POSITIVE: 0,
      UNKNOWN: 0,
    },
    [AmlIssuesTypes.ADVERSE_MEDIA]: {
      TRUE_POSITIVE: 0,
      UNKNOWN: 0,
    },
  }

  for (const pro of data.processResults || []) {
    if (pro.supplementaryData?.type === 'AML') {
      for (const amlIssues in amlIssuesToSupplementaryDataMap) {
        if (
          (pro.supplementaryData[
            amlIssuesToSupplementaryDataMap[amlIssues as AmlIssuesTypes]
          ]?.length ?? 0) > 0
        ) {
          const issues = countOfIssues[amlIssues as AmlIssuesTypes]
          const status = pro.manualStatus

          if (status?.includes(ProcessResultManualStatusEnumAML.TRUE_POSITIVE))
            issues[ProcessResultManualStatusEnumAML.TRUE_POSITIVE] += 1

          if (status?.includes(ProcessResultManualStatusEnumAML.UNKNOWN))
            issues[ProcessResultManualStatusEnumAML.UNKNOWN] += 1
        }
      }
    }
  }

  return { countOfIssues }
}
