import React from 'react'

import {
  IPartialProfileFilter,
  ProfilesFilterForm,
} from 'features/profiles-filter'
import { useUserListQuery } from 'features/user-management'

import { ProfileStateTypes, WorkflowStatusKeysTypes } from 'entities/entity'
import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'

type Props = {
  onClose: () => void
  initialValues: IPartialProfileFilter
  onSetFilters: (filter: IPartialProfileFilter) => void
  statesOptionCols: ProfileStateTypes[][]
  workflowStatesOptionCols: WorkflowStatusKeysTypes[]
}

export function ProfilesFilterModal({
  statesOptionCols,
  workflowStatesOptionCols,
  onClose,
  onSetFilters,
  initialValues,
}: Props) {
  const { canFetchUserList } = useHasPermission({
    canFetchUserList: PermissionTypes.UserList,
  })
  const { data: userList } = useUserListQuery({ canFetchUserList })

  return (
    <div className="max-h-[calc(100vh-120px)]">
      <ProfilesFilterForm
        statesOptionCols={statesOptionCols}
        workflowStatesOptionCols={workflowStatesOptionCols}
        users={userList || []}
        onClose={onClose}
        onSubmit={onSetFilters}
        initialValues={initialValues}
      />
    </div>
  )
}
