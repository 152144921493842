export const customViewEn = {
  customViewSaveModal: {
    saveView: 'Save view',
    leftViewTitle: 'Access this saved custom view from the side navigation. ',
    leftViewText: 'You have <span>{{dataLength}}</span> views left to create.',
    viewNameLabel: 'View name',
    characterLeft: '{{leftCharacter}} characters left',
    cancel: 'Cancel',
    save: 'Save view',
  },
  saveFilter: {
    saveTitle: 'Save changes in current view',
    saveSubtile: 'Access this saved custom view from the side navigation.',
    submitBtn: 'Save changes',
  },
  saveNewView: {
    saveNewTitle: 'Save as new view',
  },
  deleteView: 'Delete view',
  deleteModal: {
    title: 'Are you sure you want to delete this view?',
    cancel: 'No, cancel',
    confirm: 'Yes, I’m sure',
  },

  saveChanges: 'Save changes',
  saveNewChange: 'Save as new view',
  saveView: 'Save view',
  limitReachedTitle: 'Custom view limit reached',

  loading: {
    onSave: 'Saving custom view',
    onDelete: 'Deleting custom view',
    onUpdate: 'Updating custom view',
    onLoad: 'Loading custom views',
  },
  customView: 'CUSTOM VIEWS',
  tooltip: {
    limitReached: {
      customViewTitle: 'Maximum custom view reached',
      subTitle: 'Delete an existing custom view to create a new one',
    },
    title: 'Create new view',
  },
  notification: {
    viewCreated: 'Custom View "{{name}}" has been successfully saved.',
    viewUpdated: 'Custom view has been updated successfully.',
    viewDeleted: 'Custom view has been successfully deleted.',
    error: 'Something went wrong, please try again.',
  },
  applyAllAndSaveView: 'Apply filters and save view',
  sameViewErrorText: 'View name already exists',
}
