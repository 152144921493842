import React from 'react'

import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_AML_RESULT_KEY } from '../../../individual-aml-result.key'
import { individualAmlResultEn } from '../../../locale/individual-aml-result.en'
import { amlPersonalInfoQa } from '../../../qa/individual-aml-result.qa'

export function IndividualAmlPersonalInfo() {
  const t = useI18n(INDIVIDUAL_AML_RESULT_KEY, {
    keys: individualAmlResultEn,
  })

  return (
    <div
      className="pt-5 shrink flex flex-col gap-4 w-full"
      data-qa={amlPersonalInfoQa.container}
    >
      <div className="flex shrink gap-2.5">
        <div
          data-qa={amlPersonalInfoQa.title}
          className="text-tertiary-grey-700 font-bold text-md leading-6"
        >
          {t('individualAmlMatchData.title')}
        </div>

        <div
          data-qa={amlPersonalInfoQa.matchStrength}
          className="bg-tertiary-red-100 py-[2px] px-[10px] rounded-full font-semibold text-xs leading-4 text-tertiary-red-600 flex items-center flex-grow-0"
        >
          {t('individualAmlMatchData.matchStrength', { matchStrength: 80 })}
        </div>
      </div>

      <div className="grid grid-flow-col !auto-rows-auto  gap-3 grid-rows-[repeat(6,_auto)] laptop:grid-rows-[repeat(3,_auto)] w-full ">
        <div className="flex  gap-2.5">
          <div
            data-qa={amlPersonalInfoQa.sourceLabel}
            className="min-w-[170px] font-medium text-sm leading-5 text-tertiary-grey-800 "
          >
            {t('individualAmlResolveSingleSummary.fullName')}
          </div>
          <div
            data-qa={amlPersonalInfoQa.sourceValue}
            className="text-sm leading-5 text-tertiary-grey-800 pr-5"
          >
            Janet Citizen
          </div>
        </div>
        <div className="flex  gap-2.5">
          <div className="font-medium min-w-[170px] text-sm leading-5 text-tertiary-grey-800 ">
            {t('individualAmlResolveSingleSummary.aliases')}
          </div>
          <div className="text-sm leading-5 text-tertiary-grey-800 pr-5">
            Janet Smith-Citizen, Jane Citizen, Jane Nicole, Jane Nicole
            Elizabeth Citizen, Mary Jane Citizen
          </div>
        </div>
        <div className="flex  gap-2.5">
          <div className="font-medium min-w-[170px] text-sm leading-5 text-tertiary-grey-800 ">
            {t('individualAmlResolveSingleSummary.yearOfBirth')}
          </div>
          <div className="text-sm leading-5 text-tertiary-grey-800 pr-5">
            1980
          </div>
        </div>
        <div className="flex gap-2.5">
          <div className="min-w-[140px]  font-medium text-sm leading-5 text-tertiary-grey-800">
            {t('individualAmlResolveSingleSummary.countryOfResidence')}
          </div>
          <div className="text-sm leading-5 text-tertiary-grey-800">AU</div>
        </div>

        <div className="flex gap-2.5">
          <div className="min-w-[140px] font-medium text-sm leading-5 text-tertiary-grey-800">
            {t('individualAmlResolveSingleSummary.address')}
          </div>
          <div className="text-sm leading-5 text-tertiary-grey-800 pr-5">
            121 King St, Greenway ACT 2900, Australia
          </div>
        </div>

        <div className="flex gap-2.5">
          <div className="min-w-[140px] font-medium text-sm leading-5 text-tertiary-grey-800">
            {t('individualAmlResolveSingleSummary.associates')}
          </div>
          <div className="text-sm leading-5 text-tertiary-grey-800 pr-5">
            Associate of jane citizen, Associate of Amanda Hailey Citizen,
            Associate of Citizen Macbeth,
          </div>
        </div>
      </div>
    </div>
  )
}
