import { I18nKeys } from 'shared/i18n'

import { individualAmlResultEn } from '../locale/individual-aml-result.en'

export enum TabItemTypes {
  PEP = 'pep',
  SANCTIONS = 'sanctions',
  WATCHLIST = 'watchlist',
  ADVERSE_MEDIA = 'adverse-media',
  OTHER_DATA_SOURCES = 'other-data-sources',
}
export type TabItem = {
  tKey: I18nKeys<typeof individualAmlResultEn>
  value: TabItemTypes
}

export const tabItems: TabItem[] = [
  {
    tKey: 'tabsItem.pep',
    value: TabItemTypes.PEP,
  },
  {
    tKey: 'tabsItem.sanctions',
    value: TabItemTypes.SANCTIONS,
  },
  {
    tKey: 'tabsItem.watchlist',
    value: TabItemTypes.WATCHLIST,
  },
  {
    tKey: 'tabsItem.adverseMedia',
    value: TabItemTypes.ADVERSE_MEDIA,
  },
  {
    tKey: 'tabsItem.otherDataSources',
    value: TabItemTypes.OTHER_DATA_SOURCES,
  },
]
