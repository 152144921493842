import React, { useState } from 'react'

import classNames from 'classnames'

import {
  FrankieButton,
  FrankieIcon,
  FrankieIconName,
  FrankiePopover,
} from 'frankify/src'

export type MenuOptionType<TValue extends string> = {
  label: string
  value: TValue
  testId?: string
  disable?: boolean
  className?: string
  description?: string
  descriptionClassName?: string
}

type Props<TValue extends string> = {
  options: MenuOptionType<TValue>[]
  onSelect: (value: TValue) => void
  children?: React.ReactNode
  buttonClassName?: string
  icons?: {
    open: FrankieIconName
    close?: FrankieIconName
  }
  disabled?: boolean
  onFocusClassName?: string
  testId?: {
    button?: string
  }
  optionsClassName?: string
}

export function Menu<TValue extends string>({
  options,
  onSelect,
  children,
  buttonClassName,
  icons,
  disabled,
  onFocusClassName = undefined,
  testId,
  optionsClassName,
}: Props<TValue>) {
  const [isPopoverOpen, togglePopover] = useState(false)

  const handleContextMenuButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation()
    e.preventDefault()
    togglePopover(prev => !prev)
  }

  const handleClick = (value: TValue) => {
    onSelect(value)
    togglePopover(false)
  }

  return (
    <FrankiePopover
      onOpenChange={togglePopover}
      open={isPopoverOpen}
      popoverRest={{ placement: 'bottom-end' }}
      trigger={
        <FrankieButton
          intent="subtle"
          size="xs"
          disabled={disabled}
          className={classNames(
            '!rounded-full !max-w-[32px]',

            {
              '!bg-primary-50 border-transparent': isPopoverOpen,
            },
            `${isPopoverOpen && onFocusClassName ? onFocusClassName : ''}`,
            buttonClassName,
          )}
          testId={{ button: testId?.button }}
          onClick={handleContextMenuButtonClick}
        >
          {children || <FrankieIcon name="mdiDotsHorizontal" />}
          {!disabled && icons && (
            <FrankieIcon
              name={isPopoverOpen ? icons.open : icons.close || icons.open}
              size="sm"
            />
          )}
        </FrankieButton>
      }
    >
      {options.map(
        (
          {
            label,
            value,
            testId,
            className,
            disable,
            description,
            descriptionClassName,
          },
          ind,
        ) => (
          <div
            className={`bg-mono-white w-full shadow-md ${
              optionsClassName ?? ''
            }`}
            key={`${value}-${ind.toString()}`}
          >
            <div
              className={`px-4 w-full py-2 !text-left hover:bg-tertiary-grey-200 select-none  ${
                className ?? ''
              }`}
            >
              <FrankieButton
                onClick={e => {
                  e.stopPropagation()
                  handleClick(value)
                }}
                disabled={disable}
                noStyles
                testId={{ button: testId }}
              >
                {label}
              </FrankieButton>
              {description && (
                <div className={`text-xs cus ${descriptionClassName ?? ''}`}>
                  {description}
                </div>
              )}
            </div>
          </div>
        ),
      )}
    </FrankiePopover>
  )
}
