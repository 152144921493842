import React, { useEffect, useState } from 'react'

import {
  DataGridPro,
  GridColDef,
  GridFilterPanel,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortItem,
} from '@mui/x-data-grid-pro'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { FrankieButton } from 'frankify/src'

import { AmlStatusChangeForm } from 'entities/entity'
import { useApplicantRoute } from 'entities/routing'
import { useSessionQuery } from 'entities/session'
import { getUserList } from 'entities/user'

import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import {
  AvatarCell,
  BadgeCell,
  BadgeListCell,
  DateCell,
  NameCell,
} from 'shared/tables-common'
import { GridCheckbox } from 'shared/tables-common/ui/grid-checkbox/grid-checkbox'

import { FilterComponent } from './individual-aml-filter/individual-aml-filter-component/individual-aml-filter-component'
import { INDIVIDUAL_AML_SCREENING_KEY } from '../../individual-aml-screening.key'
import { individualAmlScreeningEn } from '../../locale/individual-aml-screening.en'
import {
  AmlFilters,
  amlIssuesToI18n,
  getMatchStatusBadge,
} from '../../model/individual-aml-screening/individual-aml-screening.model'
import { useAmlPRORowData } from '../../state/individual-aml-screening.state/individual-aml-screening.state'
import { IndividualAmlNoRowPlaceholder } from '../individual-aml-no-row-placeholder/individual-aml-no-row-placeholder'
import {
  EmptyOverlay,
  MatchStrengthCell,
  PaginationWithPageSize,
  RenderHeaderCellWithPagination,
  SortModel,
} from '../individual-aml-screening-data-grid-helper/individual-aml-screening-data-grid-helper'

const headerClassName =
  '!p-0 text-xs !pr-4 !py-2 !h-auto !whitespace-break-spaces font-medium uppercase bg-tertiary-grey-50 text-tertiary-grey-500 !outline-none'
const cellClassName = '!outline-none'

type Props = {
  entityId: string
  workflowExecutionId?: string
  defaultOffset?: number
}

// eslint-disable-next-line complexity
export function IndividualAmlScreeningDataGrid({
  entityId,
  workflowExecutionId,
  defaultOffset,
}: Props) {
  const navigate = useNavigate()

  const { generateRoute } = useApplicantRoute()
  const TABLE_MIN_WIDTH = 640 + (workflowExecutionId ? 100 : 0)

  const [sortModel, setSortModel] = useState<SortModel>([
    { field: 'resolvedAt', sort: null },
    { field: 'matchStatus', sort: 'desc' },
    { field: 'matchStrength', sort: null },
  ])

  const { data: pageData } = useSessionQuery()
  const userList = getUserList(pageData)

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: defaultOffset ?? 5,
    page: 0,
  })

  const { state } = useLocation()

  const defaultFilters: Partial<AmlFilters> | undefined = state

  const { control, reset, watch } = useForm<AmlFilters>({
    defaultValues: {
      issues: [],
      amlMatch: [],
      matchStatus: [],
      workflow: [],
    },
  })

  useEffect(() => {
    if (defaultFilters) {
      reset({
        issues: defaultFilters.issues ?? [],
        amlMatch: defaultFilters.amlMatch ?? [],
        matchStatus: defaultFilters.matchStatus ?? [],
        workflow: defaultFilters.workflow ?? [],
      })
    }
  }, [reset, defaultFilters])

  const filters = watch()
  const { rows } = useAmlPRORowData({
    entityId,
    workflowExecutionId,
    filters,
  })

  const t = useI18n([INDIVIDUAL_AML_SCREENING_KEY], {
    keys: individualAmlScreeningEn,
  })

  const columns: GridColDef<(typeof rows)[0]>[] = [
    {
      field: 'name',
      headerName: t('headers.amlMatch'),
      minWidth: 120,
      flex: 120 / TABLE_MIN_WIDTH,
      renderCell: params => {
        const { row } = params
        const { name, countries } = row
        return (
          <NameCell
            title={name ?? ''}
            subtitle={countries?.join(' ') ?? ''}
            className="!pl-0"
          />
        )
      },
    },
    {
      field: 'matchStatus',
      headerName: t('headers.matchStatus'),
      minWidth: 100,
      flex: 100 / TABLE_MIN_WIDTH,
      sortable: true,
      renderHeader: WithProps(RenderHeaderCellWithPagination, { sortModel }),
      renderCell: params => (
        <BadgeCell
          {...getMatchStatusBadge(t, params.row.matchStatus)}
          className="!pl-0 !whitespace-break-spaces"
        />
      ),
    },
    {
      field: 'issues',
      headerName: t('headers.issues'),
      minWidth: 120,
      flex: 120 / TABLE_MIN_WIDTH,
      renderCell: params => (
        <BadgeListCell
          badges={params.row.issues.map(i => t(amlIssuesToI18n[i]))}
          className="!pl-0"
        />
      ),
    },
    {
      field: 'matchStrength',
      headerName: t('headers.matchStrength'),
      minWidth: 100,
      flex: 100 / TABLE_MIN_WIDTH,
      sortable: true,
      renderHeader: WithProps(RenderHeaderCellWithPagination, { sortModel }),
      renderCell: MatchStrengthCell,
    },
    ...(!workflowExecutionId
      ? [
          {
            field: 'workflow',
            headerName: t('headers.workflow'),
            minWidth: 100,
            flex: 100 / TABLE_MIN_WIDTH,
          },
        ]
      : []),
    {
      field: 'resolvedAt',
      headerName: t('headers.update'),
      minWidth: 90,
      flex: 90 / TABLE_MIN_WIDTH,
      sortable: true,

      renderHeader: WithProps(RenderHeaderCellWithPagination, { sortModel }),
      renderCell: params => (
        <DateCell date={params.row.resolvedAt ?? ''} className="!pl-0" />
      ),
    },

    {
      field: 'resolvedBy',
      headerName: t('headers.resolvedBy'),
      minWidth: 89,
      flex: 89 / TABLE_MIN_WIDTH,

      renderCell: params => {
        const userFromList = userList.find(
          user => user.email === params.row.resolvedBy,
        )
        return <AvatarCell name={userFromList?.realname} />
      },
    },
  ]

  const getRowClassName = () => 'hover:bg-tertiary-grey-200'
  const [createOverlay, closeOverlay] = useOverlay()

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([])

  const handleUpdateAlert = () => {
    createOverlay(
      <AmlStatusChangeForm
        closeOverlay={() => {
          setSelectedRows([])
          closeOverlay()
        }}
        restProcessResultIds={rows
          .map(row => row.id)
          .filter(id => !selectedRows.includes(id))}
        processResultId={selectedRows as string[]}
        entityId={entityId}
      />,
      {
        closeButtonClassName: '!top-6 !right-5',
        className: '!p-[20px]',
      },
    )
  }
  const handleSelectionModelChange = (newSelection: GridRowSelectionModel) => {
    setSelectedRows(newSelection)
  }

  const handleSelectAllClick = () => {
    if (selectedRows.length === rows.length) {
      setSelectedRows([])
    } else {
      const allRowIds = rows.map(row => row.id)
      setSelectedRows(allRowIds as GridRowSelectionModel)
    }
  }

  const onRowClick = ({ row }: GridRowParams<(typeof rows)[0]>) =>
    navigate(
      `${generateRoute({
        routeKey: 'resolveAmlIssue',
        overrideParams: { entityId },
      })}?processResultId=${row.id}`,
    )

  const isSelectAll = selectedRows.length === rows.length
  const amlKeys = ['amlMatch', 'matchStatus', 'issues', 'workflow'] as const

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex flex-col gap-4 grow-0">
        <div className="font-semibold text-tertiary-grey-800 leading-[24px] text-left flex flex-col gap-1">
          {t('returnedResult')}
          {selectedRows.length > 0 &&
            selectedRows.length < paginationModel.pageSize && (
              <span className="text-tertiary-grey-500 leading-4 text-xs mb-2">
                {t('dataGrid.selectedRowText', {
                  amlCount: selectedRows.length,
                })}
              </span>
            )}
          <div className="flex justify-between">
            <div className="flex gap-2 grow-0">
              {amlKeys.map(amlKey => (
                <FilterComponent
                  key={amlKey}
                  amlKey={amlKey}
                  name={amlKey}
                  control={control}
                  watch={watch}
                  show={amlKey !== 'workflow' || !workflowExecutionId}
                />
              ))}
            </div>
            <FrankieButton
              intent="primary"
              noStyles
              className={`${
                !selectedRows.length
                  ? 'text-tertiary-grey-400'
                  : 'text-primary-800'
              } font-semibold text-sm leading-5`}
              disabled={!selectedRows.length}
              onClick={handleUpdateAlert}
            >
              {t('updateAlert')}
            </FrankieButton>
          </div>
        </div>

        {selectedRows.length >= paginationModel.pageSize && (
          <div className="bg-primary-container text-sm p-4 leading-5 rounded-1.5 flex items-center justify-center w-full">
            <span>
              {isSelectAll
                ? t('amlBanner.allRowSelected', { rowLength: rows.length })
                : t('amlBanner.title', {
                    rowLength: selectedRows.length,
                  })}{' '}
              <FrankieButton
                noStyles
                className="text-primary-800 cursor-pointer"
                onClick={handleSelectAllClick}
              >
                {isSelectAll
                  ? t('amlBanner.clearRow')
                  : t('amlBanner.selectAll', { total: rows.length })}
              </FrankieButton>
            </span>
          </div>
        )}
      </div>

      {rows.length === 0 ? (
        <div className="text-tertiary-grey-700  text-left">
          <IndividualAmlNoRowPlaceholder />
        </div>
      ) : (
        <DataGridPro
          rows={rows}
          onRowSelectionModelChange={id => handleSelectionModelChange(id)}
          rowSelectionModel={selectedRows}
          slots={{
            baseCheckbox: GridCheckbox,
            noRowsOverlay: EmptyOverlay,
            loadingOverlay: EmptyOverlay,
            filterPanel: GridFilterPanel,
            pagination: WithProps(PaginationWithPageSize, {
              totalCount: rows.length,
              count: Math.ceil(rows.length / paginationModel.pageSize),
              page: paginationModel.page + 1,
              pageSize: paginationModel.pageSize,
              onChange: (_val, page) => {
                setPaginationModel(prev => ({ ...prev, page: page - 1 }))
              },
              onChangePageSize: pageSize => {
                setPaginationModel({ pageSize, page: 0 })
              },
            }),
          }}
          sortingMode="client"
          getRowClassName={getRowClassName}
          getCellClassName={() => cellClassName}
          checkboxSelection
          className="
           [&_.MuiDataGrid-virtualScrollerContent]:border-neutral-30 [&_.MuiDataGrid-footerContainer]:border-none [&_.MuiDataGrid-columnHeadersInner]:bg-tertiary-grey-50 [&_.MuiDataGrid-columnHeaderTitle]:text-xs [&_.MuiDataGrid-columnHeaderTitle]:!whitespace-break-spaces  [&_.MuiDataGrid-columnHeaderTitle]:font-medium [&_.MuiDataGrid-columnHeadersInner]:text-tertiary-grey-500  [&_.MuiDataGrid-columnHeader]:!outline-none  "
          columns={columns.map(column => ({
            disableReorder: true,
            disableColumnMenu: true,
            hideSortIcons: true,
            resizable: false,
            sortable: false,
            headerClassName,
            cellClassName,
            ...column,
          }))}
          pageSizeOptions={[5, 10, 20]}
          paginationMode="client"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          rowHeight={68}
          columnHeaderHeight={34}
          columnBuffer={0}
          disableRowSelectionOnClick
          onRowClick={onRowClick}
          disableColumnSelector
          // disableColumnFilter
          disableColumnMenu
          disableDensitySelector
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSortModelChange={model => setSortModel(model)}
          sortModel={sortModel as GridSortItem[]}
          checkboxSelectionVisibleOnly
          pagination
          hideFooterSelectedRowCount
          sx={{
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              bgcolor: 'transparent',
            },
            '& .MuiDataGrid-row.Mui-selected:hover': {
              bgcolor: 'transparent',
            },
            '&.MuiDataGrid-root': {
              border: 'none',
            },
            '.MuiDataGrid-columnHeaders': {
              minHeight: 'unset !important',
              maxHeight: 'unset !important',
              lineHeight: 'unset !important',
              borderRadius: '0 !important',
            },
            '& .MuiDataGrid-columnHeaderTitleContainerContent': {
              height: '100%',
            },
            '.MuiDataGrid-cell': {
              minHeight: 'unset !important',
              maxHeight: 'unset !important',
              lineHeight: 'unset !important',
              padding: '0 !important',
            },
          }}
        />
      )}
    </div>
  )
}
