import React from 'react'

import { Trans } from 'react-i18next'

import { useI18n } from 'shared/i18n'

import { SUPRSEND_KEY, suprsendEn } from '../../../locale/en'
import { NotificationEventTypes } from '../../../model/suprsend-notifications.model'

export function Message({
  entityId,
  name,
  event,
  status,
  documentName,
}: {
  entityId: string
  name: string
  event?: string
  status?: string
  documentName?: string
}) {
  const t = useI18n([SUPRSEND_KEY], { keys: suprsendEn })

  const isBatched = entityId.includes(',')

  const applicantName = name && name !== '<empty>' ? name : entityId

  if (isBatched) {
    if (event === NotificationEventTypes.USER_ASSIGNED) {
      return (
        <Trans components={[<strong className="data-hj-suppress" />]}>
          {t('message.userAssignedBatched', {
            applicantName: entityId.split(',').length - 1,
          })}
        </Trans>
      )
    }

    if (
      [NotificationEventTypes.ENTITY_STATUS_UPDATED].includes(
        event as NotificationEventTypes,
      )
    ) {
      return (
        <Trans components={[<strong className="data-hj-suppress" />]}>
          {t('message.entityStatusUpdatedBatched', {
            applicantName: entityId.split(',').length - 1,
            status,
          })}
        </Trans>
      )
    }
  }

  if (event === NotificationEventTypes.USER_ASSIGNED) {
    return (
      <Trans components={[<strong className="data-hj-suppress" />]}>
        {t('message.userAssigned', { applicantName })}
      </Trans>
    )
  }

  if (
    [NotificationEventTypes.ENTITY_STATUS_UPDATED].includes(
      event as NotificationEventTypes,
    )
  ) {
    return (
      <Trans components={[<strong className="data-hj-suppress" />]}>
        {t('message.entityStatusUpdated', {
          applicantName,
          status,
        })}
      </Trans>
    )
  }
  if (NotificationEventTypes.TRUST_ANALYSIS_COMPLETE === event) {
    return (
      <Trans
        components={[<span className="leading-tight text-sm font-semibold" />]}
      >
        {t('message.trustAnalysisComplete', { documentName })}
      </Trans>
    )
  }
  return <div>{event}</div>
}
