import React, { useState } from 'react'

import classNames from 'classnames'

import {
  FrankieButton,
  FrankieIcon,
  FrankieIconName,
  FrankiePopover,
} from 'frankify/src'

type Props<TValue extends string> = {
  options: { label: string; value: TValue; testId?: string }[]
  onSelect: (value: TValue) => void
  children?: React.ReactNode
  buttonClassName?: string
  icons?: {
    open: FrankieIconName
    close?: FrankieIconName
  }
  disabled?: boolean
  onFocusClassName?: string
  testId?: {
    button?: string
  }
}

export function Menu<TValue extends string>({
  options,
  onSelect,
  children,
  buttonClassName,
  icons,
  disabled,
  onFocusClassName = undefined,
  testId,
}: Props<TValue>) {
  const [isPopoverOpen, togglePopover] = useState(false)

  const handleContextMenuButtonClick = () => togglePopover(prev => !prev)

  const handleClick = (value: TValue) => {
    onSelect(value)
    togglePopover(false)
  }

  return (
    <FrankiePopover
      onOpenChange={togglePopover}
      open={isPopoverOpen}
      popoverRest={{ placement: 'bottom-end' }}
      trigger={
        <FrankieButton
          intent="subtle"
          size="xs"
          disabled={disabled}
          className={classNames(
            '!rounded-full !max-w-[32px]',

            {
              '!bg-primary-50 border-transparent': isPopoverOpen,
            },
            `${isPopoverOpen && onFocusClassName ? onFocusClassName : ''}`,
            buttonClassName,
          )}
          testId={{ button: testId?.button }}
          onClick={handleContextMenuButtonClick}
        >
          {children || <FrankieIcon name="mdiDotsHorizontal" />}
          {!disabled && icons && (
            <FrankieIcon
              name={isPopoverOpen ? icons.open : icons.close || icons.open}
              size="sm"
            />
          )}
        </FrankieButton>
      }
    >
      {options.map(({ label, value, testId }, ind) => (
        <div
          className="bg-mono-white w-full shadow-md"
          key={`${value}-${ind.toString()}`}
        >
          <FrankieButton
            className="px-4 w-full py-2 !text-left hover:bg-tertiary-grey-200 select-none cursor-pointer"
            onClick={() => handleClick(value)}
            noStyles
            testId={{ button: testId }}
          >
            {label}
          </FrankieButton>
        </div>
      ))}
    </FrankiePopover>
  )
}
