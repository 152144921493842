import React from 'react'

import { FrankieIcon, FrankieBadge, FrankieTooltip } from 'frankify/src'

import {
  ILabelAndSources,
  ProcessResultManualStatusEnumAML,
  WorkflowStepResultEnum,
} from 'entities/entity'

import { useI18n } from 'shared/i18n'
import { convertToLowerCaseExceptFirst } from 'shared/string'

import { workflowEventsEn } from '../../locale/workflow-events.en'
import {
  failVariants,
  failVariantsAml,
  passVariants,
  passVariantsAml,
  reviewVariants,
} from '../../model/applicant-workflow-events.model'
import {
  IconConfigQa,
  labelAndSourcesQa,
  sourceMatchQa,
} from '../../qa/applicant-workflow-events.qa'
import { WORKFLOW_EVENTS_KEY } from '../../workflow-events.key'

type IconConfigType = {
  type?: WorkflowStepResultEnum
}

export function IconConfig({ type }: IconConfigType) {
  if (type) {
    if (failVariants.includes(type))
      return (
        <FrankieIcon
          name="mdiCloseCircle"
          className="text-tertiary-red-700 "
          testId={{ icon: IconConfigQa.failVariant }}
        />
      )
    if (passVariants.includes(type))
      return (
        <FrankieIcon
          name="mdiCheckCircle"
          className="text-tertiary-green-600"
          testId={{ icon: IconConfigQa.passVariants }}
        />
      )
    if (reviewVariants.includes(type))
      return (
        <FrankieIcon
          name="mdiAlertCircle"
          className="text-tertiary-yellow-300"
          testId={{ icon: IconConfigQa.reviewVariants }}
        />
      )
    if (type === WorkflowStepResultEnum.UNCHECKED) {
      return (
        <FrankieIcon
          name="mdiMinusCircle"
          className="text-tertiary-grey-700"
          testId={{ icon: IconConfigQa.unCheck }}
        />
      )
    }
    return null
  }
}

type Props = {
  matches?: string[]
  label?: string
}

export function SourceMatches({ matches, label }: Props) {
  const t = useI18n(WORKFLOW_EVENTS_KEY, { keys: workflowEventsEn })

  if (!matches || matches.length < 1)
    return (
      <FrankieBadge
        theme="red"
        text={t('verificationEventText.noMatches')}
        testId={{ badge: sourceMatchQa.noMatch }}
      />
    )

  return (
    <div className="flex flex-wrap gap-2" data-qa={sourceMatchQa.container}>
      {matches.map((match, ind, self) => {
        if (ind === 2) {
          return (
            <FrankieTooltip
              testId={{ tooltip: sourceMatchQa.tooltipTitle }}
              key={match}
              title={convertToLowerCaseExceptFirst(
                t('tooltipTitle', { label }),
              )}
              position="left"
              className="!min-w-max"
              body={
                <ul className="list-inside">
                  {matches.map(match => (
                    <li className="list-disc" key={match}>
                      {match}
                    </li>
                  ))}
                </ul>
              }
            >
              <FrankieBadge
                key={match}
                theme="green"
                text={`+${self.length - ind}`}
                className="cursor-pointer"
                testId={{ badge: sourceMatchQa.restResultsBadge }}
              />
            </FrankieTooltip>
          )
        }
        if (ind < 2) {
          return (
            <FrankieBadge
              key={match}
              theme="green"
              text={match}
              testId={{ badge: sourceMatchQa.matchBadge }}
            />
          )
        }
        return null
      })}
    </div>
  )
}

type LabelAndSourcesProps = {
  labelAndSources: ILabelAndSources[]
}

export function LabelAndSources({ labelAndSources }: LabelAndSourcesProps) {
  return (
    <div className="flex flex-col gap-3" data-qa={labelAndSourcesQa.container}>
      {labelAndSources.map(
        ({ label, value, sources }) =>
          // don't render if value and sources are empty
          !!(value || sources) && (
            <div className="flex grow-0" key={label}>
              <div
                className="font-medium desktop:min-w-[160px] min-w-[140px] text-tertiary-grey-800"
                data-qa={labelAndSourcesQa.label}
              >
                {label}
              </div>
              <div
                className="font-normal text-tertiary-grey-700 break-words whitespace-normal"
                data-qa={labelAndSourcesQa.value}
                data-hj-suppress
              >
                {value}
              </div>
              <div
                className="min-w-[244px] basis-[244px] desktop:basis-[450px] ml-auto"
                data-qa={labelAndSourcesQa.sourceWrapper}
              >
                {sources && <SourceMatches matches={sources} label={label} />}
              </div>
            </div>
          ),
      )}
    </div>
  )
}

export function borderStyleConfigAml(
  eventResult?: (ProcessResultManualStatusEnumAML | undefined)[],
) {
  if (!eventResult) return 'border-tertiary-grey-500'

  if (eventResult.some(status => !status)) return 'border-tertiary-yellow-400'

  if (eventResult.some(status => failVariantsAml.includes(status)))
    return 'border-tertiary-red-500'

  if (eventResult.every(status => passVariantsAml.includes(status)))
    return 'border-tertiary-grey-200'

  return 'border-tertiary-yellow-400'
}

export function getIconConfigAml(
  statuses?: (ProcessResultManualStatusEnumAML | undefined)[],
) {
  if (!statuses) return IconConfig({ type: WorkflowStepResultEnum.UNCHECKED })

  if (statuses.some(status => failVariantsAml.includes(status)))
    return IconConfig({ type: WorkflowStepResultEnum.FAIL })

  if (statuses.every(status => passVariantsAml.includes(status)))
    return IconConfig({ type: WorkflowStepResultEnum.PASS })

  return IconConfig({ type: WorkflowStepResultEnum.HIT })
}
