import React, { useEffect, useMemo, useState } from 'react'

import { useParams, useSearchParams } from 'react-router-dom'

import {
  FrankieBadge,
  FrankieDivider,
  FrankieIcon,
  FrankieLoader,
} from 'frankify/src'

import { applicantDetailConfig, businessInfoConfig } from 'entities/applicant'
import { useApplicantBusinessAssociationRolesQuery } from 'entities/organisation'
import { useSessionQuery } from 'entities/session'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../../locale/applicant-supporting-documents.en'
import {
  ApplicantTrustTypes,
  IDocumentInformationEntity,
  IValueReferenceIds,
  ITrustAnalyse,
} from '../../../model/applicant-supporting-documents.model'
import {
  checkAssociation,
  getReferenceAlphabet,
  getReferenceNumber,
  getReferenceType,
  mapLinkedData,
  TRUST_PARAM_KEY,
  TrustCommonFormType,
  TrustDeedAssociatedParty,
  TrustFormTypes,
  TrustReferenceTypes,
} from '../../../model/applicant-supporting-trust-deed.model'
import {
  trustAnalyserReferenceDetailQa,
  trustAnalyserReferenceQa,
  trustAnalyserViewFormQa,
  trustDeedsDocQa,
} from '../../../qa/applicant-support-documents.qa'
import {
  useAssociateNewPartyMutation,
  useUpdateAssociatedPartyMutation,
} from '../../../state/trust-deeds-mutation/trust-deeds-mutation'
import { useTrustDeedAssociatedPartiesQuery } from '../../../state/trust-deeds-query/trust-deeds-query'
import { TrustAnalyserReference } from '../trust-analyser-reference/trust-analyser-reference'

export type TrustAnalyserViewFormProps = {
  data: ITrustAnalyse
  setReferencePage: (page: number) => void
  associatedParties: TrustDeedAssociatedParty[]
}

type CommonViewFormProps = {
  data: ITrustAnalyse
  trustFormKey: TrustFormTypes
  trustFormData: IDocumentInformationEntity[] | IValueReferenceIds[]
  addAssociateParty: (type: TrustFormTypes, data: TrustCommonFormType) => void
  associatedParties: TrustDeedAssociatedParty[]
  setReferencePage: (page: number) => void
  formIndex: number
  showTitle?: boolean
}

function AddAssociatePartyBtn({
  trustFormKey,
  item,
  index,
  addAssociateParty,
}: {
  trustFormKey: TrustFormTypes
  item: TrustCommonFormType
  index: number
  addAssociateParty: (type: TrustFormTypes, data: TrustCommonFormType) => void
}) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const [isDisabled, setIsDisabled] = useState(false)

  return (
    <div
      onClick={() => {
        setIsDisabled(true)
        addAssociateParty(trustFormKey, item)
      }}
      onKeyDown={() => {
        setIsDisabled(true)
        addAssociateParty(trustFormKey, item)
      }}
      tabIndex={0}
      role="button"
      className={`flex ${
        isDisabled ? 'cursor-not-allowed pointer-events-none' : 'cursor-pointer'
      } ml-4 items-center gap-1`}
      data-qa={trustAnalyserViewFormQa.addAssociate(trustFormKey, index + 1)}
    >
      <FrankieIcon
        className={`
          cursor-pointer ${
            isDisabled ? 'text-tertiary-grey-400' : 'text-primary-800'
          } `}
        name="mdiAccountPlusOutline"
        size="xs"
      />
      <div
        className={`${
          isDisabled ? 'text-tertiary-grey-400' : 'text-primary-800'
        } text-sm font-semibold`}
        role="button"
        tabIndex={0}
      >
        {t('addAssociateParty')}
      </div>
    </div>
  )
}

type GeneralBeneficiariesViewFormProps = {
  trustFormData: IValueReferenceIds[]
  text: {
    title: string
    subtitle: string
    label: string
  }
  setReferencePage: (page: number) => void
  data: ITrustAnalyse
  formIndex: number
}

function GeneralBeneficiariesViewForm({
  trustFormData,
  text,
  setReferencePage,
  data,
  formIndex,
}: GeneralBeneficiariesViewFormProps) {
  return (
    <div className="mb-6">
      <div className="text-md font-semibold my-4">{text.title}</div>
      <div className="text-sm font-bold mb-2">{text.subtitle}</div>
      {trustFormData.map((beneficiary, index) => (
        <div className="mb-2">
          <div className="font-semibold">
            {`${text.label} ${index + 1}`}
            <TrustAnalyserReference
              data={data}
              getPageNumber={page => setReferencePage(page)}
              text={`[${getReferenceNumber(formIndex)}${getReferenceAlphabet(
                index,
              )}]`}
              type={TrustReferenceTypes.GENERAL_BENEFICIARIES}
              index={index}
              testId={{
                reference: trustAnalyserReferenceQa(
                  TrustFormTypes.GeneralBeneficiary,
                  index,
                ),
              }}
            />
          </div>
          <div
            data-qa={trustAnalyserViewFormQa.generalBeneficiaries(
              TrustFormTypes.GeneralBeneficiary,
              index + 1,
            )}
          >
            {beneficiary.value || '-'}
          </div>
        </div>
      ))}
    </div>
  )
}

function CommonViewForm({
  data,
  trustFormKey,
  trustFormData,
  addAssociateParty,
  associatedParties,
  showTitle = true,
  setReferencePage,
  formIndex,
}: CommonViewFormProps) {
  const { applicantId } = useParams()
  const [searchParams] = useSearchParams()

  const trustAnalysisId = searchParams.get(TRUST_PARAM_KEY)

  const { isLoading } = useTrustDeedAssociatedPartiesQuery({
    applicantId,
    trustAnalysisId: trustAnalysisId ?? '',
  })

  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })
  // check if the role is OTHER
  const isAssociated = (party: TrustCommonFormType) => {
    const isIndividual = party.type === ApplicantTrustTypes.Individual
    return checkAssociation({
      party,
      isIndividual,
      role: trustFormKey,
      associatedParties,
      t,
    })
  }

  const { trust } = data.documentInformation || {}
  if (!trust) return null

  if (trustFormKey === TrustFormTypes.GeneralBeneficiary) {
    return (
      <GeneralBeneficiariesViewForm
        trustFormData={trustFormData as IValueReferenceIds[]}
        text={{
          title: t('beneficiaries'),
          subtitle: t('generalBeneficiaries'),
          label: t('group'),
        }}
        data={data}
        setReferencePage={setReferencePage}
        formIndex={formIndex}
      />
    )
  }

  return (
    <>
      {showTitle && (
        <div className="text-md font-semibold my-4">{t(trustFormKey)}</div>
      )}
      {mapLinkedData(trustFormData as IDocumentInformationEntity[], trust).map(
        (item: TrustCommonFormType, index: number) => {
          const {
            type,
            entityId,
            name = '',
            abnOrAcn = '',
            address = '',
            dateOfBirth = '',
            role = '',
          } = item

          const dob = dateOfBirth
            ? formatDate(dateOfBirth, DateFormatTypes.DateNumbersSlash)
            : '-'

          const isMemberType = trustFormKey === TrustFormTypes.Member
          const getForm = {
            label: isMemberType ? t('role') : t('address'),
            value: isMemberType ? role : address,
          }

          return (
            <div key={entityId + name + address} className="mb-4">
              <div className="text-sm font-bold mb-4 flex">
                <div>
                  {t(trustFormKey)}
                  <span className="px-1">{index + 1}</span>
                  <TrustAnalyserReference
                    text={`[${getReferenceNumber(
                      formIndex,
                    )}${getReferenceAlphabet(index)}]`}
                    data={data}
                    type={getReferenceType[trustFormKey]}
                    getPageNumber={page => setReferencePage(page)}
                    index={index}
                    testId={{
                      reference: trustAnalyserReferenceQa(trustFormKey, index),
                    }}
                  />
                </div>
                <div className="ml-2">
                  <FrankieLoader
                    loading={isLoading}
                    loaderClassName="!items-start pl-6 ml-4 !bg-opacity-100"
                    size="xs"
                  >
                    {isAssociated(item) ? (
                      <FrankieBadge
                        theme="green"
                        size="sm"
                        text={t('associatedParty')}
                        className="ml-5"
                      />
                    ) : (
                      <AddAssociatePartyBtn
                        trustFormKey={trustFormKey}
                        item={item}
                        index={index}
                        addAssociateParty={addAssociateParty}
                      />
                    )}
                  </FrankieLoader>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <div className="font-semibold">{t('trustType')}</div>
                  <div
                    data-qa={trustAnalyserViewFormQa.type(
                      trustFormKey,
                      index + 1,
                    )}
                  >
                    {type}
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="font-semibold">{t('name')}</div>
                  <div
                    data-qa={trustAnalyserViewFormQa.name(
                      trustFormKey,
                      index + 1,
                    )}
                    data-hj-suppress
                  >
                    {name || '-'}
                  </div>
                </div>
                {type === ApplicantTrustTypes.Individual && (
                  <>
                    <div>
                      <div className="font-semibold">{t('dateOfBirth')}</div>
                      <div
                        data-qa={trustAnalyserViewFormQa.dateOfBirth(
                          trustFormKey,
                          index + 1,
                        )}
                        data-hj-suppress
                      >
                        {dob}
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="font-semibold">{getForm.label}</div>
                      <div
                        data-qa={trustAnalyserViewFormQa.address(
                          trustFormKey,
                          index + 1,
                        )}
                        data-hj-suppress
                      >
                        {getForm.value || '-'}
                      </div>
                    </div>
                  </>
                )}
                {type === ApplicantTrustTypes.Organization && (
                  <>
                    <div>
                      <div className="font-semibold">{t('abnOrAcn')}</div>
                      <div
                        data-qa={trustAnalyserViewFormQa.abnOrAcn(
                          trustFormKey,
                          index + 1,
                        )}
                        data-hj-suppress
                      >
                        {abnOrAcn || '-'}
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="font-semibold">{t('principalPlace')}</div>
                      <div
                        data-qa={trustAnalyserViewFormQa.address(
                          trustFormKey,
                          index + 1,
                        )}
                        data-hj-suppress
                      >
                        {address || '-'}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )
        },
      )}
      <FrankieDivider className="my-4" />
    </>
  )
}

export function TrustAnalyserViewForm({
  data,
  associatedParties,
  setReferencePage,
}: TrustAnalyserViewFormProps) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })
  const { applicantId } = useParams()
  const [searchParams] = useSearchParams()
  const { data: pageData } = useSessionQuery()

  const { mutate: associateNewPartyMutate } =
    useAssociateNewPartyMutation(applicantId)

  const { mutate: updateAssociatePartyMutate } =
    useUpdateAssociatedPartyMutation(applicantId)

  const { data: roleTypes } = useApplicantBusinessAssociationRolesQuery()
  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.TrustAnalyserAnalysisResultShow)
  }, [])

  const trustAnalysisId = searchParams.get(TRUST_PARAM_KEY)

  const otherAssociateRole = (
    inputRole: TrustFormTypes,
  ): {
    type: string
    typeDescription: string
  } => {
    if (inputRole === TrustFormTypes.UnitHolder)
      return { type: 'OTH', typeDescription: t(inputRole) }
    if (inputRole === TrustFormTypes.GeneralBeneficiary)
      return { type: 'OTH', typeDescription: t(inputRole) }
    if (inputRole === TrustFormTypes.SpecifiedBeneficiary)
      return { type: 'OTH', typeDescription: t(inputRole) }
    if (inputRole === TrustFormTypes.Member)
      return { type: 'OTH', typeDescription: t(inputRole) }

    return {
      type: 'OTH',
      typeDescription: 'Other',
    }
  }

  const updateAssociate = (
    party: TrustCommonFormType,
    roles: { type: string; typeDescription: string }[],
  ) => {
    const associatedParty = associatedParties.find(p => p.name === party.name)
    if (associatedParty && associatedParty.entityId) {
      updateAssociatePartyMutate({
        entityId: associatedParty.entityId,
        mainData: {
          addedBy: pageData?.user.username ?? ' ',
          percentage: {
            beneficially: 0,
            nonBeneficially: 0,
            jointly: null,
          },
          roles: [...associatedParty.roles, ...roles],
        },
      })
    }
  }

  const addAssociateParty = (
    inputRole: TrustFormTypes,
    party: TrustCommonFormType,
  ) => {
    const isIndividual = party.type === ApplicantTrustTypes.Individual
    if (!roleTypes) return

    const roles = roleTypes.filter(
      role =>
        role.typeDescription.toLocaleLowerCase() ===
        inputRole.toLocaleLowerCase(),
    )

    // if there is no role match against the role type, set role as other
    if (!roles.length) {
      roles.push(otherAssociateRole(inputRole))
    }
    // check if the party is already associated
    const isAssociated = checkAssociation({
      party,
      isIndividual,
      associatedParties,
      t,
    })

    if (!isAssociated) {
      associateNewPartyMutate({
        hidePartyFromSearch: false,
        mainData: {
          addedBy: pageData?.user.username ?? ' ',
          percentage: {
            beneficially: 0,
            nonBeneficially: 0,
            jointly: null,
          },
          roles,
        },
        partyData: isIndividual
          ? {
              partyType: 'INDIVIDUAL',
              party: {
                ...applicantDetailConfig,
                name: {
                  givenName: party.name ?? '',
                  displayName: null,
                  familyName: '',
                  middleName: '',
                },
                extraData: {
                  trust_analysis_id: trustAnalysisId ?? null,
                },
              },
            }
          : {
              partyType: 'BUSINESS',
              party: {
                ...businessInfoConfig,
                ABNNumber: party.abnOrAcn ?? '',
                ACNNumber: party.abnOrAcn ?? '',
                businessName: party.name ?? '', // Ensure businessName is not undefined
                businessProfile: 'organisation',
                extraData: {
                  trust_analysis_id: trustAnalysisId ?? null,
                },
              },
            },
      })
    } else {
      updateAssociate(party, roles)
    }
  }

  const {
    name,
    establishment = {},
    settlors = [],
    trustees = [],
    typeInformation,
    type,
  } = data.documentInformation?.trust || {}

  const { discretionary, selfManagedSuperFund, unit } = typeInformation || {}

  const {
    appointors = [],
    generalBeneficiaries = [],
    specifiedBeneficiaries = [],
    protectors = [],
  } = discretionary || {}
  const { members = [] } = selfManagedSuperFund || {}
  const { unitHolders = [] } = unit || {}

  const getTrustFormData = useMemo(
    () => ({
      [TrustFormTypes.Settlor]: settlors,
      [TrustFormTypes.Trustee]: trustees,
      [TrustFormTypes.Appointor]: appointors,
      [TrustFormTypes.Protector]: protectors,
      [TrustFormTypes.GeneralBeneficiary]: generalBeneficiaries,
      [TrustFormTypes.SpecifiedBeneficiary]: specifiedBeneficiaries,
      [TrustFormTypes.UnitHolder]: unitHolders,
      [TrustFormTypes.Member]: members,
    }),
    [
      appointors,
      generalBeneficiaries,
      members,
      protectors,
      settlors,
      specifiedBeneficiaries,
      trustees,
      unitHolders,
    ],
  )

  const establishmentDate =
    establishment.date?.normalized || establishment.date?.unstructured

  return (
    <div className="px-8 overflow-x-auto">
      <div className="text-md font-semibold my-4">{t('trustDetails')}</div>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <div className="font-semibold">{t('trustType')}</div>
          <div data-qa={trustDeedsDocQa.trustTypeSelector}>
            {type?.provided || type?.detected || '-'}
          </div>
        </div>
        <div className="col-span-2">
          <div className="font-semibold">
            {t('nameOfTrust')}
            <TrustAnalyserReference
              text={t('trustDetailReference.name')}
              data={data}
              type={TrustReferenceTypes.NAME_OF_TRUST}
              getPageNumber={page => setReferencePage(page)}
              testId={{ reference: trustAnalyserReferenceDetailQa.name }}
            />
          </div>

          <div data-qa={trustDeedsDocQa.nameOfTrustField} data-hj-suppress>
            {name?.value || '-'}
          </div>
        </div>
        <div>
          <div className="font-semibold">
            {t('dateOfEstablishment')}
            <TrustAnalyserReference
              text={t('trustDetailReference.date')}
              data={data}
              type={TrustReferenceTypes.DATE_OF_ESTABLISHMENT}
              getPageNumber={page => setReferencePage(page)}
              testId={{ reference: trustAnalyserReferenceDetailQa.date }}
            />
          </div>
          <div
            data-qa={trustDeedsDocQa.dateOfEstablishmentField}
            data-hj-suppress
          >
            {establishmentDate
              ? formatDate(establishmentDate, DateFormatTypes.DateNumbersSlash)
              : '-'}
          </div>
        </div>
        <div className="col-span-2">
          <div className="font-semibold">
            {t('countryOfEstablishment')}
            <TrustAnalyserReference
              text={t('trustDetailReference.country')}
              data={data}
              type={TrustReferenceTypes.COUNTRY_OF_ESTABLISHMENT}
              getPageNumber={page => setReferencePage(page)}
              testId={{ reference: trustAnalyserReferenceDetailQa.country }}
            />
          </div>
          <div
            data-qa={trustDeedsDocQa.countryStateOfEstablishmentField}
            data-hj-suppress
          >
            {establishment.country?.value || '-'}
          </div>
        </div>
      </div>

      <FrankieDivider className="my-4" />

      {Object.values(TrustFormTypes).map((key, formIndex) => {
        const showTitle =
          key === TrustFormTypes.SpecifiedBeneficiary
            ? !generalBeneficiaries.length
            : true
        if (getTrustFormData[key].length > 0) {
          return (
            <CommonViewForm
              trustFormKey={key}
              trustFormData={getTrustFormData[key]}
              addAssociateParty={addAssociateParty}
              associatedParties={associatedParties}
              setReferencePage={setReferencePage}
              data={data}
              showTitle={showTitle}
              formIndex={formIndex}
            />
          )
        }
        return null
      })}
    </div>
  )
}
