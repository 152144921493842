import { useMemo } from 'react'

import {
  AmlIssuesTypes,
  ProcessResultManualStatusEnumAML,
  useEntityAmlData,
} from 'entities/entity'
import { useWorkflowListQuery } from 'entities/workflow'

import { I18nKeys, useI18n } from 'shared/i18n'

import { INDIVIDUAL_AML_SCREENING_KEY } from '../../individual-aml-screening.key'
import { individualAmlScreeningEn } from '../../locale/individual-aml-screening.en'
import {
  AmlFilters,
  getAmlIssues,
} from '../../model/individual-aml-screening/individual-aml-screening.model'

type Args = {
  entityId: string
  workflowExecutionId?: string
  filters?: AmlFilters
  isNonValid?: boolean
}

export const useAmlPRORowData = ({
  entityId,
  workflowExecutionId,
  filters,
  isNonValid = false,
}: Args) => {
  const t = useI18n([INDIVIDUAL_AML_SCREENING_KEY], {
    keys: individualAmlScreeningEn,
  })

  const { data, refetch } = useEntityAmlData({
    entityId,
    workflowExecutionId,
    isNonValid,
  })

  const rows = useMemo(() => {
    const unFilteredRows = data.processResults.flatMap(process => {
      if (process.supplementaryData?.type === 'AML')
        return {
          id: process.processResultId,
          name: process.supplementaryData.matchData?.name,
          matchStrength: process.supplementaryData.matchData?.strength,
          countries: process.supplementaryData.matchData?.countries?.map(
            country => country.code,
          ),
          dateOfBirth: process.supplementaryData.matchData?.date,
          address: process.supplementaryData.matchData?.address,
          resolvedBy: process.updatedBy,
          resolvedAt: process.updatedAt,
          workflow: process.workflowName,
          attempts: process.workflowAttempts,
          issues: getAmlIssues(process.supplementaryData, t),
          matchStatus: process.manualStatus,
          entityId: process.entityId,
          query: process.query,
        }
      return []
    })

    return unFilteredRows.filter(row => {
      const amlMatch = filters?.amlMatch.length
        ? filters.amlMatch.some(
            match =>
              row.query?.[match] &&
              row.query[match]?.includes(
                row[match as 'dateOfBirth' | 'address'] ?? '',
              ),
          )
        : true
      const matchStatus = filters?.matchStatus.length
        ? filters.matchStatus.some(issue =>
            (row.matchStatus ?? 'potentialMatch').includes(issue),
          )
        : true
      const issues = filters?.issues.length
        ? filters.issues.every(issue => row.issues.includes(issue))
        : true
      const workflow = filters?.workflow.length
        ? filters.workflow.includes(row.workflow ?? '')
        : true

      return amlMatch && matchStatus && issues && workflow
    })
  }, [data.processResults, filters, t])

  return { rows, refetch }
}

export type AmlPRORow = ReturnType<typeof useAmlPRORowData>['rows'][number]

type AmlOptionsType = {
  label: I18nKeys<typeof individualAmlScreeningEn>
  value: string
}

export const useAmlFiltersOptions = () => {
  const { data } = useWorkflowListQuery()

  const amlMatchOptions: AmlOptionsType[] = [
    {
      label: 'yearOfBirth',
      value: 'dateOfBirth',
    },
    {
      label: 'residence',
      value: 'address',
    },
  ]

  const matchStatusOptions: AmlOptionsType[] = [
    {
      label: 'matchStatus.truePositive',
      value: ProcessResultManualStatusEnumAML.TRUE_POSITIVE,
    },
    {
      label: 'matchStatus.unknown',
      value: ProcessResultManualStatusEnumAML.UNKNOWN,
    },
    {
      label: 'matchStatus.potentialMatch',
      value: 'potentialMatch',
    },
    {
      label: 'matchStatus.falsePositive',
      value: ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
    },
  ]

  const amlIssuesOptions: AmlOptionsType[] = [
    {
      label: 'amlIssues.pep',
      value: AmlIssuesTypes.PEP,
    },
    {
      label: 'amlIssues.sanction',
      value: AmlIssuesTypes.SANCTION,
    },
    {
      label: 'amlIssues.watchlist',
      value: AmlIssuesTypes.WATCH_LIST,
    },
    {
      label: 'amlIssues.adverseMedia',
      value: AmlIssuesTypes.ADVERSE_MEDIA,
    },
  ]

  const workflowOptions =
    data?.map(workflow => ({
      label: workflow.workflowName,
      value: workflow.workflowName,
    })) ?? []

  return {
    amlMatchOptions,
    matchStatusOptions,
    amlIssuesOptions,
    workflowOptions,
  }
}
