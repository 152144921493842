/* eslint-disable complexity */
import React, { useMemo, useState } from 'react'

import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'

import { FrankieButton, FrankiePopover } from 'frankify/src'

import { PROFILE_FILTER_WIDGET_KEY } from 'fwidgets/profiles-filter/profile-filter.key'

import {
  getProfileFilterFromSearchParams,
  profileStates3Columns,
  IProfileFilter,
  updateSearchParamsWithProfileFilter,
  ProfileIssuesForm,
  useUpdateTableLoadingState,
  countEnabledFilterCategories,
  profileGlobalFilterDefault,
  WorkflowStatusesForm,
  workflowStatusColumns,
} from 'features/profiles-filter'
import { useUserListQuery } from 'features/user-management'

import { ProfileStateTypes, WorkflowStatusKeysTypes } from 'entities/entity'
import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'
import { useWorkflowListQuery } from 'entities/workflow'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { profileFilterWidgetEn } from '../../locale/applicants-filter'
import { applicantsFilterQa } from '../../qa/applicants-filter.qa'
import { ProfilesFilterModal } from '../profiles-filter-modal/profiles-filter-modal'

type Props = {
  statesOptionCols?: ProfileStateTypes[][]
  workflowStatesOptionCols?: WorkflowStatusKeysTypes[]
  trackingEvents?: {
    filteringPopup: TrackingEventsTypes
    filteringAdvanced: TrackingEventsTypes
    filteringWorkflowStatus: TrackingEventsTypes
    filteringIssues: TrackingEventsTypes
  }
}
export function ProfilesFilter({
  statesOptionCols = profileStates3Columns,
  workflowStatesOptionCols = workflowStatusColumns,
  trackingEvents,
}: Props) {
  const t = useI18n(PROFILE_FILTER_WIDGET_KEY, {
    keys: profileFilterWidgetEn,
  })
  const [openPopover, setOpenPopover] = useState<'statuses' | 'issues' | null>(
    null,
  )
  const [searchParams, setSearchParams] = useSearchParams()
  const [createOverlay, closeOverlay] = useOverlay()
  const { canFetchUserList } = useHasPermission({
    canFetchUserList: PermissionTypes.UserList,
  })

  const updateTableLoadingState = useUpdateTableLoadingState()

  // for ApplicantsFilterModal, run query immediately
  const { data: userList } = useUserListQuery({ canFetchUserList })
  useWorkflowListQuery('KYC')

  const currentFilter = useMemo<IProfileFilter>(
    () => getProfileFilterFromSearchParams(searchParams, userList),
    [searchParams, userList],
  )
  const enabledStatuses = currentFilter.workflowStatuses.length
  const enabledIssues = currentFilter.issueCategories.length

  const enabledCategories = useMemo<number>(
    () => countEnabledFilterCategories(searchParams),
    [searchParams],
  )
  const isStatusesOpen = openPopover === 'statuses'
  const isStatusBtnActive = isStatusesOpen || enabledStatuses > 0
  const isIssuesOpen = openPopover === 'issues'
  const isIssuesBtnActive = isIssuesOpen || enabledIssues > 0

  const handleToggleStatuses = () => {
    setOpenPopover(prev => (prev === 'statuses' ? null : 'statuses'))
  }
  const handleToggleIssues = () => {
    setOpenPopover(prev => (prev === 'issues' ? null : 'issues'))
  }
  const handleClosePopover = () => setOpenPopover(null)

  const setPartialFilters = (data: Partial<IProfileFilter>) => {
    updateTableLoadingState('applyingFilters')

    setSearchParams(prev => {
      updateSearchParamsWithProfileFilter(prev, {
        ...currentFilter,
        ...data,
      })
      return prev
    })
  }

  const handleFilterGeneral = (data: Partial<IProfileFilter>) => {
    if (trackingEvents) trackingManager.track(trackingEvents.filteringAdvanced)
    setPartialFilters(data)
  }
  const handleFilterStates = (data: Partial<IProfileFilter>) => {
    if (trackingEvents)
      trackingManager.track(trackingEvents.filteringWorkflowStatus)
    setPartialFilters(data)
  }
  const handleFilterIssues = (data: Partial<IProfileFilter>) => {
    if (trackingEvents) trackingManager.track(trackingEvents.filteringIssues)
    setPartialFilters(data)
  }

  const handleClearFiltersGeneral = () => {
    setPartialFilters(profileGlobalFilterDefault)

    updateTableLoadingState('clearingFilters')
  }

  const handleOpenFilters = () => {
    setOpenPopover(null)
    if (trackingEvents) trackingManager.track(trackingEvents.filteringPopup)
    createOverlay(
      <ProfilesFilterModal
        statesOptionCols={statesOptionCols}
        workflowStatesOptionCols={workflowStatesOptionCols}
        initialValues={currentFilter}
        onClose={closeOverlay}
        onSetFilters={handleFilterGeneral}
      />,
      {
        closeButtonClassName: 'top-6 right-5',
        className: 'w-[calc(100vw-48px)] laptop:w-[880px]',
        isFloating: true,
      },
    )
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <FrankiePopover
        open={isStatusesOpen}
        onOpenChange={handleToggleStatuses}
        popoverRest={{
          placement: 'bottom-start',
        }}
        trigger={
          <FrankieButton
            size="xs"
            className={classNames(
              'shrink-0 cursor-pointer min-w-[128px] justify-between !space-x-0',
              {
                'bg-primary-50 hover:!bg-primary-50 border-transparent':
                  isStatusBtnActive,
              },
            )}
            endIcon={{
              className: 'pointer-events-none',
              size: 'xs',
              name: isStatusesOpen ? 'mdiChevronUp' : 'mdiChevronDown',
            }}
            intent="darkOutline"
            onClick={handleToggleStatuses}
            testId={{ button: applicantsFilterQa.cta.selectStatuses }}
          >
            {t('cta.workflowStatus', { count: enabledStatuses || undefined })}
          </FrankieButton>
        }
      >
        <div className="bg-mono-white shadow-md min-w-[180px]">
          <WorkflowStatusesForm
            statusesOptions={workflowStatesOptionCols}
            initialValues={currentFilter}
            onClose={handleClosePopover}
            onSubmit={handleFilterStates}
          />
        </div>
      </FrankiePopover>
      <FrankiePopover
        open={isIssuesOpen}
        onOpenChange={handleToggleIssues}
        popoverRest={{
          placement: 'bottom-start',
        }}
        trigger={
          <FrankieButton
            size="xs"
            className={classNames(
              'cursor-pointer min-w-[128px] justify-between',
              {
                'bg-primary-50 hover:!bg-primary-50 border-transparent':
                  isIssuesBtnActive,
              },
            )}
            intent="darkOutline"
            onClick={handleToggleIssues}
            endIcon={{
              className: 'pointer-events-none',
              size: 'xs',
              name: isIssuesOpen ? 'mdiChevronUp' : 'mdiChevronDown',
            }}
            testId={{ button: applicantsFilterQa.cta.selectIssues }}
          >
            {t('cta.issues', { count: enabledIssues || undefined })}
          </FrankieButton>
        }
      >
        <div className="bg-mono-white shadow-md w-[332px]">
          <ProfileIssuesForm
            initialValues={currentFilter}
            onClose={handleClosePopover}
            onSubmit={handleFilterIssues}
          />
        </div>
      </FrankiePopover>
      <FrankieButton
        size="xs"
        className={classNames('cursor-pointer', {
          'bg-primary-50 hover:!bg-primary-50 border-transparent':
            enabledCategories > 0,
        })}
        intent="darkOutline"
        startIcon={{ name: 'mdiTuneVariant', size: 'xs' }}
        onClick={handleOpenFilters}
        testId={{ button: applicantsFilterQa.cta.showFilters }}
      >
        <div className="flex flex-row items-center gap-2">
          {t('cta.filters')}
          {enabledCategories > 0 && (
            <div
              data-qa={applicantsFilterQa.enabledCategories}
              className="inline-flex items-center justify-center w-5 h-5 text-xs font-medium text-mono-white leading-none bg-primary-800 rounded-full"
            >
              {enabledCategories}
            </div>
          )}
        </div>
      </FrankieButton>
      {enabledCategories > 0 && (
        <FrankieButton
          size="xs"
          intent="subtle"
          onClick={handleClearFiltersGeneral}
          testId={{ button: applicantsFilterQa.cta.clearFilters }}
        >
          {t('cta.clearFilters')}
        </FrankieButton>
      )}
    </div>
  )
}
