export const individualAmlScreeningEn = {
  headers: {
    amlMatch: 'AML MATCH',
    matchStatus: 'Match Status',
    issues: 'AML Issues',
    workflow: 'Workflow',
    matchStrength: 'Match Strength',
    update: 'Update',
    resolvedBy: 'Resolved By',
  },

  amlScreeningTitle: 'AML Screening',

  amlSearch: 'AML search',
  name: 'Name',

  aliases: 'Aliases',
  yearOfBirth: 'Year of Birth',
  associates: 'Associates',
  countries: 'Countries',
  fuzziness: 'Fuzziness',

  matchSummary: 'Match summary',
  potentialMatch: 'Potential Match',
  residence: 'Country of Residence',
  unknown: 'Unknown',
  truePositive: 'True Positive',
  falsePositive: 'False Positive',
  returnedResult: 'Returned result',
  noReturnedResult: 'No PEP, sanctions, adverse media or data source was found',
  updateAlert: 'Update alert',
  dataGrid: {
    rowPerPage: 'Rows per page',
    resultCount:
      '<b>{{start}}-{{end}}</b> of <b>{{total}}</b> AML search matches',
    selectedRowText: '{{amlCount}} AML matches selected',
  },
  matchStatus: {
    falsePositive: 'False positive',
    unknown: 'Unknown',
    truePositive: 'True positive',
    potentialMatch: 'Potential match',
  },
  amlIssues: {
    pep: 'PEP',
    adverseMedia: 'Adverse Media',
    sanction: 'Sanction',
    watchlist: 'Watchlist',
  },
  amlFiltersLabel: {
    amlMatch: 'AML match',
    amlMatch_one: '1 AML match',
    amlMatch_other: '{{count}} AML matches',
    matchStatus: 'Match status',
    matchStatus_one: '1 match status',
    matchStatus_other: '{{count}} match statuses',
    issues: 'AML issues',
    issues_one: '1 AML issue',
    issues_other: '{{count}} AML issues',
    workflow: 'Workflow',
    workflow_one: '1 workflow',
    workflow_other: '{{count}} workflows',
  },
  amlBanner: {
    title: 'All {{rowLength}} AML search matches on this page are selected.',
    allRowSelected: 'All {{rowLength}} AML search matches are selected.',
    clearRow: 'Clear selection',
    selectAll: 'Select all {{total}} AML search matches',
  },
  updateAmlForm: {
    title: 'Resolve AML alert',
    subTitle:
      'This will assign a match status for the selected AML matches, please review the data before proceeding.',
    selectLabel: 'AML status',
    selectPlaceholder: 'Select AML status',
    commentLabel: 'Comment',
    commentPlaceholder: 'Type your comment here',
    cancel: 'Cancel',
    resolveStatus: 'Resolve alert',
  },
  noMatchTitle: 'No match found with selected filters',
  noMatchSubtitle:
    'Your selected filters did not return any matches. \nAdjust your filters or clear them, and try again.',
}
