import { useCallback, useMemo } from 'react'

import {
  APPLICANT_GENERAL_INFORMATION_KEY,
  applicantGeneralInformationEn,
  normalizeRiskData,
  watchListOptions,
} from 'features/applicant-general-information'

import {
  ApplicantId,
  ApplicantTransactionalRiskType,
  useApplicantRiskCheckQuery,
  WatchlistReasonTypes,
} from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { capitalizeStringBySeparator } from 'shared/string'
import { arraysAsValueObject } from 'shared/typescript'

import { APPLICANT_RISK_SCORE_KEY } from '../../applicant-risk-score.key'
import { applicantRiskScoreEn } from '../../locale/applicant-risk-score.en'
import {
  ApplicantRiskScoreRecord,
  ApplicantRiskScoreType,
  riskScoreColorFlag,
} from '../../model/applicant-risk-score.model'

const watchListOptionRecord = arraysAsValueObject(watchListOptions)

export const AuditRiskScoreQueryKey = ['applicant-risk-score-query']

type Args = {
  applicantId?: ApplicantId
}

export const useApplicantRiskScoreState = ({ applicantId }: Args) => {
  const t = useI18n([APPLICANT_RISK_SCORE_KEY], {
    keys: applicantRiskScoreEn,
  })

  const tGen = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { data: riskData, isFetching } = useApplicantRiskCheckQuery({
    entityId: applicantId,
  })

  const riskNormalizedValue = useCallback(
    (factor = '', type = ''): string => {
      if (factor === 'Internal Watchlist') {
        return tGen(watchListOptionRecord[type as WatchlistReasonTypes].tKey)
      }
      return type
    },
    [tGen],
  )

  /**
   * Sort Risk Factors by Factor Name
   */
  const sortRiskFactors = useCallback(
    (factors: ApplicantRiskScoreRecord[]) =>
      factors.sort((a, b) => a.factor.localeCompare(b.factor)),
    [],
  )

  // eslint-disable-next-line complexity
  const onboarding = useMemo(() => {
    const res: ApplicantRiskScoreRecord[] = []

    if (riskData) {
      const { onboardingRisk } = riskData
      const { isManualOverride, result, factors, resultOverride } =
        onboardingRisk

      const normalizedRiskResult = normalizeRiskData(
        isManualOverride ? resultOverride.score : result.score,
      )

      const colorFlagType = riskScoreColorFlag[normalizedRiskResult.type]

      if (isManualOverride) {
        // as ver vue code delete the manual factor and create a custom one from resultOverride
        delete factors.manual
        const manualEntry: ApplicantRiskScoreRecord = {
          id: 'manual_factor',
          factor: t('manual'),
          value: normalizedRiskResult.label,
          policy: '',
          level: '',
          score: resultOverride.rating,
          type: 'value',
          color: {
            appliedOn: 'text',
            type: colorFlagType,
          },
        }

        // First Entry is Manual if manual override is present
        res.push(manualEntry)
      }

      const remainingFactors = Object.entries(factors).map(
        ([value, factor]) =>
          ({
            id: value,
            factor: factor?.factor ?? '',
            value: riskNormalizedValue(factor?.factor, factor?.type),
            policy: factor?.policy ?? '',
            score: factor?.rating ?? null,
            level: factor?.score ?? '',
            type: 'value',
          } as ApplicantRiskScoreRecord),
      )

      if (!remainingFactors.length) return [] // if no factors, return empty array

      // Reaming Factors added to the list are sorted by factor name
      res.push(...sortRiskFactors(remainingFactors))

      const overAllRisk: ApplicantRiskScoreRecord = {
        id: 'result_factor',
        factor: isManualOverride ? t('heading.manual') : t('heading.overall'),
        value: '',
        policy:
          (isManualOverride ? resultOverride.policy : result.policy) ?? '',
        score: isManualOverride ? resultOverride.rating : result.rating,
        type: 'badge',
        level: {
          label: `${normalizedRiskResult.label} ${t('risk')}`,
          type: normalizedRiskResult.type,
        },
        color: {
          appliedOn: 'bg',
          type: colorFlagType,
        },
      }

      // Overall onboarding Risk Score added to the list as last entry
      res.push(overAllRisk)
    }

    return res
  }, [riskData, riskNormalizedValue, sortRiskFactors, t])

  /**
   * Function to generate transactional risk score
   */
  const getTransactionsRisk = useCallback(
    (issueType: ApplicantTransactionalRiskType) => {
      const res: ApplicantRiskScoreRecord[] = []

      const riskTypeData = riskData?.transactionalRisk?.factors[issueType]
      if (riskTypeData) {
        const riskFactors: ApplicantRiskScoreRecord[] = riskTypeData.map(
          risk => ({
            id: risk.name,
            factor: risk.name,
            value: `${risk.count}x ${risk.description}`,
            policy: '',
            score: risk.rating,
            level: capitalizeStringBySeparator(risk.level),
            type: 'value',
          }),
        )

        res.push(...sortRiskFactors(riskFactors))
      }

      const overallRiskData = riskData?.transactionalRisk?.overall[issueType]

      if (!!riskTypeData?.length && overallRiskData) {
        const normalizedRiskResult = normalizeRiskData(
          overallRiskData.level.toLowerCase(),
        )

        const colorFlagType = riskScoreColorFlag[normalizedRiskResult.type]

        const overAllRisk: ApplicantRiskScoreRecord = {
          id: 'result_factor',
          factor: t('heading.overall'),
          value: '',
          policy: overallRiskData.policy ?? '',
          score: overallRiskData.rating,
          type: 'badge',
          level: {
            label: `${normalizedRiskResult.label} ${t('risk')}`,
            type: normalizedRiskResult.type,
          },
          color: {
            appliedOn: 'bg',
            type: colorFlagType,
          },
        }

        // Overall transactional Risk Score added to the list as last entry
        res.push(overAllRisk)
      }

      return res
    },
    [riskData?.transactionalRisk, sortRiskFactors, t],
  )

  return {
    riskData: {
      onboarding,
      amlMonitoring: getTransactionsRisk('aml'),
      fraudMonitoring: getTransactionsRisk('fraud'),
    } as Record<ApplicantRiskScoreType, ApplicantRiskScoreRecord[]>,
    loading: isFetching,
  }
}
