export const individualProfileInsightEn = {
  tab: {
    personalInfo: 'Personal info',
    phone: 'Phone',
    email: 'Email',
    documents: 'Identity documents',
    aml: 'AML screening',
    selfies: 'Selfies',
  },
  title: 'Section',
  label: {
    idType: 'ID Type',
    country: 'Country of Issue',
    individual: 'Individual',
    profileType: 'Profile Type',
  },
  banner: {
    title:
      'Some data on this profile has been changed but has not been verified',
    titleUnchecked: 'Data on this profile has not been verified',
    descriptionUnchecked: 'Please manually complete and verify this profile.',
    description:
      'To keep your profile details up-to-date, please verify the new data through a workflow.',
    button: 'Verify profile',
    loading: 'Verifying entity',
    error:
      "Sorry, we're unable to verify your profile at the moment. Please try again later.",
    archivedTitle: 'This profile has been archived',
  },
  amlSection: {
    title: 'AML screening',
    cta: 'View all',
    pep: 'PEP',
    sanctions: 'Sanctions',
    watchlist: 'Watchlist',
    adverseMedia: 'Adverse Media',
    truePositive: 'True positive',
    unknown: 'Unknown',
  },
  modal: {
    compareImages: 'Compare images',
    selfie: 'Selfie',
    uploadedOn: 'Uploaded on {{date}} at {{time}} {{via}}',
    directCapture: 'via direct capture',
    by: 'by {{name}}',
    recentUploadedOn: 'Most recent upload on 15/06/23 at 15:03:32',
  },
  uploadedAt: 'Last updated on 15/06/23 at 15:03:32',
}
