import React, { useEffect, useMemo, useState } from 'react'

import { useForm } from 'react-hook-form'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import { FrankieButton, FrankieIcon, FrankieLoader } from 'frankify/src'

import { DocumentTabTypes, useApplicantPaths } from 'entities/applicant'
import { TrustDeedTypes } from 'entities/applicant/model/applicant-trust-analyser.model'
import { DOCUMENT_KEY, documentEn, useGetDocumentScan } from 'entities/document'
import { SupportedFileTypes } from 'entities/document/model/document.model'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { getDataFileUrl } from 'shared/file'
import { SelectFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { useOverlay, ConfirmationOverlay } from 'shared/overlay'
import { capitalizeStringBySeparator } from 'shared/string'
import { SuggestionString } from 'shared/typescript'

import { TrustAnalyserAlert } from './trust-analyser-alert'
import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import {
  trustTypesOption,
  TrustTypes,
  ITrustAnalyse,
} from '../../model/applicant-supporting-documents.model'
import {
  TRUST_PARAM_KEY,
  TrustAlertTypes,
} from '../../model/applicant-supporting-trust-deed.model'
import { trustDeedsDocQa } from '../../qa/applicant-support-documents.qa'
import { useQueryUpdater } from '../../state/trust-deeds-manage/trust-deeds-manage'
import {
  useDeleteTrustDeedDocument,
  useTrustDeedAnalyser,
} from '../../state/trust-deeds-mutation/trust-deeds-mutation'
import {
  useTrustDeedAnalysisResultQuery,
  useTrustDeedAnalysisStatusQuery,
  useTrustDeedAssociatedPartiesQuery,
} from '../../state/trust-deeds-query/trust-deeds-query'
import { TrustAnalyserViewForm } from '../trust-analyzer-forms/trust-analyser-view-form/trust-analyser-view-form'
import { TrustAnalyzerForms } from '../trust-analyzer-forms/trust-analyzer-forms'

type TrustForm = {
  type: TrustTypes
}

type DocType = {
  name: string
  file: string
  createdAt: string
}

type StartAnalyzerProps = {
  onSubmit: (data: TrustForm) => void
  isProcessing: boolean
  document: DocType | null | undefined
  analysisIsLoading: boolean
}

function StartAnalyzer({
  onSubmit,
  isProcessing,
  document,
  analysisIsLoading,
}: StartAnalyzerProps) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const trustOptions = useMemo(
    () =>
      trustTypesOption.map(({ tKey, value }) => ({
        label: t(tKey),
        value,
      })),
    [t],
  )

  const { control, handleSubmit } = useForm<TrustForm>({
    mode: 'onChange',
  })

  return (
    <div className="mt-4 mb-6 p-28 !bg-tertiary-grey-50 rounded-md flex flex-col mx-8 items-center justify-items-center text-center h-[calc(100%-6.5rem)] overflow-y-hidden gap-4">
      <div className="w-full text-3xl font-bold text-center">
        {t('trustAnalyzer')}
      </div>
      {isProcessing ? (
        <FrankieLoader
          loading
          size="lg"
          label={
            <div className="mt-4">
              {t('trustAnalysisProcessingDescription', {
                fileName: document?.name,
              })}
            </div>
          }
          loaderClassName="!bg-tertiary-grey-50 relative"
          testId={{ loader: trustDeedsDocQa.startAnalysisLoader }}
        />
      ) : (
        <>
          <div className="text-md my-8">
            {t('trustAnalyzerTypeDescription')}
          </div>
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <SelectFormField
              label={t('trustType')}
              placeholder={t('select')}
              className="text-left"
              control={control}
              name="type"
              options={trustOptions}
              defaultValue={TrustTypes.AUTO_DETECT}
              testId={{ input: trustDeedsDocQa.selectTrustType }}
            />
            <FrankieButton
              intent="primary"
              className="m-auto mt-6 px-16"
              testId={{ button: trustDeedsDocQa.startAnalysisButton }}
              type="submit"
              size="md"
              disabled={analysisIsLoading}
            >
              {t('startAnalysis')}
            </FrankieButton>
          </form>
        </>
      )}
    </div>
  )
}

type RenderTrustAnalyzerInformationProps = {
  analysisResultsCompleted: boolean
  analysisResultsConfirmed: boolean
  onSubmit: (data: TrustForm) => void
  document: DocType | null | undefined
  isProcessing: boolean
  analysisIsLoading: boolean
  data: ITrustAnalyse | undefined
  onReturnToTrustDeed: () => void
  setReferencePage: (page: number) => void
  onChangeTrustType: (type: TrustTypes) => void
  status?: TrustDeedTypes
}

function RenderTrustAnalyzerInformation({
  analysisResultsCompleted,
  analysisResultsConfirmed,
  data,
  onSubmit,
  document,
  isProcessing,
  analysisIsLoading,
  onReturnToTrustDeed,
  setReferencePage,
  onChangeTrustType,
  status,
}: RenderTrustAnalyzerInformationProps) {
  const { applicantId } = useParams()

  const [searchParams] = useSearchParams()

  const trustAnalysisId = useMemo(
    () => searchParams.get(TRUST_PARAM_KEY),
    [searchParams],
  )

  const { data: associatedParties } = useTrustDeedAssociatedPartiesQuery({
    applicantId,
    trustAnalysisId: trustAnalysisId ?? '',
    analysisStatus: status,
  })

  const filteredAssociatedParties = useMemo(
    () =>
      associatedParties?.filter(
        associatedParty => associatedParty.trustAnalysisId === trustAnalysisId,
      ),
    [associatedParties, trustAnalysisId],
  )

  const { type } = data?.documentInformation?.trust || {}

  if (analysisResultsCompleted && data && type) {
    return (
      <TrustAnalyzerForms
        onReturnToTrustDeed={onReturnToTrustDeed}
        setReferencePage={setReferencePage}
        onChangeTrustType={onChangeTrustType}
        data={data}
      />
    )
  }

  if (analysisResultsConfirmed && data && type) {
    return (
      <TrustAnalyserViewForm
        data={data}
        setReferencePage={setReferencePage}
        associatedParties={filteredAssociatedParties ?? []}
      />
    )
  }

  return (
    <StartAnalyzer
      onSubmit={onSubmit}
      document={document}
      isProcessing={isProcessing}
      analysisIsLoading={analysisIsLoading}
    />
  )
}

type UploadedOnProps = {
  document: DocType | null | undefined
}

function UploadedOn({ document }: UploadedOnProps) {
  const tDoc = useI18n([DOCUMENT_KEY], { keys: documentEn })

  if (document?.createdAt) {
    return (
      <div
        data-qa={trustDeedsDocQa.uploadedOn}
        className="text-xs font-normal items-center text-tertiary-grey-500 mt-2"
      >
        {tDoc('uploadedOn', {
          date: formatDate(
            document.createdAt,
            DateFormatTypes.fullDateWithTimeSmall,
          ),
        })}
      </div>
    )
  }

  return null
}

export function TrustAnalyzerDocument() {
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()
  const { getApplicantPath } = useApplicantPaths()
  const { updateStatusQuery } = useQueryUpdater()

  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const documentId = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get(TRUST_PARAM_KEY) as SuggestionString<'new'> | undefined
  }, [location.search])

  const { data: scans, isLoading } = useGetDocumentScan({
    documentId,
  })

  const [createOverlay, closeOverlay] = useOverlay()
  const [trustDocument, setTrustDocument] = useState<DocType | null>()
  const [referencePage, setPage] = useState<{
    page: number
    totalClick: number
  }>({ page: 1, totalClick: 1 })

  const { mutateAsync, isLoading: analysisIsLoading } = useTrustDeedAnalyser()

  const {
    data: status,
    isLoading: isLoadingStatus,
    refetch: refetchStatus,
    fetchStatus: analysisFetchStatus,
    remove: removeStatus,
  } = useTrustDeedAnalysisStatusQuery({
    documentId,
    entityId: params.applicantId,
  })

  const { mutateAsync: deleteDocument } = useDeleteTrustDeedDocument()

  const {
    data,
    refetch: refetchResult,
    remove: removeResult,
    isLoading: analysisResultIsLoading,
    isFetching: analysisIsFetching,
  } = useTrustDeedAnalysisResultQuery({
    applicantId: params.applicantId,
    documentId,
    status,
  })

  useEffect(() => {
    async function fetchStatusAndResult() {
      removeStatus()
      const { data } = await refetchStatus()
      const isCompleteOrConfirmed =
        data === TrustDeedTypes.COMPLETE || data === TrustDeedTypes.CONFIRMED
      if (isCompleteOrConfirmed) {
        removeResult()
        await refetchResult()
      }
    }
    void fetchStatusAndResult()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (scans?.length) {
      const docScan = scans.find(
        item => item.mimeType === SupportedFileTypes.PDF,
      )

      if (docScan && docScan.file) {
        setTrustDocument({
          name: docScan.scanName,
          file: getDataFileUrl(docScan.mimeType, docScan.file),
          createdAt: docScan.scanCreated,
        })
      } else {
        notification.error(t('errorFetchingDoc'))
      }
    }
  }, [scans, t])

  const navigateToTrustDeed = () => {
    navigate(getApplicantPath('supportingDocuments'), {
      state: { activeTabKey: DocumentTabTypes.TRUSTS },
    })
  }

  const onSubmit = async (data: TrustForm) => {
    if (!documentId || !params.applicantId) {
      notification.error(t('analysisFailed'))
      return
    }

    await mutateAsync({
      entityId: params.applicantId,
      payload: {
        documentId,
        type: data.type,
      },
    })
  }

  const onConfirmDeleteDocument = async () => {
    if (params.applicantId && documentId) {
      await deleteDocument({
        applicantId: params.applicantId,
        documentId,
      })
      navigateToTrustDeed()
    }
  }

  const onDeleteDocument = () => {
    createOverlay(
      <ConfirmationOverlay
        text={{
          cancel: t('deleteTrustDeedDocument.cancel'),
          confirm: t('deleteTrustDeedDocument.confirm'),
          description: t('deleteTrustDeedDocument.description'),
        }}
        onClickCancel={closeOverlay}
        onClickConfirm={async () => {
          await onConfirmDeleteDocument()
          closeOverlay()
        }}
        type="danger"
        testId={{
          cancelButton: trustDeedsDocQa.confirmationCancelDeleteTrustDeedButton,
          confirmButton: trustDeedsDocQa.confirmationDeleteTrustDeed,
        }}
      />,
      {
        className: 'p-6 max-w-[448px] pb-5 pr-0',
      },
    )
  }

  const onDownloadDocument = () => {
    if (trustDocument?.file) {
      const link = document.createElement('a')
      link.href = trustDocument.file
      link.download = trustDocument.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const { type } = data?.documentInformation?.trust || {}
  const { detected, provided } = type || {}

  const onChangeTrustType = (updatedTrustType: TrustTypes) => {
    const currentTrustType = provided || detected || ''
    if (currentTrustType === updatedTrustType) return
    createOverlay(
      <ConfirmationOverlay
        text={{
          cancel: t('confirmTrustTypeChange.cancel'),
          confirm: t('confirmTrustTypeChange.confirm'),
          description: t('confirmTrustTypeChange.description', {
            updatedTrustType: capitalizeStringBySeparator(updatedTrustType),
            currentTrustType: capitalizeStringBySeparator(currentTrustType),
          }),
          subDescription: t('confirmTrustTypeChange.subDescription'),
        }}
        onClickCancel={closeOverlay}
        onClickConfirm={async () => {
          closeOverlay()
          await onSubmit({ type: updatedTrustType })
        }}
        testId={{
          cancelButton: trustDeedsDocQa.confirmationCancelButton,
          confirmButton: trustDeedsDocQa.confirmationConfirmButton,
        }}
      />,
      {
        className: 'p-6 max-w-[448px] pb-5 pr-0',
      },
    )
  }

  const analysisResultsConfirmed = status === TrustDeedTypes.CONFIRMED
  const isProcessing = status === TrustDeedTypes.PROCESSING
  const isComplete = status === TrustDeedTypes.COMPLETE
  const isFailed = status === TrustDeedTypes.FAILED

  const isLoadingAnalysis = analysisIsLoading || isLoadingStatus
  const analysisLoadingResult = analysisResultIsLoading || analysisIsFetching

  const getLoaderLabel = () =>
    isLoadingStatus ? t('fetchingAnalysisStatus') : t('fetchingAnalysisResult')

  const isLoadingResultAndStatus = (status?: TrustDeedTypes) =>
    isProcessing || isFailed || !status
      ? isLoadingStatus
      : analysisLoadingResult && !isProcessing

  const analysisResultsCompleted =
    isComplete &&
    analysisFetchStatus === 'idle' &&
    detected !== TrustTypes.UNKNOWN

  const onReturnToTrustDeed = () => {
    if (!analysisResultsCompleted || isProcessing) {
      navigateToTrustDeed()
    } else {
      createOverlay(
        <ConfirmationOverlay
          text={{
            cancel: t('returnToTrustDeed.cancel'),
            confirm: t('returnToTrustDeed.confirm'),
            description: t('returnToTrustDeed.description'),
            subDescription: t('returnToTrustDeed.subDescription'),
          }}
          onClickCancel={closeOverlay}
          onClickConfirm={() => {
            navigateToTrustDeed()
            closeOverlay()
          }}
          testId={{
            cancelButton: trustDeedsDocQa.confirmationCancelButton,
            confirmButton: trustDeedsDocQa.confirmationConfirmButton,
          }}
        />,
        {
          className: 'p-6 max-w-[448px] pb-5 pr-0',
        },
      )
    }
  }

  const setReferencePage = (page: number) => {
    setPage(state => ({ totalClick: state.totalClick + 1, page }))
  }

  const getAlertStatus = (status?: TrustDeedTypes): TrustAlertTypes | null => {
    const analysisResultMismatch =
      analysisResultsCompleted && provided !== detected

    if (status === TrustDeedTypes.FAILED || detected === TrustTypes.UNKNOWN) {
      return TrustAlertTypes.FAILED
    }
    if (!provided && analysisResultsCompleted) {
      return TrustAlertTypes.WARNING
    }
    if (analysisResultMismatch) {
      return TrustAlertTypes.MISMATCH
    }
    return null
  }

  const onClickEditForm = () => {
    updateStatusQuery(TrustDeedTypes.COMPLETE, documentId as string)
  }

  const addOverflowYCn = isLoadingResultAndStatus(status) ? '' : 'overflow-auto'

  const src = trustDocument?.file
    ? `${trustDocument.file}#page=${referencePage.page}`
    : ''

  return (
    <div className="pl-6 gap-2 flex overflow-y-auto">
      <div className="relative flex h-[calc(100vh-230px)] text-tertiary-grey-700">
        <FrankieLoader
          loading={isLoading}
          label={t('fetchingDoc')}
          className="pt-6 flex flex-col sticky top-0"
          testId={{ loader: trustDeedsDocQa.documentLoader }}
        >
          <FrankieButton
            noStyles
            className="inline-flex gap-1 text-xs font-normal items-center text-tertiary-grey-500"
            startIcon={{
              name: 'mdiChevronLeft',
            }}
            size="xs"
            onClick={onReturnToTrustDeed}
            testId={{ button: trustDeedsDocQa.returnToTrustDeed }}
          >
            {t('returnToTrustDeeds')}
          </FrankieButton>
          <iframe
            title="trust_deed_file"
            src={src}
            width="100%"
            className="mt-2 mb-4 grow"
            data-qa={trustDeedsDocQa.documentViewer}
            key={`pdf-${referencePage.page}-${referencePage.totalClick}`}
          />
          <UploadedOn document={trustDocument} />
        </FrankieLoader>
        <FrankieLoader
          loading={isLoadingResultAndStatus(status)}
          label={getLoaderLabel()}
          className={`pt-6 min-w-[550px] ${addOverflowYCn}`}
          testId={{ loader: trustDeedsDocQa.analysisLoader }}
        >
          <TrustAnalyserAlert
            showAlert={!isLoadingResultAndStatus(status)}
            analysisIsLoading={isLoadingAnalysis}
            detected={detected}
            provided={provided}
            onChangeTrustType={onChangeTrustType}
            status={getAlertStatus(status)}
            fileName={trustDocument?.name}
          />
          <div className="mt-6 px-8 flex justify-between items-center">
            <div className="w-full truncate text-xl font-semibold basis-1/2">
              {trustDocument?.name}
            </div>
            <div className="flex items-center w-full basis-1/2 justify-end">
              <button
                type="button"
                className="cursor-pointer mr-3"
                onClick={onDeleteDocument}
              >
                <FrankieIcon
                  name="mdiDeleteOutline"
                  size="md"
                  testId={{ icon: trustDeedsDocQa.delete }}
                />
              </button>
              <button
                type="button"
                className="cursor-pointer mr-3"
                onClick={onDownloadDocument}
              >
                <FrankieIcon
                  className="cursor-pointer mr-3"
                  name="mdiDownload"
                  size="md"
                  testId={{ icon: trustDeedsDocQa.download }}
                />
              </button>
              {analysisResultsConfirmed && (
                <FrankieButton
                  intent="secondary"
                  size="md"
                  onClick={onClickEditForm}
                  className="overflow-hidden"
                  testId={{ button: trustDeedsDocQa.editTrustData }}
                >
                  {t('editTrustData')}
                </FrankieButton>
              )}
            </div>
          </div>

          <RenderTrustAnalyzerInformation
            data={data}
            analysisIsLoading={isLoadingAnalysis}
            analysisResultsCompleted={analysisResultsCompleted}
            analysisResultsConfirmed={analysisResultsConfirmed}
            document={trustDocument}
            isProcessing={isProcessing}
            onSubmit={onSubmit}
            onReturnToTrustDeed={onReturnToTrustDeed}
            setReferencePage={setReferencePage}
            onChangeTrustType={onChangeTrustType}
            status={status}
          />
        </FrankieLoader>
      </div>
    </div>
  )
}
