import React from 'react'

import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_AML_RESULT_KEY } from '../../../individual-aml-result.key'
import { individualAmlResultEn } from '../../../locale/individual-aml-result.en'
import { useLabelAndSrc } from '../../../state/individual-aml-helper.state'

type Props = {
  entityId: string
}
export function IndividualAmlWatchlistCard({ entityId }: Props) {
  const t = useI18n(INDIVIDUAL_AML_RESULT_KEY, {
    keys: individualAmlResultEn,
  })

  const { watchlistSrcLabel } = useLabelAndSrc({ t, entityId })

  return (
    <>
      <div className="border border-tertiary-grey-200 rounded-[4px] pt-5 px-6 pb-6">
        <div className="">
          <div className="font-semibold text-sm leading-5 text-tertiary-grey-700 mb-3">
            DFAT Australia Consolidated Sanctions List
          </div>

          <div className="grid grid-flow-col !auto-rows-auto  gap-3 grid-rows-[repeat(7,_auto)] laptop:grid-rows-[repeat(6,_auto)] w-full">
            {watchlistSrcLabel.map(item => (
              <div className="flex gap-4" key={item.value}>
                <div className="min-w-[140px] font-medium text-sm leading-5 text-tertiary-grey-800">
                  {item.label}
                </div>

                {item.isUrl ? (
                  <a
                    className="underline text-primary-800"
                    href={item.value}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.value}
                  </a>
                ) : (
                  <div className="text-sm leading-5 text-tertiary-grey-700">
                    {item.value}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="border border-tertiary-grey-200 rounded-[4px] pt-5 px-6 pb-6">
        <div className="">
          <div className="font-semibold text-sm leading-5 text-tertiary-grey-700 mb-3">
            DFAT Australia Consolidated Sanctions List
          </div>

          <div className="grid grid-flow-col !auto-rows-auto  gap-3 grid-rows-[repeat(7,_auto)] laptop:grid-rows-[repeat(6,_auto)] w-full">
            {watchlistSrcLabel.map(item => (
              <div className="flex gap-4" key={item.value}>
                <div className="min-w-[140px] font-medium text-sm leading-5 text-tertiary-grey-800">
                  {item.label}
                </div>

                {item.isUrl ? (
                  <a
                    className="underline text-primary-800"
                    href={item.value}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.value}
                  </a>
                ) : (
                  <div className="text-sm leading-5 text-tertiary-grey-700">
                    {item.value}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
