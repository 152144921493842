import React from 'react'

import { useI18n } from 'shared/i18n'

import {
  NOTIFICATIONS_MANAGEMENT_KEY,
  notificationsManagementEn,
} from '../../locale/notifications-management.en'
import { INotificationChannel } from '../../model/notifications-management.model'
import { NotificationsConfigTableRowLayout } from '../notifications-config-table-row/notifications-config-table-row-layout/notifications-config-table-row-layout'

type Props = {
  channels: INotificationChannel[]
}

export function GeneralNotificationsConfigTableRowHeader({ channels }: Props) {
  const t = useI18n([NOTIFICATIONS_MANAGEMENT_KEY], {
    keys: notificationsManagementEn,
  })

  return (
    <div className="w-full text-sm font-semibold text-tertiary-grey-700 mb-2 ">
      <NotificationsConfigTableRowLayout
        notificationTypeCell={
          <>
            <div>{t('general.header')}</div>
            <div className="grow" />
          </>
        }
        frequencyCell={<div />}
        channelCells={[]}
      />
    </div>
  )
}
