import React, { useState } from 'react'

import { DataGridPro, GridColDef, GridSortModel } from '@mui/x-data-grid-pro'

import { FrankieBadge, FrankieLoader } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import {
  ApplicantUploadedTrustDeedRecord,
  getStatusBadge,
} from '../../model/applicant-supporting-trust-deed.model'
import {
  trustDeedsDocQa,
  trustDeedsTableQA,
} from '../../qa/applicant-support-documents.qa'
import { useApplicantTrustDeedDocumentQuery } from '../../state/trust-deeds-query/trust-deeds-query'
import {
  trustHeaderField,
  documentCellWidth,
  documentDateFormatter,
  DocumentTableRow,
  documentTableMuiStyles,
  DocumentLoadingOverlay,
  documentTimeFormatter,
  DocumentTableHeader,
} from '../applicant-support-document-table-helper/applicant-support-document-table-helper'

type Props = {
  applicantId: ApplicantId
  paramKey: string
  className: string
}

export function TrustUploadedDocuments({
  applicantId,
  paramKey,
  className = '',
}: Props) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const {
    data: trustDeedDocuments,
    isFetching,
    isLoading,
  } = useApplicantTrustDeedDocumentQuery({
    applicantId,
  })

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: trustHeaderField('uploadedDate'), sort: 'asc' },
  ])

  const columns: GridColDef<ApplicantUploadedTrustDeedRecord>[] = [
    {
      field: trustHeaderField('idType'),
      headerName: t('document'),
      sortable: false,
      renderCell: ({ row, api, id }) => (
        <div
          data-qa={trustDeedsTableQA.nameCell(
            api.getRowIndexRelativeToVisibleRows(id) + 1,
          )}
          className="py-2 flex-col"
        >
          <div className="break-all">{row.scanName}</div>
          <div className="text-xs font-normal text-tertiary-grey-500 break-all">
            {t('trustDeed')}
          </div>
        </div>
      ),
    },
    {
      field: trustHeaderField('status'),
      headerName: t('status'),
      sortable: false,
      ...documentCellWidth(150),
      renderCell: ({ row, api, id }) => (
        <div>
          <p
            data-qa={trustDeedsTableQA.statusCell(
              api.getRowIndexRelativeToVisibleRows(id) + 1,
            )}
            className="text-left"
          >
            <FrankieBadge {...getStatusBadge(row, t)} />
          </p>
        </div>
      ),
    },
    {
      field: trustHeaderField('uploadedDate'),
      headerName: t('upload'),
      sortComparator: (a: string, b: string) => b.localeCompare(a),
      renderCell: ({ row, api, id }) => {
        const date = documentDateFormatter({ value: row.uploadedDate })
        const time = documentTimeFormatter({ value: row.uploadedDate })
        return (
          <div
            data-qa={trustDeedsTableQA.dateCell(
              api.getRowIndexRelativeToVisibleRows(id) + 1,
            )}
            className="py-2 flex-col"
          >
            <div className="font-normal">{date}</div>
            <div className="text-xs font-medium text-tertiary-grey-500 break-all">
              {time}
            </div>
          </div>
        )
      },
      ...documentCellWidth(145),
    },
    {
      field: trustHeaderField('uploadedBy'),
      headerName: t('uploadedBy'),
      sortable: false,
      renderCell: ({ row, api, id }) => (
        <div
          data-qa={trustDeedsTableQA.uploaderCell(
            api.getRowIndexRelativeToVisibleRows(id) + 1,
          )}
          className="font-normal"
        >
          {row.uploadedBy}
        </div>
      ),
    },
    {
      field: trustHeaderField('lastUpdatedDate'),
      headerName: t('updated'),
      sortComparator: (a: string, b: string) => b.localeCompare(a),
      renderCell: ({ row, api, id }) => {
        const date = documentDateFormatter({ value: row.lastUpdatedDate })
        return (
          <div
            data-qa={trustDeedsTableQA.updatedCell(
              api.getRowIndexRelativeToVisibleRows(id) + 1,
            )}
            className="font-normal"
          >
            {date}
          </div>
        )
      },
      ...documentCellWidth(145),
    },
    {
      field: trustHeaderField('updatedBy'),
      headerName: t('updatedBy'),
      sortable: false,
      renderCell: ({ row, api, id }) => (
        <div
          data-qa={trustDeedsTableQA.updaterCell(
            api.getRowIndexRelativeToVisibleRows(id) + 1,
          )}
          className="font-normal"
        >
          {row.updatedBy}
        </div>
      ),
    },
  ]

  return (
    <div
      className={`text-tertiary-grey-700 ${className}`}
      data-qa={trustDeedsDocQa.tableWrapper}
    >
      <div className="text-xl text-secondary-900 font-bold pb-2 mb-2">
        {t('trustDeeds')}
      </div>
      {trustDeedDocuments && !!trustDeedDocuments.length ? (
        <DataGridPro
          getRowId={row => row.id}
          rows={trustDeedDocuments}
          slots={{
            row: WithProps(DocumentTableRow, { paramKey }),
            loadingOverlay: DocumentLoadingOverlay,
          }}
          className="border-none"
          columns={columns.map(item => ({
            // Adding default properties for every column
            headerClassName:
              '!p-0 text-xs font-medium bg-tertiary-grey-50 text-tertiary-grey-500 !outline-none uppercase',
            cellClassName:
              '!outline-none text-sm font-semibold text-secondary-900 break-words',
            sortable: true,
            hideSortIcons: true,
            resizable: false,
            disableColumnMenu: true,
            disableReorder: true,
            renderHeader: WithProps(
              DocumentTableHeader<ApplicantUploadedTrustDeedRecord>,
              { sortModel },
            ),
            ...documentCellWidth(180),
            ...item,
          }))}
          sx={{
            ...documentTableMuiStyles,
            '& .MuiDataGrid-virtualScroller': {
              maxHeight: 'calc(100vh - 550px)',
              overflowY: 'auto !important',
              overflowX: 'hidden',
            },
            '& .MuiDataGrid-row--lastVisible': {
              borderBottom: '1px solid #E5E7EB',
            },
          }}
          loading={isFetching || isLoading}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          isRowSelectable={() => false}
          isCellEditable={() => false}
          getRowHeight={() => 'auto'}
          disableRowSelectionOnClick
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
          disableDensitySelector
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          hideFooterPagination
          hideFooter
          hideFooterSelectedRowCount
          autoHeight
          autoPageSize
          data-qa={trustDeedsDocQa.table}
        />
      ) : (
        <FrankieLoader
          className="min-h-[50px]"
          loading={isLoading || isFetching}
          label={t('fetchingDoc')}
          testId={{ loader: trustDeedsDocQa.loader }}
        >
          <div className="">{t('noTrustDeedAvailable')}</div>
        </FrankieLoader>
      )}
    </div>
  )
}
