import {
  ProfileIssueCategoryTypes,
  ProfileRiskLevelTypes,
  ProfileStateTypes,
  WorkflowStatusKeysTypes,
  WorkflowExecutionResultEnum,
} from 'entities/entity'

import { I18nKeys } from 'shared/i18n'

import { profilesFilterEn } from '../locale/profiles-filter.en'

export const profileStatesI18n: Record<
  ProfileStateTypes,
  I18nKeys<typeof profilesFilterEn>
> = {
  [ProfileStateTypes.INIT]: 'profileStates.unchecked',
  [ProfileStateTypes.ACTIVE]: 'profileStates.active',
  [ProfileStateTypes.ARCHIVED]: 'profileStates.archived',
  [ProfileStateTypes.INACTIVE]: 'profileStates.inactive',
  [ProfileStateTypes.DELETED]: 'profileStates.deleted',
  [ProfileStateTypes.AUTO]: 'profileStates.auto',
  [ProfileStateTypes.BLOCKLISTED]: 'profileStates.blocklisted',
}

export const workflowStatesI18n: Record<
  WorkflowStatusKeysTypes,
  I18nKeys<typeof profilesFilterEn>
> = {
  [WorkflowStatusKeysTypes.PASSED]: 'workflowStatuses.passed',
  [WorkflowStatusKeysTypes.FAILED]: 'workflowStatuses.failed',
  [WorkflowStatusKeysTypes.NEEDS_ATTENTION]: 'workflowStatuses.needsAttention',
  [WorkflowStatusKeysTypes.MANUALLY_PASSED]: 'workflowStatuses.manuallyPassed',
  [WorkflowStatusKeysTypes.MANUALLY_FAILED]: 'workflowStatuses.manuallyFailed',
  [WorkflowStatusKeysTypes.UNCHECKED]: 'workflowStatuses.unchecked',
}

export const profileStatesOrder: ProfileStateTypes[] = [
  ProfileStateTypes.ARCHIVED,
  ProfileStateTypes.ACTIVE,
  ProfileStateTypes.BLOCKLISTED,
  // removing auto and appending it to the api call
  ProfileStateTypes.INIT,
]

export const profileStates3Columns: ProfileStateTypes[][] = [
  profileStatesOrder.slice(0, 2),
  profileStatesOrder.slice(2, 3),
  profileStatesOrder.slice(3, 4),
]

export const workflowStatusColumns: WorkflowStatusKeysTypes[] = [
  WorkflowStatusKeysTypes.PASSED,
  WorkflowStatusKeysTypes.FAILED,
  WorkflowStatusKeysTypes.NEEDS_ATTENTION,
  WorkflowStatusKeysTypes.MANUALLY_PASSED,
  WorkflowStatusKeysTypes.MANUALLY_FAILED,
  WorkflowStatusKeysTypes.UNCHECKED,
]

const profileIssueCategoryOrder: ProfileIssueCategoryTypes[] = Object.values(
  ProfileIssueCategoryTypes,
).sort()

export const profileIssueCategory2Columns: ProfileIssueCategoryTypes[][] = [
  profileIssueCategoryOrder.slice(
    0,
    Math.ceil(profileIssueCategoryOrder.length / 2),
  ),
  profileIssueCategoryOrder.slice(
    Math.ceil(profileIssueCategoryOrder.length / 2),
  ),
]

export const profileIssueCategory3Columns: ProfileIssueCategoryTypes[][] = [
  profileIssueCategoryOrder.slice(0, 6),
  profileIssueCategoryOrder.slice(6, 12),
  profileIssueCategoryOrder.slice(12),
]

export const profileRisksColumns: ProfileRiskLevelTypes[][] = [
  [ProfileRiskLevelTypes.LOW, ProfileRiskLevelTypes.MEDIUM],
  [ProfileRiskLevelTypes.HIGH, ProfileRiskLevelTypes.UNACCEPTABLE],
  [ProfileRiskLevelTypes.UNKNOWN],
]

export const profileRisksI18n: Record<
  ProfileRiskLevelTypes,
  I18nKeys<typeof profilesFilterEn>
> = {
  [ProfileRiskLevelTypes.LOW]: 'applicantRisks.low',
  [ProfileRiskLevelTypes.MEDIUM]: 'applicantRisks.medium',
  [ProfileRiskLevelTypes.HIGH]: 'applicantRisks.high',
  [ProfileRiskLevelTypes.UNACCEPTABLE]: 'applicantRisks.unacceptable',
  [ProfileRiskLevelTypes.UNKNOWN]: 'applicantRisks.unknown',
}

export const profileIssuesCategoriesI18n: Record<
  ProfileIssueCategoryTypes,
  I18nKeys<typeof profilesFilterEn>
> = {
  [ProfileIssueCategoryTypes.VERIFY]: 'profileIssueCategories.verify',
  [ProfileIssueCategoryTypes.DECEASED]: 'profileIssueCategories.deceased',
  [ProfileIssueCategoryTypes.EXPIRY]: 'profileIssueCategories.expiry',
  [ProfileIssueCategoryTypes.INFORMATIONAL]:
    'profileIssueCategories.informational',
  [ProfileIssueCategoryTypes.AML]: 'profileIssueCategories.aml',
  [ProfileIssueCategoryTypes.FRAUD]: 'profileIssueCategories.fraud',
  [ProfileIssueCategoryTypes.IDV]: 'profileIssueCategories.idv',
  [ProfileIssueCategoryTypes.DEVICE]: 'profileIssueCategories.device',
  [ProfileIssueCategoryTypes.TRANSACTION]: 'profileIssueCategories.transaction',
  [ProfileIssueCategoryTypes.WATCHLIST]: 'profileIssueCategories.watchlist',
  [ProfileIssueCategoryTypes.DUPLICATE]: 'profileIssueCategories.duplicate',
  [ProfileIssueCategoryTypes.BLOCKLISTED]: 'profileIssueCategories.blocklisted',
  [ProfileIssueCategoryTypes.SYSTEM]: 'profileIssueCategories.system',
}

export const workflowStatusesMapping = {
  UNCHECKED: [
    WorkflowExecutionResultEnum.UNCHECKED,
    WorkflowExecutionResultEnum.IN_PROGRESS,
    WorkflowExecutionResultEnum.COMPLETE,
  ],
  FAILED: [
    WorkflowExecutionResultEnum.FAIL,
    WorkflowExecutionResultEnum.INCOMPLETE,
    WorkflowExecutionResultEnum.REJECTED,
  ],
  PASSED: [
    WorkflowExecutionResultEnum.PASS,
    WorkflowExecutionResultEnum.APPROVED,
  ],
  NEEDS_ATTENTION: [
    WorkflowExecutionResultEnum.REVIEW,
    WorkflowExecutionResultEnum.NEEDS_APPROVAL,
    WorkflowExecutionResultEnum.BLOCKED,
    WorkflowExecutionResultEnum.URGENT,
    WorkflowExecutionResultEnum.MONITOR,
  ],
  MANUALLY_PASSED: [WorkflowExecutionResultEnum.PASS],
  MANUALLY_FAILED: [WorkflowExecutionResultEnum.FAIL],
}
