import React, { useMemo } from 'react'

import SuprSendInbox from '@suprsend/react-inbox'
import classNames from 'classnames'

import { FrankieButton } from 'frankify/src'

import { useApplicantsQueryInfinite } from 'features/applicants-filter'

import { ApplicantId, FrankieRawApplicant } from 'entities/applicant'

import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { notificationWidgetQa } from '../../qa/suprsend-notifications.qa'
import { NotificationCard } from '../notification-card/notification-card'

import './suprsend-notification.styles.css'

type Props = {
  distinctId: string
  subscriberId: string
  workspaceKey: string
  getApplicantPath: (
    id: ApplicantId,
    search?: { matchStatusFilter?: string },
  ) => string
}

export function SuprSendNotification({
  distinctId,
  subscriberId,
  workspaceKey,
  getApplicantPath,
}: Props) {
  const { data: applicantsQueryResponse, isInitialLoading } =
    useApplicantsQueryInfinite({})

  const allApplicants: FrankieRawApplicant[] = useMemo(() => {
    if (!applicantsQueryResponse?.pages) return []

    let all: FrankieRawApplicant[] = []

    for (const page of applicantsQueryResponse.pages) {
      all = all.concat(page.applicants)
    }

    return all
  }, [applicantsQueryResponse?.pages])

  const handleBellClick = () => {
    trackingManager.track(TrackingEventsTypes.NotificationShow)
  }

  return (
    <div className="mb-1.5 mr-1.5">
      <SuprSendInbox
        hideToast
        // eslint-disable-next-line react/no-unstable-nested-components
        notificationComponent={({ notificationData, markRead, markUnRead }) => (
          <NotificationCard
            markRead={markRead}
            markUnRead={markUnRead}
            applicants={allApplicants}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            notificationData={notificationData}
            getApplicantPath={getApplicantPath}
            isLoading={isInitialLoading}
          />
        )}
        // eslint-disable-next-line react/no-unstable-nested-components
        bellComponent={() => (
          <FrankieButton
            noStyles
            className="text-tertiary-grey-700"
            onClick={handleBellClick}
            singleIcon={{ name: 'mdiBell' }}
            testId={{ button: notificationWidgetQa.bell }}
          />
        )}
        workspaceKey={workspaceKey}
        subscriberId={subscriberId}
        distinctId={distinctId}
        popperPosition="bottom"
        // eslint-disable-next-line react/no-unstable-nested-components
        badgeComponent={({ count }) => (
          <div
            className={classNames(
              'absolute -top-0.5 left-[15px] h-[17px] ' +
                'flex items-center justify-center text-[10px] leading-[15px] ' +
                'font-bold bg-tertiary-red-600 rounded-full ' +
                'text-mono-white border border-solid border-mono-white',
              {
                'w-[17px]': !count || count <= 9,
                'w-fit px-1': count && count > 9,
              },
            )}
          >
            {count && count > 99 ? '99+' : count}
          </div>
        )}
      />
    </div>
  )
}
