import React from 'react'

import {
  isThisMinute,
  isThisHour,
  isToday,
  differenceInHours,
  differenceInSeconds,
  differenceInMinutes,
} from 'date-fns'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'

import {
  TABLES_COMMON_KEY,
  tablesCommonEn,
} from '../../locale/tables-common.en'

export function DateCell({
  date,
  className = '',
}: {
  date: string
  className?: string
}) {
  const t = useI18n([TABLES_COMMON_KEY], { keys: tablesCommonEn })

  const dateFromString = new Date(date)
  const nowDate = new Date()

  if (isThisMinute(dateFromString)) {
    const diffInSeconds = differenceInSeconds(nowDate, dateFromString)
    const diffToDisplay = diffInSeconds === 0 ? 1 : diffInSeconds

    return (
      <div
        className={`w-full h-full whitespace-nowrap px-4 py-3 flex flex-col justify-center ${className}`}
      >
        <span className="text-sm font-normal text-tertiary-grey-700">
          {/* @ts-ignore */}
          {t('second', {
            second: diffToDisplay,
            count: diffToDisplay,
          })}
        </span>
      </div>
    )
  }

  if (isThisHour(dateFromString)) {
    const diffInMinutes = differenceInMinutes(nowDate, dateFromString)
    const diffToDisplay = diffInMinutes === 0 ? 1 : diffInMinutes

    return (
      <div
        className={`w-full h-full whitespace-nowrap px-4 py-3 flex flex-col justify-center ${className}`}
      >
        <span className="text-sm font-normal text-tertiary-grey-700">
          {/* @ts-ignore */}
          {t('minutes', {
            minute: diffToDisplay,
            count: diffToDisplay,
          })}
        </span>
      </div>
    )
  }

  if (isToday(dateFromString)) {
    const diffInHours = differenceInHours(nowDate, dateFromString)
    const diffToDisplay = diffInHours === 0 ? 1 : diffInHours

    return (
      <div
        className={`w-full h-full whitespace-nowrap px-4 py-3 flex flex-col justify-center ${className}`}
      >
        <span className="text-sm font-normal text-tertiary-grey-700">
          {/* @ts-ignore */}
          {t('hours', {
            hour: diffToDisplay,
            count: diffToDisplay,
          })}
        </span>
      </div>
    )
  }

  return (
    <div
      className={`w-full h-full whitespace-nowrap px-4 py-3 flex flex-col justify-center ${className}`}
    >
      <span className="text-sm font-normal text-tertiary-grey-700">
        {formatDate(date, DateFormatTypes.DateNumbers)}
      </span>
      <span className="text-xs font-normal leading-none text-tertiary-grey-500">
        {formatDate(date, DateFormatTypes.Time)}
      </span>
    </div>
  )
}
