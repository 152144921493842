import React, { useMemo, useState } from 'react'

import {
  FrankieButton,
  FrankieIcon,
  FrankieLoader,
  FrankiePopover,
} from 'frankify/src'
import { Banner } from 'frankify/src/components/banner'

import { OrganisationInfo } from 'features/organisation-search'

import {
  ApplicantId,
  useApplicantDataQuery,
  useApplicantInternationalKYB,
} from 'entities/applicant'
import { useOrganisationProfile } from 'entities/organisation'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { MONITORING_OVERVIEW_KEY } from '../../monitoring-overview.key'
import { useHasInternationalUbo } from '../../state/applicant-has-international-ubo/applicant-has-international-ubo'
import {
  useIKybCheckStatus,
  useIKybReportCheckStatus,
} from '../../state/ikyb-aml-status/ikyb-aml-status'
import { useReportGeneration } from '../../state/ikyb-report-generation/ikyb-report-generation'
import { useOrganisationProfileInfo } from '../../state/organisation-profile-info/organisation-profile-info'
import { useGetBusinessRequestStatusInterval } from '../../state/request-status-interval/request-status-interval'
import { AmlCheckStatus } from '../aml-check-run-and-status/aml-check-run-and-status'
import { ArchieveProfileModal } from '../archieve-profile-modal/archieve-profile-modal'
import { ChecksToRun } from '../checks-to-run/checks-to-run'
import { CompletedCheck } from '../completed-check/completed-check'
import { KybDocumentCatalog } from '../kyb-document-catalog/kyb-document-catalog'
import { ReportErrorBanner } from '../report-error-banner/report-error-banner'
import { ReportProgressBanner } from '../report-progress-banner/report-progress-banner'
import { UpdateStatusModal } from '../update-status-modal/update-status-modal'

type MenuOptionType = 'updateProfile' | 'archiveProfile' | 'unarchiveProfile'
type ReportType = 'profile' | 'ownership'

type Props = {
  paramsEntityId?: ApplicantId
}

// eslint-disable-next-line complexity
export function MonitoringOverview({ paramsEntityId }: Props) {
  const t = useI18n([MONITORING_OVERVIEW_KEY])

  const { isInternationalKYB } = useApplicantInternationalKYB({
    applicantId: paramsEntityId,
  })

  const {
    requestStatus,
    refetchRequestStatus,
    isFetchingRequestStatus,
    ownershipRequestId,
    profileReportRequestId,
  } = useGetBusinessRequestStatusInterval({
    entityId: paramsEntityId!,
  })

  const generatingProfileReport =
    !!requestStatus?.PROFILE_REPORT.isProcessing || !!profileReportRequestId
  const generatingOwnershipReport = !!(
    requestStatus?.OWNERSHIP.isProcessing ||
    requestStatus?.OWNERSHIP_REPORT.isProcessing ||
    !!ownershipRequestId
  )

  const { data: applicantData, isFetching: isLoadingApplicant } =
    useApplicantDataQuery({
      applicantId: paramsEntityId,
    })

  const { data: organisationProfile, isFetching: isLoadingKYBprofile } =
    useOrganisationProfile({
      entityId: paramsEntityId,
    })

  const toggleReportGeneration = async (type: ReportType) => {
    await refetchRequestStatus()
  }

  const { generateOwnershipReport } = useReportGeneration({
    entityId: paramsEntityId!,
    toggleReportGeneration,
  })

  const { hasInternationalUBO } = useHasInternationalUbo({
    applicantId: paramsEntityId!,
  })

  const { organisationRowData } = useOrganisationProfileInfo({
    entityId: paramsEntityId,
  })

  const { showAmlStatus } = useIKybCheckStatus({ entityId: paramsEntityId! })
  const { hasCompletedReports } = useIKybReportCheckStatus({
    entityId: paramsEntityId!,
  })

  const [createOverlay, closeOverlay] = useOverlay()
  const [open, setOpen] = useState(false)

  const updateStatusOptions = [
    { title: 'Manually passed', value: 'pass' },
    { title: 'Manually failed', value: 'fail' },
  ]

  const handleMenuClick = (option: MenuOptionType) => {
    setOpen(false)

    if (option === 'updateProfile') {
      createOverlay(
        <UpdateStatusModal
          applicantId={paramsEntityId!}
          onClose={closeOverlay}
          options={updateStatusOptions}
        />,
        { className: 'w-[420px] min-h-[440px] p-7' },
      )
    } else {
      createOverlay(
        <ArchieveProfileModal
          applicantId={paramsEntityId!}
          onClose={closeOverlay}
          action={option === 'archiveProfile' ? 'archive' : 'unarchive'}
        />,
        { className: 'w-[420px] min-h-[400px] p-7' },
      )
    }
  }

  const showArchiveOption = !['ARCHIVED', 'INACTIVE'].includes(
    applicantData?.checkSummary.status.key as string,
  )
  const showUnarchiveOption = ['ARCHIVED'].includes(
    applicantData?.checkSummary.status.key as string,
  )

  const dropdownOptions = useMemo(() => {
    const options: {
      title: string
      value: MenuOptionType
      isHidden?: boolean
    }[] = [
      {
        title: t('updateProfile'),
        value: 'updateProfile',
      },
      {
        title: t('archiveProfile'),
        value: 'archiveProfile',
        isHidden: !showArchiveOption,
      },
      {
        title: t('unarchiveProfile'),
        value: 'unarchiveProfile',
        isHidden: !showUnarchiveOption,
      },
    ]

    return options
  }, [showArchiveOption, showUnarchiveOption, t])

  const isMaxCreditLimitReached = useMemo(() => {
    const blockingReasons =
      organisationProfile?.organization.blockingEntities &&
      Object.keys(organisationProfile.organization.blockingEntities).some(
        entity =>
          organisationProfile.organization.blockingEntities?.[
            entity
          ]?.blockingReasons.some(
            reason => reason.type === 'INSUFFICIENT_MAX_CREDIT_COST',
          ),
      )

    return !!blockingReasons
  }, [organisationProfile?.organization.blockingEntities])

  const getLastUpdatedDate = useMemo(() => {
    const reports = organisationProfile?.organization.documents?.REPORT
    if (!reports) return ''

    const ownershipReportTypeDate = reports
      .filter(report => report.subtype === 'ownership')
      .map(report => report.attachments.map(attachment => attachment.createdAt))
      .reduce((acc, curr) => [...acc, ...curr], [])
      .sort(
        (currDate, nextDate) =>
          new Date(nextDate).getTime() - new Date(currDate).getTime(),
      )[0]

    return ownershipReportTypeDate
      ? formatDate(ownershipReportTypeDate, DateFormatTypes.MonthDateAndTime)
      : ''
  }, [organisationProfile?.organization.documents?.REPORT])

  if (!applicantData) return null

  if (!isInternationalKYB) return null

  return (
    <div className="max-h-[calc(100vh-195px)] overflow-y-auto">
      {isMaxCreditLimitReached && !generatingOwnershipReport && (
        <div className="w-full px-8 overflow-auto mt-8">
          <Banner
            heading={t('banner.heading')}
            description={`${t('banner.description')} ${
              getLastUpdatedDate
                ? t('banner.descriptionUpdated', {
                    lastUpdated: getLastUpdatedDate,
                  })
                : ''
            }`}
            theme="warning"
            button={{
              name: 'generate-report',
              label: t('banner.buttonLabel'),
              onClick: generateOwnershipReport,
            }}
          />
        </div>
      )}
      <div className="flex justify-center bg-mono-white w-full px-8 overflow-auto">
        <FrankieLoader loading={isLoadingApplicant || isLoadingKYBprofile}>
          <div className="mt-2 flex justify-center bg-mono-white w-full overflow-auto">
            <div className="w-full">
              <ReportProgressBanner
                generatingOwnershipReport={generatingOwnershipReport}
                generatingProfileReport={generatingProfileReport}
              />
              <ReportErrorBanner
                profile={{
                  hasError: !!requestStatus?.PROFILE_REPORT.hasError,
                  requestedDate: requestStatus?.PROFILE_REPORT.requestedDate,
                }}
                ownership={{
                  hasError:
                    !!requestStatus?.OWNERSHIP.hasError ||
                    !!requestStatus?.OWNERSHIP_REPORT.hasError,
                  // either ownership generation is failed or ownership report generation is failed
                  requestedDate: requestStatus?.OWNERSHIP_REPORT.hasError
                    ? requestStatus.OWNERSHIP_REPORT.requestedDate
                    : requestStatus?.OWNERSHIP.requestedDate,
                  updatedDate: requestStatus?.OWNERSHIP_REPORT.hasError
                    ? requestStatus.OWNERSHIP_REPORT.updatedDate
                    : requestStatus?.OWNERSHIP.updatedDate,
                }}
                entityId={paramsEntityId}
              />

              <div className="mt-6 flex justify-between">
                <span className="inline-block text-tertiary-grey-800 leading-tight text-2xl font-bold">
                  {t('organisationOverview')}
                </span>
                <FrankiePopover
                  open={open}
                  onOpenChange={setOpen}
                  popoverRest={{ placement: 'bottom-end' }}
                  trigger={
                    <FrankieButton
                      intent="subtle"
                      size="xs"
                      className="!rounded-full !max-w-[32px]"
                      onClick={() => {
                        setOpen(prev => !prev)
                      }}
                    >
                      <FrankieIcon name="mdiDotsHorizontal" />
                    </FrankieButton>
                  }
                >
                  <div className="bg-mono-white shadow-md">
                    {dropdownOptions
                      .filter(option => !option.isHidden)
                      .map(option => (
                        //  eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions
                        <div
                          className="px-5 py-2 hover:bg-tertiary-grey-200 select-none cursor-pointer rounded-sm"
                          onClick={() => handleMenuClick(option.value)}
                          key={option.title}
                        >
                          <span>{option.title}</span>
                        </div>
                      ))}
                  </div>
                </FrankiePopover>
              </div>
              <div className="mt-8">
                <span className="text-tertiary-grey-800 text-lg font-bold">
                  {t('organisationInformation')}
                </span>
                <div className="mt-4">
                  <OrganisationInfo rows={organisationRowData} />
                </div>
              </div>
              {paramsEntityId && (hasCompletedReports || showAmlStatus) && (
                <div className="mt-6">
                  <div className="text-lg pb-[14px] font-bold text-tertiary-grey-800">
                    {t('completedChecksTitle')}
                  </div>
                  <div className="flex flex-col gap-4">
                    <AmlCheckStatus entityId={paramsEntityId} />
                    <CompletedCheck
                      title={t('organisationProfileTitle')}
                      type="profile"
                      entityId={paramsEntityId}
                      isGeneratingReport={generatingProfileReport}
                    />
                    {hasInternationalUBO && (
                      <CompletedCheck
                        title={t('ownershipProfileTitle')}
                        entityId={paramsEntityId}
                        type="ownership"
                        isGeneratingReport={generatingOwnershipReport}
                      />
                    )}
                  </div>
                </div>
              )}
              {paramsEntityId && (
                <div className="mt-6" id="checks-to-run">
                  <ChecksToRun
                    isGenerating={
                      generatingProfileReport ||
                      generatingOwnershipReport ||
                      isFetchingRequestStatus
                    }
                    entityId={paramsEntityId}
                    toggleReportGeneration={toggleReportGeneration}
                  />
                </div>
              )}
              <div className="mt-8">
                <KybDocumentCatalog />
              </div>
              <div className="mt-8 pb-16">
                {applicantData.checkSummary.checkDate && (
                  <>
                    <span className="text-xs font-normal text-tertiary-grey-500">
                      {t('lastUpdated')}
                    </span>
                    <span className="ml-2 text-xs font-semibold text-tertiary-grey-500">
                      {formatDate(
                        organisationProfile?.organization.updatedAt ??
                          applicantData.checkSummary.checkDate,
                        DateFormatTypes.MonthDateAndTimeShort,
                      )}
                    </span>
                  </>
                )}
                <span className="ml-4 text-xs font-normal text-tertiary-grey-500">
                  {t('frankieId')}
                </span>
                <span className="ml-2 text-xs font-semibold text-tertiary-grey-500">
                  {applicantData.applicantDetails.entityId}
                </span>
              </div>
            </div>
          </div>
        </FrankieLoader>
      </div>
    </div>
  )
}
