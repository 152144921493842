import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import {
  FieldArrayWithId,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'

import {
  FrankieButton,
  FrankieCheckbox,
  FrankieDivider,
  FrankieIcon,
  FrankieSelectField,
  FrankieTextField,
  FrankieTooltip,
} from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'
import { SuggestionString } from 'shared/typescript'

import { TrustAnalyserReference } from './trust-analyser-reference/trust-analyser-reference'
import {
  TrustCommonForm,
  TrustCommonFormProps,
} from './trust-common-forms/trust-common-form'
import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import {
  IDocumentInformation,
  ITrustAnalyse,
  ConfirmAnalysisResultPayload,
  trustTypesOption,
  TrustTypes,
} from '../../model/applicant-supporting-documents.model'
import {
  ITrustDeedForm,
  commonDefaultForm,
  TrustFormTypes,
  mapDataToForm,
  TrustFormKeys,
  mapTrustDeedFormToAnalysis,
  commonGeneralBeneficiaryForm,
  TrustFormLabelProps,
  TRUST_PARAM_KEY,
  TrustReferenceTypes,
  getReferenceAlphabet,
  getReferenceType,
  getReferenceNumber,
} from '../../model/applicant-supporting-trust-deed.model'
import {
  trustAnalyserReferenceDetailQa,
  trustAnalyserReferenceQa,
  trustDeedsDocQa,
} from '../../qa/applicant-support-documents.qa'
import { useTrustDeedConfirmAnalysis } from '../../state/trust-deeds-mutation/trust-deeds-mutation'

type GetTagIndexType = { originalIndex: number | undefined; fieldIndex: number }

export const MIN_WIDTH_TRUST_INFO = 700

export type TrustAnalyzerFormsProps = {
  data: ITrustAnalyse
  onReturnToTrustDeed: () => void
  setReferencePage: (page: number) => void
  onChangeTrustType: (type: TrustTypes) => void
}

export function TrustAnalyzerForms({
  data,
  onReturnToTrustDeed,
  setReferencePage,
  onChangeTrustType,
}: TrustAnalyzerFormsProps) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const params = useParams()
  const location = useLocation()

  const documentId = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get(TRUST_PARAM_KEY) as SuggestionString<'new'> | undefined
  }, [location.search])

  const { mutate, isLoading } = useTrustDeedConfirmAnalysis()

  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.TrustAnalyserAnalysisResultEdit)
  }, [])

  const copyData = useRef<ConfirmAnalysisResultPayload>({
    references: { ...data.references },
    documentInformation: data.documentInformation
      ? ({
          ...JSON.parse(JSON.stringify(data.documentInformation)),
        } as IDocumentInformation)
      : undefined,
  })

  const defaultValues = useRef<ITrustDeedForm>(mapDataToForm(data))

  const { register, control, watch, setValue, handleSubmit } =
    useForm<ITrustDeedForm>({
      defaultValues: defaultValues.current,
    })

  const {
    fields: settlorFields,
    append: appendSettlor,
    remove: removeSettlor,
  } = useFieldArray({
    control,
    name: 'settlor',
  })

  const {
    fields: trusteeFields,
    append: appendTrustee,
    remove: removeTrustee,
  } = useFieldArray({
    control,
    name: 'trustee',
  })

  const {
    fields: appointorFields,
    append: appendAppointor,
    remove: removeAppointor,
  } = useFieldArray({
    control,
    name: 'appointor',
  })

  const {
    fields: protectorFields,
    append: appendProtector,
    remove: removeProtector,
  } = useFieldArray({
    control,
    name: 'protector',
  })

  const {
    fields: generalBeneficiaryFields,
    append: appendGeneralBeneficiary,
    remove: removeGeneralBeneficiary,
  } = useFieldArray({
    control,
    name: 'beneficiary.generalBeneficiary',
  })

  const {
    fields: specifiedBeneficiaryFields,
    append: appendSpecifiedBeneficiary,
    remove: removeSpecifiedBeneficiary,
  } = useFieldArray({
    control,
    name: 'beneficiary.specifiedBeneficiary',
  })

  const {
    fields: unitHolderFields,
    append: appendUnitHolder,
    remove: removeUnitHolder,
  } = useFieldArray({
    control,
    name: 'unitHolder',
  })

  const {
    fields: memberFields,
    append: appendMember,
    remove: removeMember,
  } = useFieldArray({
    control,
    name: 'member',
  })

  const trustOptions = useMemo(
    () =>
      // remove trust type auto detect
      trustTypesOption.slice(1, 4).map(({ tKey, value }) => ({
        label: t(tKey),
        value,
      })),
    [t],
  )

  const onAdd = useMemo(
    () => (key: TrustFormTypes) => {
      const add = {
        [TrustFormTypes.Settlor]: () => appendSettlor(commonDefaultForm),
        [TrustFormTypes.Trustee]: () => appendTrustee(commonDefaultForm),
        [TrustFormTypes.Appointor]: () => appendAppointor(commonDefaultForm),
        [TrustFormTypes.Protector]: () => appendProtector(commonDefaultForm),
        [TrustFormTypes.GeneralBeneficiary]: () =>
          appendGeneralBeneficiary([commonGeneralBeneficiaryForm]),
        [TrustFormTypes.SpecifiedBeneficiary]: () =>
          appendSpecifiedBeneficiary(commonDefaultForm),
        [TrustFormTypes.UnitHolder]: () => appendUnitHolder(commonDefaultForm),
        [TrustFormTypes.Member]: () => appendMember(commonDefaultForm),
      }

      add[key]()
    },
    [
      appendSettlor,
      appendTrustee,
      appendAppointor,
      appendProtector,
      appendGeneralBeneficiary,
      appendSpecifiedBeneficiary,
      appendUnitHolder,
      appendMember,
    ],
  )

  const onRemove = useMemo(
    () => (key: TrustFormTypes, index: number) => {
      const remove = {
        [TrustFormTypes.Settlor]: removeSettlor,
        [TrustFormTypes.Trustee]: removeTrustee,
        [TrustFormTypes.Appointor]: removeAppointor,
        [TrustFormTypes.Protector]: removeProtector,
        [TrustFormTypes.GeneralBeneficiary]: removeGeneralBeneficiary,
        [TrustFormTypes.SpecifiedBeneficiary]: removeSpecifiedBeneficiary,
        [TrustFormTypes.UnitHolder]: removeUnitHolder,
        [TrustFormTypes.Member]: removeMember,
      }

      remove[key](index)
    },
    [
      removeSettlor,
      removeTrustee,
      removeAppointor,
      removeProtector,
      removeGeneralBeneficiary,
      removeSpecifiedBeneficiary,
      removeUnitHolder,
      removeMember,
    ],
  )

  const onSubmit: SubmitHandler<ITrustDeedForm> = submitData => {
    const result = mapTrustDeedFormToAnalysis(submitData, copyData.current)

    if (!documentId || !params.applicantId) {
      notification.error(t('confirmAnalysisFailed'))
      return
    }

    mutate({
      entityId: params.applicantId,
      documentId,
      payload: { analysisId: data.analysisId, ...result },
    })

    trackingManager.track(TrackingEventsTypes.TrustAnalyserAnalysisResultSave)
    trackingManager.track(
      TrackingEventsTypes.TrustAnalyserAnalysisResultFeedback,
    )
  }

  const getFieldName: {
    [key in TrustFormTypes]: FieldArrayWithId<
      ITrustDeedForm,
      TrustFormKeys,
      'id'
    >[]
  } = useMemo(
    () => ({
      [TrustFormTypes.Settlor]: settlorFields,
      [TrustFormTypes.Trustee]: trusteeFields,
      [TrustFormTypes.Appointor]: appointorFields,
      [TrustFormTypes.Protector]: protectorFields,
      [TrustFormTypes.GeneralBeneficiary]: generalBeneficiaryFields,
      [TrustFormTypes.SpecifiedBeneficiary]: specifiedBeneficiaryFields,
      [TrustFormTypes.UnitHolder]: unitHolderFields,
      [TrustFormTypes.Member]: memberFields,
    }),
    [
      settlorFields,
      trusteeFields,
      appointorFields,
      protectorFields,
      generalBeneficiaryFields,
      specifiedBeneficiaryFields,
      unitHolderFields,
      memberFields,
    ],
  )

  const getFieldLabel: { [key in TrustFormTypes]: TrustFormLabelProps } =
    useMemo(() => {
      const commonFieldLabel = {
        name: t('name'),
        dateIndividual: t('dateOfBirth'),
        dateOrganisation: t('abnOrAcn'),
        addressIndividual: t('address'),
        addressOrganisation: t('principalPlace'),
      }
      return {
        [TrustFormTypes.Settlor]: {
          type: `${t('settlor')} ${t('type')}`,
          ...commonFieldLabel,
        },
        [TrustFormTypes.Trustee]: {
          type: `${t('trustee')} ${t('type')}`,
          ...commonFieldLabel,
        },
        [TrustFormTypes.Appointor]: {
          type: `${t('appointor')} ${t('type')}`,
          ...commonFieldLabel,
        },
        [TrustFormTypes.Protector]: {
          type: `${t('protector')} ${t('type')}`,
          ...commonFieldLabel,
        },
        [TrustFormTypes.GeneralBeneficiary]: {},
        [TrustFormTypes.SpecifiedBeneficiary]: {
          type: `${t('beneficiary')} ${t('type')}`,
          ...commonFieldLabel,
        },
        [TrustFormTypes.UnitHolder]: {
          type: t('trustType'),
          ...commonFieldLabel,
        },
        [TrustFormTypes.Member]: {
          type: `${t('member')} ${t('type')}`,
          ...commonFieldLabel,
          addressIndividual: t('role'),
        },
      }
    }, [t])

  const getTitle: {
    [key in TrustFormTypes]: string
  } = useMemo(() => {
    const specifiedBeneficiaryTitle =
      generalBeneficiaryFields.length > 0 ? '' : t('beneficiaries')
    return {
      [TrustFormTypes.Settlor]: t('settlor'),
      [TrustFormTypes.Trustee]: t('trustees'),
      [TrustFormTypes.Appointor]: t('appointors'),
      [TrustFormTypes.Protector]: t('protectors'),
      [TrustFormTypes.GeneralBeneficiary]: t('beneficiaries'),
      [TrustFormTypes.SpecifiedBeneficiary]: specifiedBeneficiaryTitle,
      [TrustFormTypes.UnitHolder]: t('unitHolders'),
      [TrustFormTypes.Member]: t('members'),
    }
  }, [generalBeneficiaryFields.length, t])

  const getTag = useCallback(
    (
      enumKey: TrustFormTypes,
      { originalIndex, fieldIndex }: GetTagIndexType,
      formIndex: number,
    ) => {
      const numberRef = getReferenceNumber(formIndex)
      const alphabetRef = getReferenceAlphabet(originalIndex || 0)
      return (
        <TrustAnalyserReference
          text={`[${numberRef}${alphabetRef}]`}
          data={data}
          type={getReferenceType[enumKey]}
          getPageNumber={page => setReferencePage(page)}
          index={originalIndex}
          testId={{
            reference: trustAnalyserReferenceQa(enumKey, fieldIndex),
          }}
        />
      )
    },
    [data, setReferencePage],
  )

  const mapProps = useMemo(
    () =>
      Object.values(TrustFormTypes).flatMap((key, formIndex) => {
        const enumKey = key as TrustFormTypes
        return getFieldName[enumKey].map<TrustCommonFormProps>(
          (field, fieldIndex) => ({
            key: field.id,
            formKey: enumKey,
            title: getTitle[enumKey],
            label: getFieldLabel[enumKey],
            isLastItem: getFieldName[enumKey].length === fieldIndex + 1,
            onAdd: () => onAdd(enumKey),
            onRemove: () => onRemove(enumKey, fieldIndex),
            tag: getTag(
              enumKey,
              { originalIndex: field.index, fieldIndex },
              formIndex,
            ),
            index: fieldIndex,
            register,
            control,
            watch,
          }),
        )
      }),
    [
      control,
      getFieldLabel,
      getFieldName,
      getTag,
      getTitle,
      onAdd,
      onRemove,
      register,
      watch,
    ],
  )

  const { detected, provided } = data.documentInformation?.trust.type || {}

  return (
    <form
      className={`overflow-x-auto min-w-[${MIN_WIDTH_TRUST_INFO}px]`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="px-8">
        <div className="text-lg font-semibold my-4">{t('trustDetails')}</div>
        <div className="grid grid-cols-3 gap-4">
          <FrankieSelectField
            label={t('trustType')}
            placeholder={t('select')}
            options={trustOptions}
            value={provided || detected}
            onChange={e => onChangeTrustType(e.target.value as TrustTypes)}
            name="trustDetail.type"
            testId={{ input: trustDeedsDocQa.trustTypeSelector }}
            tag={
              <FrankieTooltip
                position="top"
                body={t('trustTypeTooltip')}
                className="min-w-[180px]"
              >
                <div>
                  <FrankieIcon
                    name="mdiInformation"
                    size="xs"
                    className="ml-2"
                  />
                </div>
              </FrankieTooltip>
            }
          />
          <FrankieTextField
            label={t('nameOfTrust')}
            className="col-span-2"
            {...register('trustDetail.name')}
            testId={{ input: trustDeedsDocQa.nameOfTrustField }}
            tag={
              <TrustAnalyserReference
                text={t('trustDetailReference.name')}
                data={data}
                type={TrustReferenceTypes.NAME_OF_TRUST}
                getPageNumber={page => setReferencePage(page)}
                testId={{ reference: trustAnalyserReferenceDetailQa.name }}
                className="top-1"
              />
            }
          />
          <FrankieTextField
            type="date"
            label={`${t('dateOfEstablishment')}`}
            maxLength={4}
            {...register('trustDetail.date')}
            testId={{ input: trustDeedsDocQa.dateOfEstablishmentField }}
            tag={
              <TrustAnalyserReference
                text={t('trustDetailReference.date')}
                data={data}
                type={TrustReferenceTypes.DATE_OF_ESTABLISHMENT}
                getPageNumber={page => setReferencePage(page)}
                testId={{ reference: trustAnalyserReferenceDetailQa.date }}
                className="top-1"
              />
            }
          />
          <FrankieTextField
            label={t('countryOfEstablishment')}
            className="col-span-2"
            {...register('trustDetail.country')}
            testId={{ input: trustDeedsDocQa.countryStateOfEstablishmentField }}
            tag={
              <TrustAnalyserReference
                text={t('trustDetailReference.country')}
                data={data}
                type={TrustReferenceTypes.COUNTRY_OF_ESTABLISHMENT}
                getPageNumber={page => setReferencePage(page)}
                testId={{ reference: trustAnalyserReferenceDetailQa.date }}
                className="top-1"
              />
            }
          />
        </div>
        <FrankieDivider className="my-4" />
        {mapProps.map(props => (
          <TrustCommonForm {...props} />
        ))}
      </div>
      <div className="sticky flex justify-between bottom-0 border-t border-tertiary-grey-200 w-full py-6 px-8 bg-mono-white">
        <div className="flex">
          <FrankieCheckbox
            {...register('checked')}
            testId={{ input: trustDeedsDocQa.reviewAndAgreeCheckmarkButton }}
          />
          <span
            onClick={() => setValue('checked', !watch('checked'))}
            onKeyDown={() => setValue('checked', !watch('checked'))}
            role="button"
            tabIndex={0}
            className="pl-3"
          >
            {t('reviewedTrust')}
          </span>
        </div>
        <div className="flex justify-end">
          <FrankieButton
            intent="secondary"
            onClick={onReturnToTrustDeed}
            testId={{ button: trustDeedsDocQa.cancelTrustAnalyserButton }}
          >
            {t('cancel')}
          </FrankieButton>
          <FrankieButton
            intent="primary"
            className="ml-2"
            disabled={!watch('checked') || isLoading}
            type="submit"
            testId={{ button: trustDeedsDocQa.saveTrustAnalyserButton }}
          >
            {t('save')}
          </FrankieButton>
        </div>
      </div>
    </form>
  )
}
