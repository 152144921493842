import React from 'react'

import { Outlet } from 'react-router-dom'

import { FrankieImage } from 'frankify/src'

export function LayoutAuth(): JSX.Element {
  console.log('Frankie:V:1.12.0')
  return (
    <div className="h-screen flex flex-initial justify-center tablet:items-start tablet:bg-tertiary-grey-50 tablet:pt-16">
      <div className="w-full tablet:w-[400px] bg-mono-white rounded-lg drop-shadow-sm pt-8">
        <div className="flex flex-initial justify-center pb-8 px-8 border-b border-tertiary-grey-200">
          <FrankieImage
            className="h-6"
            alt="FrankieOne Logo"
            src="frankie_logo_default_light"
            testId={{ image: 'auth-image-logo' }}
          />
        </div>
        <div className="px-6 tablet:px-8 py-6 tablet:py-8">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
