import { useEffect, useState } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useApplicantDataQuery } from 'entities/applicant'
import {
  organisationApi,
  BusinessRequestStatus,
  BusinessRequestType,
} from 'entities/organisation'

import { useReportRequestId } from '../report-request-id-query/report-request-id.query'

type BusinessRequestState = {
  currentStatus?: BusinessRequestStatus
  previousStatus?: BusinessRequestStatus
  isProcessing: boolean
  hasError: boolean
  requestedDate?: string
  updatedDate?: string
  requestId?: string
}

type Args = {
  entityId: string
  requestType: BusinessRequestType[]
}

type AllRequestStatus = Record<BusinessRequestType, BusinessRequestState>

export const BusinessRequestQueryKey = 'business-request-query-key'

const getBusinessRequestQueryKey = ({ entityId, requestType }: Args) => [
  BusinessRequestQueryKey,
  entityId,
  ...requestType,
]

/**
 * Sequence of request type matters
 * Ownership report generation gives ownership request first then after completion ownership report request
 */
const requestType: BusinessRequestType[] = [
  'PROFILE_REPORT',
  'OWNERSHIP',
  'OWNERSHIP_REPORT',
]

type Params = {
  entityId: string
}
export const useGetBusinessRequestStatusInterval = ({ entityId }: Params) => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>()
  const queryClient = useQueryClient()

  const { refetch: refetchApplicantData } = useApplicantDataQuery({
    applicantId: entityId,
  })

  const { data: profileReportRequestId, remove: removeProfileReportRequestId } =
    useReportRequestId('profile', entityId)

  const { data: ownershipRequestId, remove: removeOwnershipRequestId } =
    useReportRequestId('continue-ownership', entityId)

  const {
    data,
    refetch: refetchRequestStatus,
    isFetching,
  } = useQuery({
    queryKey: [BusinessRequestQueryKey, entityId],
    queryFn: async () => {
      // TODO: ADD query param for exact request type
      const { data } = await organisationApi.getBusinessRequestStatus(entityId)

      return data
    },
  })

  const { data: requestStatus } = useQuery<AllRequestStatus>({
    queryKey: getBusinessRequestQueryKey({ entityId, requestType }),
    cacheTime: 0,
  })

  useEffect(() => {
    if (data) {
      // Check if there is a request for ownership and profile
      let processing = false as boolean

      // Check if there is a request for ownership and profile
      const hasRequest = {
        profile: !profileReportRequestId,
        // Both of the ownership request and ownership report is dependent on ownershipRequestId
        ownership: !ownershipRequestId,
        ownershipReport: !ownershipRequestId,
      }

      // Flag to check if refetching of applicant data is required
      const refetchDocumentRequired = !!(
        profileReportRequestId || ownershipRequestId
      )

      let newOwnershipRequestDate: string | undefined
      const allRequestStatus = requestType.reduce((acc, curr) => {
        const sortedStatus = data.requests[curr]?.sort((a, b) =>
          b.requestedAt.localeCompare(a.requestedAt),
        )

        let currentStatus = sortedStatus?.[0]?.status
        let previousStatus = sortedStatus?.[1]?.status
        const requestId = sortedStatus?.[0]?.requestId
        const requestDate = sortedStatus?.[0]?.requestedAt
        const updateDate = sortedStatus?.[0]?.updatedAt

        switch (curr) {
          case 'PROFILE_REPORT':
            if (profileReportRequestId === requestId) {
              hasRequest.profile = true
              removeProfileReportRequestId()
            }

            break
          case 'OWNERSHIP':
            if (ownershipRequestId === requestId) {
              hasRequest.ownership = true
            }
            newOwnershipRequestDate = requestDate

            break
          case 'OWNERSHIP_REPORT':
            if (
              newOwnershipRequestDate &&
              requestDate &&
              hasRequest.ownership // If ownership request is completed then only check for ownership report
            ) {
              const lastOwnershipRequestDate = new Date(newOwnershipRequestDate)
              const lastOwnershipReportRequestDate = new Date(requestDate)

              if (lastOwnershipRequestDate > lastOwnershipReportRequestDate) {
                previousStatus = currentStatus
                currentStatus = 'PROCESSING'
              } else {
                hasRequest.ownershipReport = true
                removeOwnershipRequestId()
              }
            }
            break
          default:
            break
        }

        const state: BusinessRequestState = {
          currentStatus,
          previousStatus,
          isProcessing: currentStatus === 'PROCESSING',
          hasError: currentStatus === 'FAILED',
          requestedDate: requestDate,
          updatedDate: updateDate,
          requestId,
        }

        if (state.currentStatus === 'PROCESSING') {
          processing = true
        }

        return {
          ...acc,
          [curr]: state,
        }
      }, {} as AllRequestStatus)

      const queryKey = getBusinessRequestQueryKey({ entityId, requestType })
      queryClient.setQueryData(queryKey, allRequestStatus)

      const doesNotHaveRequest = Object.values(hasRequest).every(
        hasRequest => !hasRequest,
      )

      if (processing || doesNotHaveRequest) {
        // If any request is processing, set interval to refetch request status
        if (!intervalId) {
          setIntervalId(
            setInterval(() => {
              void refetchRequestStatus()
            }, 10000),
          )
        }
      } else {
        // If no request is processing, clear interval
        clearInterval(intervalId)
        setIntervalId(undefined)

        // Refetch applicant data if document is required for OWNERSHIP_REPORT
        if (refetchDocumentRequired) {
          void refetchApplicantData()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return {
    requestStatus,
    refetchRequestStatus,
    isFetchingRequestStatus: isFetching,
    profileReportRequestId,
    ownershipRequestId,
  }
}
