import React from 'react'

import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'

import {
  AmlIssuesTypes,
  ProcessResultManualStatusEnumAML,
} from 'entities/entity'
import { useApplicantRoute } from 'entities/routing'

import { useI18n } from 'shared/i18n'
import {
  TrackingEventsTypes,
  trackingManager,
  useTrackingShowEvents,
} from 'shared/tracking'

import { INDIVIDUAL_PROFILE_INSIGHT_KEY } from '../../individual-profile-insight.key'
import { individualProfileInsightEn } from '../../locale/individual-profile-insight.en'
import {
  amlIssuesToI18n,
  amlIssuesToTrackingEvent,
} from '../../model/individual-profile-insight.model'
import {
  getRouteTileQa,
  IndividualProfileAmlQa,
} from '../../qa/individual-profile-insight.qa'
import { useAmlProfileState } from '../../state/aml-profile.state'

type Props = {
  entityId: string
}

export function IndividualProfileAml({ entityId }: Props) {
  const t = useI18n(INDIVIDUAL_PROFILE_INSIGHT_KEY, {
    keys: individualProfileInsightEn,
  })

  const { countOfIssues } = useAmlProfileState({ entityId })

  const { generateRoute } = useApplicantRoute()
  const { ref, inView } = useInView()

  useTrackingShowEvents({
    data: inView,
    eventsType: TrackingEventsTypes.ProfileInsightsAmlViewIndividual,
  })

  return (
    <div
      className="flex flex-col gap-3"
      data-qa={IndividualProfileAmlQa.container}
      ref={ref}
    >
      <div className="flex justify-between">
        <div
          data-qa={IndividualProfileAmlQa.title}
          className="font-bold text-xl leading-7 text-tertiary-grey-800"
        >
          {t('amlSection.title')}
        </div>
        <div>
          <Link
            to={generateRoute({
              routeKey: 'amlScreening',
              overrideParams: { entityId },
            })}
            onClick={() => {
              trackingManager.track(
                TrackingEventsTypes.ProfileInsightViewAllClickIndividual,
              )
            }}
            className="text-primary-800 text-sm font-semibold"
            data-qa={IndividualProfileAmlQa.routeBtn}
          >
            {t('amlSection.cta')}
          </Link>
        </div>
      </div>
      <div className="flex justify-between gap-6 items-stretch flex-wrap">
        {Object.entries(countOfIssues).map(([amlIssue, count]) => (
          <Link
            to={generateRoute({
              routeKey: 'amlScreening',
              overrideParams: { entityId },
            })}
            state={{
              matchStatus: [
                ProcessResultManualStatusEnumAML.TRUE_POSITIVE,
                ProcessResultManualStatusEnumAML.UNKNOWN,
              ],
              issues: [amlIssue],
            }}
            onClick={() => {
              trackingManager.track(
                TrackingEventsTypes.ProfilesInsightAmlTilesClickIndividual,
                { tiles: amlIssuesToTrackingEvent[amlIssue as AmlIssuesTypes] },
              )
            }}
            data-qa={getRouteTileQa(
              amlIssuesToTrackingEvent[amlIssue as AmlIssuesTypes],
            )}
            className="w-[calc(50%-12px)] laptop:w-[calc(25%-18px)]"
          >
            <div className=" p-4 border h-full border-tertiary-grey-200 rounded-2">
              <div className="flex flex-col">
                <div className="font-semibold text-md leading-6 text-tertiary-grey-700">
                  {t(amlIssuesToI18n[amlIssue as AmlIssuesTypes])}
                </div>
                <div className="flex gap-6">
                  <div className="flex flex-col">
                    <h1 className="font-bold text-2xl text-tertiary-grey-800">
                      {count.TRUE_POSITIVE}
                    </h1>
                    <p className="text-xs leading-4 text-tertiary-grey-700">
                      {t('amlSection.truePositive')}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <h1 className="font-bold text-2xl text-tertiary-grey-800">
                      {count.UNKNOWN}
                    </h1>
                    <p className="text-xs leading-4 text-tertiary-grey-700">
                      {t('amlSection.unknown')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}
