import React from 'react'

import { ApplicantId } from 'entities/applicant'
import { PermissionTypes } from 'entities/role'
import { useHasFeatureFlag, useHasPermission } from 'entities/session'

import { Show } from 'shared/hoc'
import { useI18n } from 'shared/i18n'

import { APPLICANT_RISK_SCORE_KEY } from '../../applicant-risk-score.key'
import { applicantRiskScoreEn } from '../../locale/applicant-risk-score.en'
import { applicantRiskScoreQa } from '../../qa/applicant-risk-score.qa'
import { useApplicantRiskScoreState } from '../../state/applicant-risk-score-query/applicant-risk-score.query'
import { ApplicantManualOverride } from '../applicant-manual-override/applicant-manual-override'
import { ApplicantRiskScoreDataGrid } from '../applicant-risk-score-data-grid/applicant-risk-score-data-grid'

type Props = {
  applicantId: ApplicantId
  className?: string
}

export function ApplicantRiskScore({ applicantId, className = '' }: Props) {
  const t = useI18n([APPLICANT_RISK_SCORE_KEY], {
    keys: applicantRiskScoreEn,
  })

  const { hasTxnFeature } = useHasFeatureFlag({
    hasTxnFeature: 'transactionMonitoring',
  })

  const {
    hasTxnPermissionAML,
    hasTxnPermissionFraud,
    hasTxnPermissionCustomer,
  } = useHasPermission({
    hasTxnPermissionAML: PermissionTypes.AmlTransactionData,
    hasTxnPermissionFraud: PermissionTypes.FraudTransactionData,
    hasTxnPermissionCustomer: PermissionTypes.DeviceTransactionData,
  })

  const {
    riskData: { onboarding, amlMonitoring, fraudMonitoring },
    loading,
  } = useApplicantRiskScoreState({ applicantId })

  const containerClass = 'border border-tertiary-grey-200 py-8 px-12 rounded-md'

  return (
    <div
      className={`flex flex-col gap-12 mx-auto my-4 max-w-[1080px] text-tertiary-grey-700 ${className}`}
      data-qa={applicantRiskScoreQa.component}
    >
      <ApplicantRiskScoreDataGrid
        className={containerClass}
        heading={t('heading.onboarding')}
        data={onboarding}
        loading={loading}
        testId={{ container: applicantRiskScoreQa.dataGrid }}
      >
        <ApplicantManualOverride applicantId={applicantId} className="mt-8" />
      </ApplicantRiskScoreDataGrid>
      <Show onlyIf={hasTxnFeature} showAll>
        <Show.When isTrue={hasTxnPermissionCustomer || hasTxnPermissionFraud}>
          <ApplicantRiskScoreDataGrid
            className={containerClass}
            heading={t('heading.fraudMonitoring')}
            data={fraudMonitoring}
            loading={loading}
          />
        </Show.When>

        <Show.When isTrue={hasTxnPermissionAML}>
          <ApplicantRiskScoreDataGrid
            className={containerClass}
            heading={t('heading.amlMonitoring')}
            data={amlMonitoring}
            loading={loading}
          />
        </Show.When>
      </Show>
    </div>
  )
}
