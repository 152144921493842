import React, { useState } from 'react'

import { FrankieUtilityImg } from 'shared/FrankieImg'
import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { ImageGallery } from 'shared/image-gallery'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { INDIVIDUAL_OCR_CHECK_KEY } from '../../individual-ocr-check.key'
import { individualOcrCheckEn } from '../../locale/individual-ocr-check.en'

const images = [
  [
    'https://i.postimg.cc/sgkffVWR/Passport-country-Australia-1.png',
    'https://fastly.picsum.photos/id/58/1280/853.jpg?hmac=YO3QnOm9TpyM5DqsJjoM4CHg8oIq4cMWLpd9ALoP908',
  ],
  [
    'https://i.postimg.cc/KYTztKbz/Country-Australia-State-ACT-Side-Front-Highlight-None.jpg',
    'https://fastly.picsum.photos/id/58/1280/853.jpg?hmac=YO3QnOm9TpyM5DqsJjoM4CHg8oIq4cMWLpd9ALoP908',
  ],
  [
    'https://imgs.search.brave.com/7YXbKmxQL2eIzdcvXakbf_tAq0crm1yo2QZlCYOgpqA/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9pZ2x1/Lm5ldC93cC1jb250/ZW50L3VwbG9hZHMv/MjAxNy8wMS90aGFp/LWRyaXZlcnMtbGlj/ZW5zZS5qcGVn',
    'https://upload.wikimedia.org/wikipedia/commons/5/51/Imitation_official_document.jpg',
  ],
]

type Props = {
  gallery: string[]
  defaultIndex: number
  entityId: string
}
export function IndividualOcrOverlay({
  defaultIndex,
  gallery,
  entityId,
}: Props) {
  const [activeIndex, setActiveIndex] = useState(defaultIndex)
  const [activeDocument, setActiveDocument] = useState(0)

  const t = useI18n([INDIVIDUAL_OCR_CHECK_KEY], { keys: individualOcrCheckEn })

  const isVideo = ['MP4'].includes(
    gallery[activeIndex].split('.').at(-1)?.toUpperCase() || '',
  )

  const getHeaderSlot = (idx: number) => {
    const documents = [{ uploadedBy: 'Ryan Gosling' }, {}]
    return (
      <div className="flex justify-between">
        <div className="text-tertiary-grey-500">
          {t('modal.uploadedOn', {
            date: formatDate(
              new Date().toDateString(),
              DateFormatTypes.DateNumbersSlash,
            ),
            time: formatDate(
              new Date().toISOString(),
              DateFormatTypes.Time24HoursWithSeconds,
            ),
            via: documents[idx]?.uploadedBy
              ? t('modal.by', { name: documents[idx].uploadedBy })
              : t('modal.directCapture'),
          })}
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="text-xl mb-4 font-bold text-tertiary-grey-800">
        {t('modal.compareImages')}
      </div>
      <div className="flex gap-6">
        <div className="flex w-[400px] flex-col shrink gap-3">
          <div className="flex justify-between">
            <div className="text-tertiary-grey-500">
              {t('modal.uploadedOn', {
                date: formatDate(
                  new Date().toDateString(),
                  DateFormatTypes.DateNumbersSlash,
                ),
                time: formatDate(
                  new Date().toISOString(),
                  DateFormatTypes.Time24HoursWithSeconds,
                ),
                via: '',
              })}
            </div>
          </div>
          {!isVideo ? (
            <FrankieUtilityImg
              key={`${gallery[activeIndex]}${activeIndex}`}
              className=""
              allowZoom={false}
              gallery={[gallery[activeIndex]]}
            />
          ) : (
            <video
              loop
              controls
              onPlay={() => {
                trackingManager.track(
                  TrackingEventsTypes.WorkflowEventsBiometricsComparePlay,
                )
              }}
              key={gallery[activeIndex]}
              className="aspect-square mx-auto  bg-tertiary-grey-500 rounded-sm"
            >
              <track kind="captions" />
              <source src={gallery[activeIndex]} type="video/mp4" />
            </video>
          )}
          <ImageGallery
            gallery={gallery}
            selected={activeIndex}
            onChange={idx => {
              setActiveIndex(idx)
              trackingManager.track(
                TrackingEventsTypes.WorkflowEventsBiometricsThumbnailClick,
              )
            }}
          />
        </div>
        <div className="flex w-[400px] flex-col shrink gap-3">
          <FrankieUtilityImg
            key={activeDocument}
            className=""
            gallery={images[activeDocument]}
            headerSlot={getHeaderSlot}
            trackingEvents={{
              zoom: TrackingEventsTypes.WorkflowEventsBiometricsCompareDocZoom,
              rotate:
                TrackingEventsTypes.WorkflowEventsBiometricsCompareDocRotate,
              gallery: [
                TrackingEventsTypes.WorkflowEventsBiometricsCompareDocFront,
                TrackingEventsTypes.WorkflowEventsBiometricsCompareDocBack,
              ],
            }}
          />
          <ImageGallery
            gallery={images.map(doc => doc[0])}
            selected={activeDocument}
            onChange={idx => {
              setActiveDocument(idx)
              trackingManager.track(
                TrackingEventsTypes.WorkflowEventsBiometricsCompareDocSwitch,
              )
            }}
          />
        </div>
      </div>
    </div>
  )
}
