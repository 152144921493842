export const PROFILES_TABLE_KEY = 'profiles_table'

export const profilesTableEn = {
  entityStatus: {
    ManuallyPassed: 'Manually Passed',
    Passed: 'Passed',
    NeedsAttention: 'Needs attention',
    Failed: 'Failed',
    ManuallyFailed: 'Manually failed',
    Unchecked: 'Unchecked',
    Archived: 'Archieved',
    Inactive: 'Inactive',
  },
  applyingFilters: 'Applying filters...',
  clearingFilters: 'Clearing filters...',
  noMatchTitle: 'No match found with selected filters',
  loadingMoreData: 'Loading more data...',
  noMatchSubtitle:
    'Your selected filters did not return any matches. Adjust your filters or clear them, and try again.',
  giveFeedback: 'Give feedback',
  allProfiles: '{{count}} entities',
  allProfiles_one: '{{count}} entity',
  allProfiles_other: '{{count}} entities',
  filteredEntitiesNumber_one: 'Showing {{count}} entity',
  filteredEntitiesNumber_other: 'Showing {{count}} entities',
  searchedEntitiesNumber: 'Showing {{count}} results',
  searchedEntitiesNumber_one: 'Showing {{count}} result',
  searchedEntitiesNumber_other: 'Showing {{count}} results',
  entitiesTitle: 'Entities',
  needsReviewTitle: 'Needs review',
  notificationResultsTitle: 'Notification alert results',
  headers: {
    name: 'name',
    profileStatus: 'Profile status',
    issues: 'Issues',
    riskLevel: 'Risk level',
    createdDate: 'Created',
    lastUpdatedDate: 'Updated',
    lastWorkflow: 'Latest workflow',
    lastWorkflowStatus: 'Latest event status',
    assignee: 'Assignee',
  },
  second_one: '{{second}} second ago',
  second_other: '{{second}} seconds ago',
  minutes_one: '{{minute}} minute ago',
  minutes_other: '{{minute}} minutes ago',
  hours_one: '{{hour}} hour ago',
  hours_other: '{{hour}} hours ago',
  exportToCsvButton: 'Export as CSV',
  exportToCsvStart: 'Exporting entities to CSV file. Please wait.',
  exportToCsvSuccess: 'Entity CSV file ready.',
  exportToCsvError: 'Unable to export as a CSV file. Please try again later.',
  noSortTooltipCreated: 'Sort created',
  noSortTooltipUpdated: 'Sort updated',
  sortAsc: 'Sorted newest to oldest',
  sortDesc: 'Sorted oldest to newest',
}
