import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { UseFormReturn } from 'react-hook-form'

import {
  FrankieButton,
  FrankieCheckbox,
  FrankieIcon,
  FrankiePopover,
  FrankieTextField,
} from 'frankify/src'

import { countryList, stateList, streetTypes } from 'entities/country'

import { SelectFormField, getError } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { UK_POSTAL_CODE_PATTERN } from 'shared/validation'

import { INDIVIDUAL_PROFILE_KEY } from '../../individual-profile.keys'
import { individualProfileEn } from '../../locale/new-profile.en'
import {
  CountryTypes,
  DetailAddressResponse,
  NON_INTERNATIONAL_COUNTRIES,
  Places,
  labelMapData,
  optionalAddressFieldMap,
  fieldMap,
  getAddressValidation,
} from '../../model/address.model'
import {
  AddressType,
  IIndividualProfileInputs,
  IndividualProfileInputTypes,
  detailAddressInputs,
} from '../../model/form.model'
import {
  AddressFormQaType,
  addressFormQa,
  individualProfileVueMigratedQa,
} from '../../qa/individual-profile.qa'
import { useGetDetailAddress } from '../../state/getDetailAddress.data'
import { useGetAddressSuggestion } from '../../state/getSearchAddress.data'

export type AddressFormPropsType = {
  form: UseFormReturn<IIndividualProfileInputs>
  name: keyof IIndividualProfileInputs
  heading: string
  remove?: () => void
  qaType?: AddressFormQaType
  showSameAs?: ReactNode
  editMode?: boolean
  isOptionalField?: boolean
}

// eslint-disable-next-line complexity
export function AddressForm({
  form,
  name,
  heading,
  remove,
  qaType = 'current',
  showSameAs,
  editMode,
  isOptionalField,
}: AddressFormPropsType) {
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })

  const { register, setValue, watch, control, formState } = form

  const { errors } = formState

  const [openAddressSuggestion, setOpenAddressSuggestion] =
    useState<boolean>(false)

  const [selectedGoogleAddressId, setSelectedGoogleAddressId] =
    useState<string>('')

  const manualAddress = IndividualProfileInputTypes.ManualAddress

  const country = watch(
    `${name}.${IndividualProfileInputTypes.Country}` as keyof IIndividualProfileInputs,
  )

  const searchTerm = watch(
    `${name}.${IndividualProfileInputTypes.Search}` as keyof IIndividualProfileInputs,
  )

  const { data: detailAddress = undefined } = useGetDetailAddress(
    selectedGoogleAddressId,
  )

  const { data: placesData = [] } = useGetAddressSuggestion(
    searchTerm as string,
    [country] as string[],
  )

  const [detailAddressLabel, setDetailAddressLabel] = useState<string>(
    t('addressFrom.detailAddressHeading.empty'),
  )

  const [toggle, setToggle] = useState(false)

  const clearForm = useCallback(() => {
    const addressType = `${name}.${IndividualProfileInputTypes.ManualAddress}`
    const fieldArray = Object.keys(detailAddressInputs)
    for (const field of fieldArray) {
      setValue(`${addressType}.${field}` as keyof IIndividualProfileInputs, '')
    }
  }, [name, setValue])

  const countryType = useMemo(
    () =>
      NON_INTERNATIONAL_COUNTRIES.includes(country as CountryTypes)
        ? (country as CountryTypes)
        : CountryTypes.INTERNATIONAL,
    [country],
  )

  const setManualAddress = (
    addressType: AddressType,
    response: DetailAddressResponse | undefined,
  ) => {
    const data = response
    if (data) {
      const fieldArray = Object.keys(data) as (keyof DetailAddressResponse)[]
      for (const field of fieldArray) {
        const currentField = fieldMap[field]
        const value = data[field]
        if (
          Object.hasOwn(fieldMap, field) &&
          currentField &&
          data[field] !== null
        ) {
          setValue(
            `${addressType}.${currentField}` as keyof IIndividualProfileInputs,
            typeof value === 'string' ? value : '',
          )
        }
      }
    }
  }

  useEffect(() => {
    if (detailAddress) {
      setManualAddress(`${name}.${manualAddress}` as AddressType, detailAddress)
      setToggle(true)
      setDetailAddressLabel(t('addressFrom.detailAddressHeading.filled'))
      setValue(
        `${name}.${IndividualProfileInputTypes.Search}` as keyof IIndividualProfileInputs,
        detailAddress.longForm as string,
      )
      setSelectedGoogleAddressId('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailAddress])

  const countryOptions = useMemo(
    () =>
      countryList.map(country => ({
        label: country.name,
        value: country.alpha3code,
      })),
    [],
  )

  const registerSearch = register(
    `${name}.${IndividualProfileInputTypes.Search}` as keyof IIndividualProfileInputs,
  )

  const propertyNameFieldName =
    `${name}.${manualAddress}.${IndividualProfileInputTypes.PropertyName}` as keyof IIndividualProfileInputs

  const unitNumberFieldName =
    `${name}.${manualAddress}.${IndividualProfileInputTypes.UnitNumber}` as keyof IIndividualProfileInputs

  const streetNumberFieldName =
    `${name}.${manualAddress}.${IndividualProfileInputTypes.StreetNumber}` as keyof IIndividualProfileInputs

  const streetNameFieldName =
    `${name}.${manualAddress}.${IndividualProfileInputTypes.StreetName}` as keyof IIndividualProfileInputs

  const suburbFieldName =
    `${name}.${manualAddress}.${IndividualProfileInputTypes.Suburb}` as keyof IIndividualProfileInputs

  const townFieldName =
    `${name}.${manualAddress}.${IndividualProfileInputTypes.Town}` as keyof IIndividualProfileInputs

  const postalCodeFieldName =
    `${name}.${manualAddress}.${IndividualProfileInputTypes.PostalCode}` as keyof IIndividualProfileInputs

  const sameAsCurrentName =
    `${name}.${IndividualProfileInputTypes.SameAsCurrent}` as keyof IIndividualProfileInputs

  const sameAsCurrentWatch = watch(sameAsCurrentName)

  const handleSelectedAddress = (googleAddressId: string) => {
    setOpenAddressSuggestion(false)
    setSelectedGoogleAddressId(googleAddressId)
  }

  const handleToggle = () => {
    setToggle(prev => !prev)
  }

  const getStates = useMemo(() => {
    if (Object.hasOwn(stateList, country as string)) {
      return stateList[country as string]
    }
    return []
  }, [country])

  const getLabel = useCallback(
    (fieldName: string) => {
      const labelMap = labelMapData(t)

      if (!country && fieldName) return labelMap[fieldName].defaultLabel

      let selectedCountry = country
      if (
        !NON_INTERNATIONAL_COUNTRIES.includes(selectedCountry as CountryTypes)
      ) {
        selectedCountry = CountryTypes.INTERNATIONAL
      }
      let label = null

      if (Object.hasOwn(labelMap, fieldName)) {
        for (const item of labelMap[fieldName].label) {
          if (item.countries.includes(selectedCountry as CountryTypes)) {
            label = item.value
            break
          }
        }
        return label ?? labelMap[fieldName].defaultLabel
      }
      return ''
    },
    [country, t],
  )

  const handleSearchClear = () => {
    setValue(registerSearch.name, '')
    clearForm()
    setDetailAddressLabel(t('addressFrom.detailAddressHeading.empty'))
    setToggle(false)
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        name?.includes(`${name}.${IndividualProfileInputTypes.Country}`) &&
        type
      ) {
        handleSearchClear()
      }
    })

    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch])

  const testId = useMemo(() => addressFormQa(qaType), [qaType])

  useEffect(() => {
    if (formState.errors[name]) {
      setToggle(true)
    }
  }, [formState, name, setToggle])

  const watchAddressId = watch(
    `${name}.${IndividualProfileInputTypes.AddressId}` as keyof IIndividualProfileInputs,
  )
  const showSearchAddress = watchAddressId && editMode

  const isRequired = getAddressValidation(country as string)

  return (
    <div className="group/remove">
      <div className="relative flex justify-start items-center mt-4 max-w-max group/remove">
        <div className="text-lg mt-3 font-bold group/remove">{heading}</div>

        {!!remove && (
          <div className="absolute px-4 right-0 top-[15px] transform translate-x-full hidden group-hover/remove:block">
            <FrankieButton
              noStyles
              className="text-primary-600 whitespace-nowrap font-bold bg-transparent hover:bg-transparent hidden group-hover/remove:block"
              onClick={() => {
                handleSearchClear()
                remove()
              }}
              intent="secondary"
              testId={{ button: testId.remove }}
            >
              {t('removeAddress')}
            </FrankieButton>
          </div>
        )}
      </div>
      {showSameAs && (
        <div className="flex gap-2 py-2 mt-2 mb-[-10px]">
          <FrankieCheckbox
            onChange={() => {
              setValue(sameAsCurrentName, !sameAsCurrentWatch)
            }}
            checked={!!sameAsCurrentWatch}
          />
          <span>{t('addressFrom.sameAsCurrent')}</span>
        </div>
      )}
      {!sameAsCurrentWatch && (
        <div className="flex flex-wrap">
          <SelectFormField
            label={t('profileForm.country')}
            name={
              `${name}.${IndividualProfileInputTypes.Country}` as keyof IIndividualProfileInputs
            }
            className={`mt-4 mb-${showSearchAddress ? '5' : '2'} basis-3/5`}
            control={control}
            options={countryOptions}
            autocomplete
            testId={{ input: testId.country }}
          />
          {!showSearchAddress && (
            <>
              <div className="basis-3/5 bg-white-100 w-full">
                <FrankiePopover
                  open={
                    openAddressSuggestion && watch(registerSearch.name) !== ''
                  }
                  onOpenChange={(open, event) => {
                    // check if close icon is clicked by fetching data=-name of target icon
                    const target = event?.target as HTMLElement
                    if (
                      target.dataset.name === 'mdiClose' ||
                      target.parentElement?.dataset.name === 'mdiClose'
                    ) {
                      handleSearchClear()
                    }
                    setOpenAddressSuggestion(open)
                  }}
                  initialFocus={-1}
                  trigger={
                    <FrankieTextField
                      type="search"
                      closeButton={{
                        onClick: () => {
                          setValue(registerSearch.name, '')
                          clearForm()
                          setDetailAddressLabel(
                            t('addressFrom.detailAddressHeading.empty'),
                          )
                          setToggle(false)
                        },
                      }}
                      className="mt-4 mb-4"
                      {...registerSearch}
                      onClick={() => {
                        setOpenAddressSuggestion(true)
                      }}
                      label=""
                      placeholder={t('profileForm.searchAddress')}
                      isSearchIcon
                      testId={{
                        container:
                          individualProfileVueMigratedQa.autocompleteAddress,
                      }}
                    />
                  }
                >
                  <div className="w-full justify-start bg-surface max-w-[513px] min-w-[513px] border border-tertiary-grey-300 rounded-sm">
                    <div className="border-b border-tertiary-grey-300 py-1 w-full">
                      <p className="px-2">{t('suggestions')}</p>
                    </div>
                    <div>
                      <ul>
                        {!placesData.length && (
                          <li className="py-1 px-2 font-bold h-[48px] flex items-center">
                            {t('noSearchResults')}
                          </li>
                        )}
                        {!!placesData.length &&
                          placesData.map((item: Places) => (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                            <li
                              className="py-2 px-2 h-[48px] font-bold flex items-center cursor-pointer hover:bg-primary-50"
                              key={item.value}
                              onClick={() => handleSelectedAddress(item.value)}
                            >
                              {item.label}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </FrankiePopover>
              </div>

              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                className="basis-full w-full flex justify-items-start mt-5 cursor-pointer items-center"
                onClick={() => handleToggle()}
              >
                <FrankieIcon
                  name="mdiChevronRight"
                  className={`text-xl text-grey-300 ${
                    toggle ? 'rotate-90 duration-600' : 'rotate-0'
                  } `}
                  size="md"
                />
                {detailAddressLabel}
              </div>
            </>
          )}

          <div
            className={`${
              showSearchAddress || toggle ? 'block' : 'hidden'
            }  ease-in duration-400 `}
          >
            <div className="bg-neutral-20 rounded-md pt-5 pl-5">
              <div>
                <div className="pb-6">
                  <div className="flex flex-wrap">
                    <FrankieTextField
                      className="mt-4  mb-2 basis-2/4"
                      label={getLabel('propertyName')}
                      {...register(propertyNameFieldName)}
                      placeholder={t('addressFrom.propertyNamePlaceholder')}
                      testId={{ input: testId.buildingName }}
                    />
                    <div className="basis-[100%] flex-wrap flex gap-[2%]">
                      <FrankieTextField
                        className="mt-4 mb-2 basis-[15%]"
                        label={getLabel('unitNumber')}
                        placeholder={getLabel('unitNumber')}
                        testId={{ input: testId.unitNumber }}
                        {...register(unitNumberFieldName)}
                      />
                      <FrankieTextField
                        className="mt-4 mb-2 basis-[15%]"
                        label={getLabel('streetNumber')}
                        placeholder={getLabel('streetNumber')}
                        error={!!getError(streetNumberFieldName, errors)}
                        testId={{ input: testId.streetNumber }}
                        {...register(streetNumberFieldName, {
                          required: !isOptionalField && isRequired.STREETNUMBER,
                        })}
                      />
                      <FrankieTextField
                        className="mt-4 mb-2 basis-[40%]"
                        label={getLabel('streetName')}
                        placeholder={getLabel('streetName')}
                        error={!!getError(streetNameFieldName, errors)}
                        testId={{ input: testId.streetName }}
                        {...register(streetNameFieldName, {
                          required: !isOptionalField && isRequired.STREETNAME,
                        })}
                      />
                      <SelectFormField
                        className="mt-4 mb-2 basis-[20%]"
                        label={getLabel('streetType')}
                        control={control}
                        name={
                          `${name}.${manualAddress}.${IndividualProfileInputTypes.StreetType}` as keyof IIndividualProfileInputs
                        }
                        placeholder={getLabel('streetType')}
                        autocomplete
                        clearable
                        options={streetTypes.map(type => ({
                          label: type,
                          value: type,
                        }))}
                        testId={{ input: testId.streetType }}
                      />
                    </div>
                    <div className="basis-[100%] flex-wrap flex gap-[2%]">
                      {optionalAddressFieldMap.suburb.includes(countryType) && (
                        <FrankieTextField
                          className="mt-4 mb-2 basis-[32%]"
                          label={getLabel('suburb')}
                          placeholder={getLabel('suburb')}
                          error={!!getError(suburbFieldName, errors)}
                          testId={{ input: testId.suburb }}
                          {...register(suburbFieldName)}
                        />
                      )}

                      <FrankieTextField
                        className="mt-4 mb-2 basis-[32%] !w-[300px]"
                        label={getLabel('town')}
                        placeholder={getLabel('town')}
                        testId={{ input: testId.town }}
                        error={!!getError(townFieldName, errors)}
                        {...register(townFieldName, {
                          required: !isOptionalField && isRequired.TOWN,
                        })}
                      />

                      {optionalAddressFieldMap.state.includes(countryType) && (
                        <SelectFormField
                          name={
                            `${name}.${manualAddress}.${IndividualProfileInputTypes.State}` as keyof IIndividualProfileInputs
                          }
                          control={control}
                          className="mt-4 mb-2 basis-[40%]"
                          options={getStates}
                          placeholder={getLabel('state')}
                          label={getLabel('state')}
                          error={
                            !!getError(
                              `${name}.${manualAddress}.${IndividualProfileInputTypes.State}` as keyof IIndividualProfileInputs,
                              errors,
                            )
                          }
                          rules={{
                            required: !isOptionalField && isRequired.STATE,
                          }}
                          testId={{ input: testId.state }}
                        />
                      )}
                      <FrankieTextField
                        className="mt-4 mb-2 basis-[20%]"
                        label={getLabel('postalCode')}
                        error={!!getError(postalCodeFieldName, errors)}
                        placeholder={t('addressFrom.postalCodePlaceholder')}
                        {...register(postalCodeFieldName, {
                          required: !isOptionalField && isRequired.POSTALCODE,
                          validate: value => {
                            const postalCode = value.toString()
                            if (['AUS', 'NZL'].includes(country as string)) {
                              return postalCode.length === 4
                            }
                            if (country === 'GBR') {
                              // if country is GBR, postal code need to follow the pattern below
                              const regex = UK_POSTAL_CODE_PATTERN
                              return !!postalCode.match(regex)
                            }
                            return true
                          },
                        })}
                        testId={{ input: testId.postalCode }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
