import { useMutation, useQueryClient } from '@tanstack/react-query'

import { IProfileResponse, PROFILES_QUERY } from 'entities/entity'

import { CommonErrorType } from 'shared/error'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { individualApiF2 } from '../api/individual-f2.api'
import { INDIVIDUAL_PROFILE_F2_KEY } from '../individual-profile-f2.key'
import { individualProfileF2En } from '../locale/individual-profile-f2.en'
import { IIndividualProfileF2FormType } from '../model/individual-profile-f2-form.model'

type Args = {
  saveOnly: boolean
  onError: (formData: IIndividualProfileF2FormType) => void
}

export const useCreateIndividual = ({ saveOnly = true, onError }: Args) => {
  const t = useI18n(INDIVIDUAL_PROFILE_F2_KEY, { keys: individualProfileF2En })
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (body: IIndividualProfileF2FormType) => {
      const res = await individualApiF2.createIndividual(body, saveOnly)
      return res.data
    },
    onSuccess: data => {
      const { serviceProfiles } = data
      const serviceProfile = serviceProfiles[0]
      queryClient.setQueriesData(
        [PROFILES_QUERY],
        (oldData?: { pages?: IProfileResponse[] }) => {
          const oldPages = oldData?.pages
          if (oldPages) {
            const newPages = oldPages.map((page, index) => {
              const oldMatches = page.matches

              // Add a new match at the beginning of the first page
              if (index === 0) {
                oldMatches.unshift({ serviceProfile, fieldsMatched: {} })
              }

              // Remove the last match from the last page
              if (index === oldPages.length - 1) {
                oldMatches.pop()
              }

              return { ...page, matches: oldMatches }
            })
            const newData = { ...oldData, pages: newPages }

            return newData
          }
          return oldData
        },
      )
      notification.success(
        t('hasBeenCreated', {
          fullName: data.individual.name.displayName,
        }),
      )
    },
    onError: (
      error: CommonErrorType<{ formData: IIndividualProfileF2FormType }>,
    ) => {
      const formData = error.response?.data.responseData.formData

      if (formData) {
        onError(formData)
      }

      if (saveOnly) {
        trackingManager.track(
          TrackingEventsTypes.IndividualProfileCreateSaveErrorEntity,
        )
      } else {
        trackingManager.track(
          TrackingEventsTypes.IndividualProfileCreateSaveAndVerifyErrorEntity,
        )
      }
      notification.error(t('saveOnlyError'))
    },
  })
}
