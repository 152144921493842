import React, { useEffect, useRef, useState } from 'react'

import { FrankieButton } from 'frankify/src'

import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { ZoomImage } from './zoomed-img'

const rotateImg = [
  'rotate-[0deg]',
  'rotate-[90deg]',
  'rotate-[180deg]',
  'rotate-[270deg]',
]

export type Props = {
  gallery: string[]
  trackingEvents?: {
    zoom?: TrackingEventsTypes
    rotate?: TrackingEventsTypes
    gallery?: TrackingEventsTypes[]
  }
  className?: string
  allowZoom?: boolean
  headerSlot?: (idx: number) => React.ReactNode
  defaultIdx?: number
}
export function FrankieUtilityImg({
  className = '',
  allowZoom = true,
  gallery,
  headerSlot,
  trackingEvents,
  defaultIdx = 0,
}: Props) {
  const [rotateDeg, setRotateDeg] = useState<number[]>([0])
  const ref = useRef<number[]>([])

  const [imgIdx, setImgIdx] = useState(defaultIdx)

  const handleImageChange = (isNext: boolean) => () => {
    if (isNext) {
      setImgIdx(prev => prev + 1)
    } else {
      setImgIdx(prev => prev - 1)
    }
  }

  useEffect(() => {
    if (trackingEvents?.gallery?.[imgIdx] && !ref.current.includes(imgIdx))
      trackingManager.track(trackingEvents.gallery[imgIdx])

    ref.current.push(imgIdx)
  }, [imgIdx, trackingEvents])

  const handleRotate = () => {
    if (trackingEvents?.rotate) trackingManager.track(trackingEvents.rotate)

    setRotateDeg(prev => {
      const rotateArr = [...prev]
      if ((prev[imgIdx] ?? 0) + 1 === 4) {
        rotateArr[imgIdx] = 0
      } else {
        rotateArr[imgIdx] = (prev[imgIdx] ?? 0) + 1
      }
      return rotateArr
    })
  }

  return (
    <>
      {headerSlot && headerSlot(imgIdx)}
      <div
        className={`w-[405px] h-[405px] relative bg-tertiary-grey-500 rounded-sm flex justify-center items-center ${className}`}
      >
        <ZoomImage
          allowZoom={allowZoom}
          src={gallery[imgIdx]}
          trackingEvent={trackingEvents?.zoom}
          rotateDeg={rotateImg[rotateDeg[imgIdx] ?? 0]}
        />

        <div className=" border border-tertiary-grey-200 rounded-sm bg-mono-white absolute bottom-2 left-2">
          <FrankieButton
            intent="subtle"
            size="xs"
            className="border border-solid border-tertiary-grey-200 rounded-none"
            onClick={handleRotate}
            singleIcon={{ name: 'mdiRotateRight' }}
          />
        </div>

        {gallery.length > 1 && (
          <div className="border border-tertiary-grey-200 flex bg-mono-white rounded-sm absolute bottom-2 right-2">
            <FrankieButton
              intent="subtle"
              disabled={imgIdx === 0}
              size="xs"
              className="border-r border-solid border-tertiary-grey-200 rounded-none"
              onClick={handleImageChange(false)}
              singleIcon={{ name: 'mdiChevronLeft' }}
            />
            <FrankieButton
              intent="subtle"
              size="xs"
              disabled={imgIdx === gallery.length - 1}
              onClick={handleImageChange(true)}
              singleIcon={{ name: 'mdiChevronRight' }}
            />
          </div>
        )}
      </div>
    </>
  )
}
