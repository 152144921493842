import { useMutation, useQueryClient } from '@tanstack/react-query'

import { organisationApi } from '../../api/organisation.api'
import {
  InternationalReportType,
  getReportRequestIdQueryKey,
} from '../../model/organisation.model'

type Args = {
  entityId: string
}

export const useContinueOwnershipReportGeneration = (
  reportType: InternationalReportType,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ entityId }: Args) =>
      organisationApi.continueOwnershipReportGeneration({
        entityId,
      }),
    onSuccess: (response, { entityId }) => {
      const queryKeyWithReportType = getReportRequestIdQueryKey(
        reportType,
        entityId,
      )
      queryClient.setQueryData(queryKeyWithReportType, response.data.requestId)
    },
  })
}
