import React from 'react'

import { IndividualAmlScreeningDataGrid } from '../individual-aml-screening-data-grid/individual-aml-screening-data-grid'
import { IndividualAmlScreeningSummary } from '../individual-aml-screening-summary/individual-aml-screening-summary'

type Props = {
  entityId: string
  workflowExecutionId?: string
  defaultOffset?: number
}

export function IndividualAmlScreening({
  entityId,
  workflowExecutionId,
  defaultOffset,
}: Props) {
  return (
    <div className="w-full flex flex-col gap-4">
      <IndividualAmlScreeningSummary
        workflowExecutionId={workflowExecutionId}
        entityId={entityId}
      />
      <IndividualAmlScreeningDataGrid
        workflowExecutionId={workflowExecutionId}
        entityId={entityId}
        defaultOffset={defaultOffset}
      />
    </div>
  )
}
