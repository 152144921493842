export const ENTITIES_TABLE_KEY = 'entities_table'

export const entitiesTableEn = {
  entityStatus: {
    ManuallyPassed: 'Manually Passed',
    Passed: 'Passed',
    NeedsAttention: 'Needs attention',
    Failed: 'Failed',
    ManuallyFailed: 'Manually failed',
    Unchecked: 'Unchecked',
    Archived: 'Archieved',
    Inactive: 'Inactive',
  },
  applyingFilters: 'Applying filters...',
  clearingFilters: 'Clearing filters...',
  noMatchTitle: 'No match found with selected filters',
  loadingMoreData: 'Loading more data...',
  noMatchSubtitle:
    'Your selected filters did not return any matches. \nAdjust your filters or clear them, and try again.',
  giveFeedback: 'Give feedback',
  filteredEntitiesNumber_one: 'Showing {{count}} entity',
  filteredEntitiesNumber_other: 'Showing {{count}} entities',
  searchedEntitiesNumber: 'Showing {{count}} results',
  searchedEntitiesNumber_one: 'Showing {{count}} result',
  searchedEntitiesNumber_other: 'Showing {{count}} results',
  entitiesTitle: 'Entities',
  notificationResultsTitle: 'Notification alert results',
  needsReviewTitle: 'Needs review',
  headers: {
    name: 'name',
    entityStatus: 'Entity status',
    issues: 'Issues',
    riskLevel: 'Risk level',
    createdDate: 'Created',
    lastUpdatedDate: 'Updated',
    recipe: 'Recipe',
    assignee: 'Assignee',
  },
  second_one: '{{second}} second ago',
  second_other: '{{second}} seconds ago',
  minutes_one: '{{minute}} minute ago',
  minutes_other: '{{minute}} minutes ago',
  hours_one: '{{hour}} hour ago',
  hours_other: '{{hour}} hours ago',
  exportToCsvButton: 'Export as CSV',
  exportToCsvStart: 'Exporting entities to CSV file. Please wait.',
  exportToCsvSuccess: 'Entity CSV file ready.',
  exportToCsvError: 'Unable to export as a CSV file. Please try again later.',
  noSortTooltipCreated: 'Sort created',
  noSortTooltipUpdated: 'Sort updated',
  sortAsc: 'Sorted newest to oldest',
  sortDesc: 'Sorted oldest to newest',
  deleteView: 'Delete view',
  deleteModal: {
    title: 'Are you sure you want to delete this view?',
    cancel: 'No, cancel',
    confirm: 'Yes, I’m sure',
  },
}
