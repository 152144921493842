import { Nullable } from 'shared/typescript'

import type { IUser } from '../../user'

export type OrganisationId = number
export type CustomerId = string
export type CustomerChildId = Nullable<string>

export interface IOrganisation {
  id: OrganisationId
  customerId: CustomerId
  customerChildId: Nullable<CustomerId>
  parentId: Nullable<OrganisationId>
  isRoot: boolean
  name: string
  nickName: string
  timezone: string // TODO: @ggrigorev Enum
  children?: IOrganisation[]
  users?: IUser[]
  parent?: Nullable<{ name: string; id?: number; users?: IUser[] }>
}

export type OrganisationRegistrationDetail = {
  registrationNumber: string
  registrationNumberType?: string
  registrationStatus?: {
    code?: string
    description: string
  }
  registeredDate?: string
  registeredName?: string
  registryDescription?: string
}

export type OrganisationAddress = {
  addressId?: string
  country: string
  postalCode: string
  subdivision?: string
  type: string
  unstructuredLongForm?: string
  district?: string
  longForm?: string
}

export interface IMatchedOrganization {
  country: string // only AUS
  organizationToken: string
  organizationCountry?: string
  organisationType?: {
    code: string
    description: string
  }
  addresses?: OrganisationAddress[]
  organizationType?: {
    code: string
    description: string
  }[]
  organizationName?: string
  organizationStatus?: string
  registrationDetails: OrganisationRegistrationDetail[]
  name?: { name?: string }
  alternameNames?: {
    name: string
  }[]
}

export type BlockingReasonsType = {
  code?: string
  description?: string
  type?: string
}

export type BlockingEntityType = {
  blockingReasons: BlockingReasonsType[]
  entityId: string
  entityType: string
}

export type OrganisationProfile = {
  organization: {
    details?: {
      name: {
        name: string
      }
      type?: {
        code?: string
        description?: string
      }
      status?: {
        normalized?: {
          code?: string
          description?: string
        }
        unstructured?: {
          code?: string
          description?: string
        }
      }
      jurisdiction?: {
        country: string
        subdivision?: string
      }
      organizationJurisdiction?: { country: string; subdivision?: string }
      organizationType?: { code: string }
      organizationName: string
      registrationDetails: {
        registeredName?: string
        registeredCountry?: string
        registeredDate?: { unstructured?: string; normalized?: string }
        registeredState: string
        registrationNumber: string
        registrationStatus?: { description?: string }
        registryDescription?: string
      }[]
    }
    addresses?: OrganisationAddress[]
    documents?: {
      REPORT: {
        attachments: {
          attachmentId: string
          createdAt: string
          data: {
            uri: string
          }
        }[]
        documentId: string
        subtype: string
        type: string
      }[]
    }
    updatedAt?: string
    blockingEntities?: Record<string, BlockingEntityType>
  }
}

export type OrganisationStatus = {
  entityId: string
  request: {
    status: 'PROCESSING' | 'COMPLETE' | 'FAILED'
    responseUrl: string
  }
  requestId: string
}

function trim(dirty: string) {
  return dirty.replace(/\s/g, '')
}

// copy from vue portal
export function isAbn(value: string) {
  // The 11 digit ABN is structured as a 9 digit identifier with two leading check digits. The leading check digits are derived using a modulus 89 (remainder after dividing by 89) calculation.

  // To verify an ABN:

  // Subtract 1 from the first (left-most) digit of the ABN to give a new 11 digit number
  // Multiply each of the digits in this new number by a "weighting factor" based on its position as shown in the table below
  // Sum the resulting 11 products
  // Divide the sum total by 89, noting the remainder
  // If the remainder is zero the number is a valid ABN
  const trimmedValue = trim(value)
  const isNumeric = !Number.isNaN(trimmedValue)
  const isLongEnough = trimmedValue.length === 11
  return isNumeric && isLongEnough
}

export function isAcn(value: string) {
  const trimmedValue = trim(value)
  const isNumeric = !Number.isNaN(trimmedValue)
  const isLongEnough = trimmedValue.length === 9
  return isNumeric && isLongEnough
}

const queryKey = 'report-request-id-query-eky'

export type InternationalReportType =
  | 'profile'
  | 'ownership'
  | 'continue-ownership'

export const getReportRequestIdQueryKey = (
  reportType: InternationalReportType,
  entityId: string,
) => [queryKey, reportType, entityId]
