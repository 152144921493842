import { useMutation, useQueryClient } from '@tanstack/react-query'

import { organisationApi } from '../../api/organisation.api'
import { getReportRequestIdQueryKey } from '../../model/organisation.model'

type Args = {
  entityId: string
  reportType: 'profile' | 'ownership'
}

export const useGenerateReportROW = (reportType: 'profile' | 'ownership') => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ entityId, reportType }: Args) =>
      organisationApi.generateReportROW(entityId, reportType),
    onSuccess: (response, { entityId }) => {
      const queryKeyWithReportType = getReportRequestIdQueryKey(
        reportType,
        entityId,
      )
      queryClient.setQueryData(queryKeyWithReportType, response.data.requestId)
    },
  })
}
