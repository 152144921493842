import React, { useEffect } from 'react'

import { useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { FrankieButton } from 'frankify/src'

import { IPartialProfileFilter } from 'entities/entity'
import { paths, useApplicantRoute } from 'entities/routing'
import { useHasFeatureFlag } from 'entities/session'

import { TextFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { CUSTOM_VIEWS_KEYS } from '../../custom-view.key'
import { customViewEn } from '../../locale/custom-view.en'
import { VIEW_LIMIT } from '../../model/custom-views.model'
import { customViewModalQa } from '../../qa/custom-view.qa'
import { useCustomView } from '../../states/custom-view-state/custom-view.state'
import { useCreateCustomView } from '../../states/mutation/create-custom-view/create-custom-view.mutation'
import { useUpdateCustomView } from '../../states/mutation/update-custom-view/update-custom-view.mutation'
import { CustomViewLoader } from '../custom-view-loader/custom-view-loader'

type SaveViewProps = {
  onCloseOverlay: () => void
  mode?: 'save' | 'new'
  submitFilter?: (filters: IPartialProfileFilter) => void
  filterData?: IPartialProfileFilter

  onClose?: () => void
  defaultName?: string
  handleNavigate?: (viewId?: string, query?: string) => void
  customViewStateChange?: () => void
}

type CreateView = {
  viewName: string
}

const MaxChar = 24

export function useNavigateToCustomView(saveBtn?: boolean) {
  const { generateRoute } = useApplicantRoute()
  const navigate = useNavigate()

  const handleNavigate = (viewId?: string, query?: string) => {
    let finalRoute
    if (viewId && query) {
      const route = generateRoute({
        routeKey: 'customView',
        overrideParams: { viewId },
      })
      finalRoute = `${route}?${query}`
    } else {
      finalRoute = '.'
    }

    if (saveBtn) {
      navigate(finalRoute, {
        state: { openModal: false, hideBtn: true },
        replace: true,
      })
    } else {
      navigate(finalRoute)
    }
  }

  return { handleNavigate }
}

export function CustomViewSaveModal({
  onCloseOverlay,
  submitFilter,
  filterData,
  onClose,
  mode,
  defaultName,
  handleNavigate,
  customViewStateChange,
}: SaveViewProps) {
  const t = useI18n([CUSTOM_VIEWS_KEYS], { keys: customViewEn })
  const { data } = useCustomView()
  const id = window.location.pathname.split('/')[2]
  const {
    mutate,
    isLoading,
    isSuccess,
    data: mutateData,
  } = useCreateCustomView()

  const {
    mutate: updateView,
    isLoading: isViewUpdating,
    isSuccess: isUpdatingSuccess,
  } = useUpdateCustomView()

  const { hasCustomViews } = useHasFeatureFlag({
    hasCustomViews: 'customViews',
  })

  const handleMutate = (name: string) => {
    const payloadData = {
      name,
      query: window.location.search.slice(1),
    }

    if (mode === 'save') {
      updateView({ data: payloadData, viewId: id })
    } else {
      mutate(payloadData)
    }
    if (customViewStateChange) customViewStateChange()
  }

  const {
    control,
    formState: { isValid },
    handleSubmit,
    watch,
  } = useForm<CreateView>({
    defaultValues: {
      viewName: defaultName ?? '',
    },
    mode: 'onTouched',
  })

  const viewName = watch('viewName')

  useEffect(() => {
    if (isSuccess || isUpdatingSuccess) {
      if (handleNavigate)
        handleNavigate(
          mutateData?.customViews.id.toString(),
          mutateData?.customViews.query,
        )
      onCloseOverlay()
      if (onClose) onClose()
    }
  }, [isSuccess, isUpdatingSuccess])

  const onSubmitHandler = (data: CreateView) => {
    if (submitFilter && filterData) {
      submitFilter(filterData)
    }

    handleMutate(data.viewName)

    if (window.location.href.includes(paths.applicants)) {
      trackingManager.track(
        TrackingEventsTypes.CustomViewCreateFromSaveButtonFromCustomSection,
      )
    }
  }

  const title =
    mode === 'save' ? t('saveFilter.saveTitle') : t('saveNewView.saveNewTitle')
  const subTitle = mode === 'save' ? t('saveFilter.saveSubtile') : undefined
  const btnText = mode === 'save' ? t('saveFilter.submitBtn') : undefined

  const text = mode === 'save' ? t('loading.onUpdate') : t('loading.onSave')

  if (!hasCustomViews) return null

  return (
    <>
      <CustomViewLoader
        text={text}
        loader={isLoading || isViewUpdating}
        testId={{ loader: customViewModalQa.saveModalLoader }}
      />

      <div className="w-[420px]" data-qa={customViewModalQa.container}>
        <div className="flex flex-col">
          <div className="flex flex-col gap-4">
            <div className="flex justify-between flex-grow-0">
              <div
                className="text-tertiary-grey-800 font-bold text-xl leading-7"
                data-qa={customViewModalQa.titleContainer}
              >
                {mode ? title : t('customViewSaveModal.saveView')}
              </div>
            </div>

            <div
              className="leading-5"
              data-qa={customViewModalQa.subTitleContainer}
            >
              {subTitle ?? (
                <>
                  <div>{t('customViewSaveModal.leftViewTitle')}</div>

                  <p>
                    <Trans
                      components={{ span: <span className="font-semibold" /> }}
                    >
                      {t('customViewSaveModal.leftViewText', {
                        dataLength:
                          VIEW_LIMIT - (data?.customViews.length ?? 0),
                      })}
                    </Trans>
                  </p>
                </>
              )}
            </div>

            <form
              className="flex flex-col gap-4 justify-between"
              onSubmit={handleSubmit(onSubmitHandler)}
            >
              <div className="flex flex-col gap-2">
                <TextFormField
                  name="viewName"
                  shouldUnregister
                  control={control}
                  showErrorText
                  label={t('customViewSaveModal.viewNameLabel')}
                  rules={{
                    required: true,
                    validate: value => {
                      const viewExists = data?.customViews.some(
                        view => view.name === value && +view.id !== +id,
                      )

                      return viewExists ? t('sameViewErrorText') : true
                    },
                  }}
                  maxLength={MaxChar}
                  testId={{ input: customViewModalQa.viewInput }}
                />
                <span
                  className="text-tertiary-grey-500 text-sm"
                  data-qa={customViewModalQa.leftCharacterContainer}
                >
                  {t('customViewSaveModal.characterLeft', {
                    leftCharacter: MaxChar - viewName.length,
                  })}
                </span>
              </div>
              <div className="flex flex-grow-0 justify-end">
                <FrankieButton
                  className="mr-8"
                  noStyles
                  onClick={() => onCloseOverlay()}
                  testId={{ button: customViewModalQa.cancelBtn }}
                >
                  {t('customViewSaveModal.cancel')}
                </FrankieButton>
                <FrankieButton
                  size="sm"
                  disabled={!isValid}
                  type="submit"
                  testId={{ button: customViewModalQa.saveBtn }}
                >
                  {btnText ?? t('customViewSaveModal.save')}
                </FrankieButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
