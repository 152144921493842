import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import {
  FrankieAvatar,
  FrankieButton,
  FrankiePopover,
  FrankieTextField,
  FrankieTooltip,
} from 'frankify/src'

import { entityAssignQa } from 'entities/entity/qa/entity.qa'
import { useUserListState } from 'entities/session'

import { useI18n } from 'shared/i18n'

import { ENTITY_KEY } from '../../entity.key'
import { entityEn } from '../../locale/entity.en'
import { EntityID } from '../../model/entity.model'
import { useAssignEntityMutation } from '../../mutation/entity-assign/entity-assign.mutation'

type Props = {
  entityId?: EntityID
}

export function AssignEntity({ entityId }: Props) {
  const [open, setOpen] = useState(false)

  const { mutate, data, isLoading } = useAssignEntityMutation(entityId)

  const t = useI18n(ENTITY_KEY, { keys: entityEn })
  const [assignedUser, setAssignedUser] = useState('')
  const [searchUser, setSearchUser] = useState('')

  const userList = useUserListState()

  const filteredUserList = useMemo(() => {
    const selectedUser = userList.find(user => user.value === assignedUser)
    const filteredUser = userList.filter(
      user =>
        user.value !== assignedUser &&
        user.value !== 'unassigned' &&
        user.label.toLowerCase().includes(searchUser.toLowerCase()),
    )
    if (selectedUser) filteredUser.unshift(selectedUser)

    return filteredUser
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchUser, userList, open])

  const handleSelect = (user: string) => {
    if (isLoading) return
    setAssignedUser(assignedUser => {
      let newAssignedUser = user
      if (assignedUser === user) {
        newAssignedUser = ''
      }

      void mutate({ assignee: newAssignedUser })

      return newAssignedUser
    })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchUser(e.target.value)
  }

  useEffect(() => {
    if (
      data?.serviceProfiles?.[0]?.assignee &&
      assignedUser !== data.serviceProfiles[0]?.assignee
    ) {
      setAssignedUser(data.serviceProfiles[0]?.assignee)
    }
  }, [data])

  return (
    <div
      className="flex gap-2 items-center gap-3"
      data-qa={entityAssignQa.assignEntityWrapper}
    >
      <span className="font-medium text-xs text-tertiary-grey-800">
        {t('title.assignee')}
      </span>

      <FrankieTooltip
        textClassName="text-center"
        position="top"
        title={
          <span data-hj-suppress>
            {filteredUserList.find(user => user.value === assignedUser)
              ?.label || t('assignEntity.unassigned')}
          </span>
        }
      >
        <div>
          <FrankiePopover
            open={open}
            onOpenChange={setOpen}
            trigger={
              <FrankieButton
                noStyles
                className="flex"
                testId={{ button: entityAssignQa.assignCTA }}
                onClick={() => setOpen(open => !open)}
              >
                <FrankieAvatar
                  name={
                    filteredUserList.find(i => i.value === assignedUser)
                      ?.label || assignedUser
                  }
                  icon={
                    !assignedUser
                      ? {
                          size: 'sm',
                          name: 'mdiAccountPlusOutline',
                          className: classNames(
                            'text-tertiary-grey-700 border-none bg-tertiary-grey-100 p-[6.5px] align-center rounded-full',
                            open && 'outline outline-primary-200 outline-3',
                          ),
                        }
                      : undefined
                  }
                />
              </FrankieButton>
            }
          >
            <div
              className="bg-mono-white shadow-md pb-2 mr-4 rounded-sm"
              data-qa={entityAssignQa.assignPopoverWrapper}
            >
              <div className="text-tertiary-grey-500 p-4">
                <div className="text-tertiary-grey-500 mb-1 text-xs font-medium">
                  {t('assignEntity.assignTo')}
                </div>
                <FrankieTextField
                  isSearchIcon
                  type="search"
                  onChange={handleChange}
                  searchIconPlacement="end"
                  className="text-tertiary-grey-500"
                  placeholder={t('assignEntity.placeholder')}
                  testId={{ input: entityAssignQa.searchField }}
                />
              </div>

              <div className="max-h-[165px] scrollbar scrollbar-sm overflow-auto pl-4 ">
                {filteredUserList.map((item, index) => (
                  <div className="py-[6px]">
                    <FrankieButton
                      testId={{
                        button: `${entityAssignQa.searchItem}-${index}`,
                      }}
                      noStyles
                      className="flex gap-2 items-center text-tertiary-grey-700"
                      onClick={() => handleSelect(item.value)}
                      startIcon={{
                        name: 'mdiCheckCircle',
                        // className: 'text-primary-800',
                        size: 'sm',
                        className:
                          assignedUser === item.value
                            ? 'text-primary-800 '
                            : 'text-tertiary-grey-200 scale-75 bg-tertiary-grey-200 rounded-full',

                        ...(isLoading && assignedUser === item.value
                          ? {
                              name: 'mdiSyncCircle',
                              className:
                                'bg-mono-white text-primary-800 animate-spin rounded-full',
                            }
                          : {}),
                      }}
                    >
                      <span data-hj-suppress>{item.label}</span>
                    </FrankieButton>
                  </div>
                ))}
              </div>
            </div>
          </FrankiePopover>
        </div>
      </FrankieTooltip>
    </div>
  )
}
