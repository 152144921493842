import { useQueryClient } from '@tanstack/react-query'

import { TrustDeedTypes } from 'entities/applicant/model/applicant-trust-analyser.model'

import {
  ConfirmAnalysisResultPayload,
  ITrustAnalyse,
  TrustTypes,
} from '../../model/applicant-supporting-documents.model'
import { ApplicantUploadedTrustDeedRecord } from '../../model/applicant-supporting-trust-deed.model'

export const applicantTrustDeedDocumentQueryKey =
  'applicant-trust-deed-document'
export const trustDeedAnalysisResultQueryKey = 'trust-deed-analysis-result'
export const trustDeedAnalysisStatusQueryKey = 'trust-deed-analysis-status'

function getQueryResultTrustType(
  oldData: ITrustAnalyse | undefined,
  provided: TrustTypes,
  analysisId: string,
): ITrustAnalyse | undefined {
  if (!oldData?.documentInformation) {
    return oldData
  }

  const { trust } = oldData.documentInformation
  const { discretionary, selfManagedSuperFund, unit } = trust.typeInformation
  const { detected } = trust.type

  const type = { detected, provided }
  const typeValue = provided || detected

  return {
    ...oldData,
    analysisId,
    documentInformation: {
      ...oldData.documentInformation,
      trust: {
        ...trust,
        type,
        typeInformation: {
          ...trust.typeInformation,
          discretionary: {
            ...discretionary,
            type: typeValue,
          },
          selfManagedSuperFund: {
            ...selfManagedSuperFund,
            type: typeValue,
          },
          unit: {
            ...unit,
            type: typeValue,
          },
        },
      },
    },
  }
}

function updateTrustListStatus(
  oldData: ApplicantUploadedTrustDeedRecord[] | undefined,
  status: TrustDeedTypes | undefined,
  documentId: string,
) {
  return oldData
    ? oldData.map(item => {
        if (item.id === documentId) {
          return {
            ...item,
            status,
          }
        }
        return item
      })
    : oldData
}

function removeTrustListType(
  oldData: ApplicantUploadedTrustDeedRecord[] | undefined,
  documentId: string,
) {
  return oldData ? oldData.filter(item => item.id !== documentId) : oldData
}

type analysisResultTypeIds = {
  analysisId: string
  entityId: string
  documentId: string
}

export function useQueryUpdater() {
  const queryClient = useQueryClient()

  return {
    updateStatusQuery: (status: TrustDeedTypes, documentId: string) =>
      queryClient.setQueryData(
        [trustDeedAnalysisStatusQueryKey, documentId],
        status,
      ),
    updateTrustListStatusQuery: (
      status: string,
      entityId: string,
      documentId: string,
    ) => {
      const trustListQueryData = queryClient.getQueryData<
        ApplicantUploadedTrustDeedRecord[]
      >([applicantTrustDeedDocumentQueryKey, entityId])

      const updatedTrustListData = updateTrustListStatus(
        trustListQueryData,
        status as TrustDeedTypes,
        documentId,
      )

      queryClient.setQueryData<ApplicantUploadedTrustDeedRecord[]>(
        [applicantTrustDeedDocumentQueryKey, entityId],
        updatedTrustListData as ApplicantUploadedTrustDeedRecord[],
      )
    },
    updateAnalysisResultTypeQuery: (
      type: TrustTypes,
      { analysisId, entityId, documentId }: analysisResultTypeIds,
    ) => {
      const queryResultKey = [
        trustDeedAnalysisResultQueryKey,
        entityId,
        documentId,
      ]
      const resultQueryData =
        queryClient.getQueryData<ITrustAnalyse>(queryResultKey)

      const updatedQueryData = getQueryResultTrustType(
        resultQueryData,
        type,
        analysisId,
      )

      queryClient.setQueryData(queryResultKey, updatedQueryData)
    },
    updateAnalysisResultQuery: (
      payload: ConfirmAnalysisResultPayload,
      entityId: string,
      documentId: string,
    ) => {
      const queryResultKey = [
        trustDeedAnalysisResultQueryKey,
        entityId,
        documentId,
      ]
      const resultQueryData =
        queryClient.getQueryData<ITrustAnalyse>(queryResultKey)

      const updatedQueryData = {
        ...resultQueryData,
        ...payload,
      }

      queryClient.setQueryData(queryResultKey, updatedQueryData)
    },
    removeTrustListQuery: (entityId: string, documentId: string) => {
      const trustListQueryData = queryClient.getQueryData<
        ApplicantUploadedTrustDeedRecord[]
      >([applicantTrustDeedDocumentQueryKey, entityId])

      const removeTrustListData = removeTrustListType(
        trustListQueryData,
        documentId,
      )

      queryClient.setQueryData<ApplicantUploadedTrustDeedRecord[]>(
        [applicantTrustDeedDocumentQueryKey, entityId],
        removeTrustListData as ApplicantUploadedTrustDeedRecord[],
      )
    },
  }
}
