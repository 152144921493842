import React, { useMemo } from 'react'

import { FrankieButton, FrankieLoader, FrankieTooltip } from 'frankify/src'

import { ApplicantId, useApplicantDataQuery } from 'entities/applicant'
import { checksRegex, ChecksTypes } from 'entities/check'
import { getFullRecipesList } from 'entities/recipe'
import { PermissionTypes } from 'entities/role'
import {
  useHasFeatureFlag,
  useHasPermission,
  useSessionQuery,
} from 'entities/session'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { useOverlay } from 'shared/overlay'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../locale/applicant-general-information.en'
import {
  CheckCardInfo,
  ChecksOptions,
  checksDataGenerator,
  getTxnAMLDetail,
  getTxnCustomerDetail,
  getTxnFraudDetail,
  getDeviceCheckDetail,
  getKYCDeviceFraudDetail,
} from '../../model/checks.model'
import {
  applicantGeneralInformationVueMigratedQa,
  applicantOverviewQa,
} from '../../qa/applicant-general-information.qa'
import { useApplicantCheckSummaryState } from '../../state/applicant-check-summary-state/applicant-check-summary-state'
import { useApplicantTransactionRecordState } from '../../state/applicant-transaction-record-state/applicant-transaction-record.state'
import { ApplicantActionMenu } from '../applicant-action-menu/applicant-action-menu'
import { ApplicantActionModal } from '../applicant-action-menu/applicant-action-modal/applicant-action-modal'
import { ApplicantCheckCard } from '../applicant-check-card/applicant-check-card'
import { ApplicantStatusBanner } from '../applicant-status-banner/applicant-status-banner'

type Props = {
  applicantId: ApplicantId
}

// eslint-disable-next-line complexity
export function ApplicantOverview({ applicantId }: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { data: pageData } = useSessionQuery()

  const { hasPermissionToRemoveBlacklist, ...txnPermission } = useHasPermission(
    {
      hasPermissionToRemoveBlacklist: PermissionTypes.ApplicantRemoveBlacklist,
      hasTxnPermissionAML: PermissionTypes.AmlTransactionData,
      hasTxnPermissionFraud: PermissionTypes.FraudTransactionData,
      hasTxnPermissionCustomer: PermissionTypes.DeviceTransactionData,
    },
  )

  const {
    hasConSardine,
    hasTxnFeature,
    isAllowGenerateKYCReport,
    hasFraudAlerts,
  } = useHasFeatureFlag({
    hasConSardine: ['connectors', 'con_sardine'],
    hasTxnFeature: 'transactionMonitoring',
    hasFraudAlerts: 'fraudAlerts',
    isAllowGenerateKYCReport: 'KYCIndividualReports',
  })

  const { isLoading: fetchingTxn, transactionCheckState } =
    useApplicantTransactionRecordState({
      applicantId,
      onError: () => notification.error(t('error.fetchingTransaction')),
    })

  const {
    data: applicantData,
    isLoading,
    isRefetching,
  } = useApplicantDataQuery({ applicantId })
  const loadingApplicantData = isLoading || isRefetching

  const { isEntityIsUnchecked, lastCheckDate } = useApplicantCheckSummaryState({
    applicantId,
  })

  const [createOverlay, closeOverlay] = useOverlay()

  const openGenerateIndividualReportModal = () => {
    createOverlay(
      <ApplicantActionModal
        handleClose={closeOverlay}
        applicantId={applicantId}
        type="GenerateIndividualVerificationReport"
      />,
      { className: 'p-0' },
    )
  }

  /**
   * Onboarding Summary Checks
   */
  const onboardingSummary: CheckCardInfo[] = useMemo(() => {
    if (!applicantData) return []

    const { checkResults, issues } = applicantData.checkSummary
    const { fraudData } = applicantData.applicantDetails

    const result: CheckCardInfo[] = []

    const options: ChecksOptions = {
      isDupBlResolved: !!issues.DUPLICATE_RESOLVED,
      isManualBlacklisted: !!issues.MANUAL_BLOCKLIST,
      hasPermissionToRemoveBlacklist,
      hasConSardine,
      fraudData,
    }

    checkResults.forEach(checkResult => {
      const { type } = checkResult
      const resultType = checkResult.result.type.toLowerCase()

      const findRegMatch = Object.entries(checksRegex).find(([_key, regex]) =>
        regex.test(type),
      )

      const showFraudAlerts = hasFraudAlerts && hasConSardine

      if (findRegMatch) {
        const matchedKey = findRegMatch[0] as ChecksTypes

        if (hasConSardine && matchedKey === 'deviceRisk') {
          if (showFraudAlerts) {
            result.push(getDeviceCheckDetail(resultType, options))
          } else {
            result.push(getKYCDeviceFraudDetail(resultType))
          }
        } else {
          const checksDataGeneratorFn = checksDataGenerator[matchedKey]
          result.push(checksDataGeneratorFn(resultType, options))
        }
      }
    })

    return result
  }, [
    applicantData,
    hasConSardine,
    hasFraudAlerts,
    hasPermissionToRemoveBlacklist,
  ])

  /**
   * Monitoring Summary Checks
   */
  const monitoringSummary: CheckCardInfo[] = useMemo(() => {
    const {
      hasTxnPermissionAML,
      hasTxnPermissionCustomer,
      hasTxnPermissionFraud,
    } = txnPermission

    const checkStack: CheckCardInfo[] = []
    if (fetchingTxn || !hasTxnFeature) return checkStack

    if (hasTxnPermissionAML) {
      checkStack.push(getTxnAMLDetail(transactionCheckState.AML))
    }
    if (hasTxnPermissionFraud) {
      checkStack.push(getTxnFraudDetail(transactionCheckState.FRAUD))
    }
    if (hasTxnPermissionCustomer) {
      checkStack.push(getTxnCustomerDetail(transactionCheckState.CUSTOMER))
    }
    return checkStack
  }, [
    txnPermission,
    fetchingTxn,
    hasTxnFeature,
    transactionCheckState.AML,
    transactionCheckState.FRAUD,
    transactionCheckState.CUSTOMER,
  ])

  /**
   * Applicant Recipe
   */
  const recipe = getFullRecipesList(pageData).find(
    recipe =>
      recipe.value.toLowerCase() ===
      applicantData?.applicantDetails.profile.profileType?.toLowerCase(),
  )?.label

  const maxWidthClass = 'max-w-[700px]'

  return (
    <FrankieLoader loading={fetchingTxn || loadingApplicantData}>
      <div
        className={`text-tertiary-grey-700 ${
          monitoringSummary.length > 0 ? 'w-full' : `${maxWidthClass} m-auto`
        }`}
        data-qa={applicantGeneralInformationVueMigratedQa.checkEntityProfile}
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex-grow-0 flex items-center gap-1">
            <div className="text-xl font-bold my-4 ">{t('overview')}</div>

            {isAllowGenerateKYCReport && (
              <FrankieTooltip
                title={t('action.generateNewIndividualVerificationReport')}
                position="right"
                className="min-w-[310px]"
              >
                <FrankieButton
                  noStyles
                  onClick={openGenerateIndividualReportModal}
                  singleIcon={{
                    className: 'text-tertiary-grey-300',
                    name: 'mdiFileOutline',
                    size: 'sm',
                  }}
                />
              </FrankieTooltip>
            )}
          </div>

          <div className="flex items-center gap-2 flex-grow-0">
            <div className="text-neutral-90">{t('recipe')}:</div>
            <div className="font-bold min-w-[65px]">{recipe}</div>
            <ApplicantActionMenu applicantId={applicantId} />
          </div>
        </div>

        <ApplicantStatusBanner
          applicantId={applicantId}
          className="rounded-sm overflow-hidden mt-2 mb-4"
        />

        <div
          className="flex flex-wrap gap-8 items-start justify-center"
          data-qa={applicantOverviewQa.summary}
        >
          {!!onboardingSummary.length && (
            <div className={`min-w-[530px] ${maxWidthClass} flex-grow`}>
              <div className="flex flex-col gap-4">
                <div className="text-lg font-semibold">
                  {t('onboardingSummary')}
                </div>

                {onboardingSummary.map(item => (
                  <ApplicantCheckCard
                    key={`${item.title}-${item.state}`}
                    {...item}
                    applicantId={applicantId}
                  />
                ))}
              </div>
            </div>
          )}
          {!!monitoringSummary.length && (
            <div className={`min-w-[530px] ${maxWidthClass} flex-grow`}>
              <div className="flex flex-col gap-4">
                <div className="text-lg font-semibold">
                  {t('monitoringSummary')}
                </div>

                {monitoringSummary.map(item => (
                  <ApplicantCheckCard {...item} applicantId={applicantId} />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="mt-8 mb-4 flex gap-2 text-xs">
          {isEntityIsUnchecked ? (
            <div>{t('status.noChecksRun')}</div>
          ) : (
            <>
              <div className="text-neutral-90">{t('lastCheckDate')}</div>
              {lastCheckDate && (
                <div className="font-bold">
                  {formatDate(lastCheckDate, DateFormatTypes.DateNumbers)}{' '}
                  {formatDate(lastCheckDate, DateFormatTypes.Time)}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </FrankieLoader>
  )
}
