/* eslint-disable complexity */
import React, { useEffect } from 'react'

import { isToday } from 'date-fns'

import { FrankieAvatar, FrankieDivider, FrankieLoader } from 'frankify/src'

import {
  ApplicantEntityTypes,
  ApplicantId,
  ApplicantResponse,
  FrankieRawApplicant,
  useApplicantDataQuery,
} from 'entities/applicant'
import { useHasFeatureFlag } from 'entities/session'

import { Menu } from 'shared/components'
import {
  DateFormatTypes,
  formatDate,
  formatDistanceToNow,
} from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { FrankieLink } from 'shared/react-router'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { Message } from './message/message'
import { SUPRSEND_KEY, suprsendEn } from '../../locale/en'
import {
  matchStatusFilterMapping,
  notificationBatchIcons,
  notificationBusinessIcons,
  NotificationEventTypes,
  notificationIndividualIcons,
} from '../../model/suprsend-notifications.model'
import { notificationWidgetQa } from '../../qa/suprsend-notifications.qa'
import {
  useSuprSendNotificationState,
  useSuprSendNotificationStateUpdate,
} from '../../state/suprsend-notification/suprsend-notification.state'

type Props = {
  applicants: FrankieRawApplicant[]
  getApplicantPath: (
    id: ApplicantId,
    search?: { matchStatusFilter?: string },
  ) => string
  isLoading: boolean
  notificationData: {
    created_on: number
    seen_on?: number
    n_id: string
    message?: {
      url?: string
      text?: string
      avatar?: {
        avatar_url: string
      }
      extra_data?: string // JSON
    }
  }
  markRead: () => void
  markUnRead: () => void
}

type ExtraData = {
  entity_id?: ApplicantId
  event?: string
  status?: string
  risk_from?: string
  risk_to?: string
  document_name?: string
}

export function NotificationCard({
  notificationData,
  applicants,
  getApplicantPath,
  isLoading,
  markRead,
  markUnRead,
}: Props) {
  const t = useI18n([SUPRSEND_KEY], { keys: suprsendEn })
  const { created_on, seen_on, message } = notificationData
  const { extra_data, text } = message || {}

  const { data: suprSendNotificationTimeLine } = useSuprSendNotificationState()
  const { trustAnalyser } = useHasFeatureFlag({
    trustAnalyser: 'trustAnalyser',
  })

  const getParseExtraData = () => {
    try {
      return JSON.parse(extra_data || '{}') as ExtraData
    } catch {
      return {} as ExtraData
    }
  }

  const dataParsed: ExtraData = getParseExtraData()
  const { entity_id, event, status, document_name } = dataParsed

  const isBatchedEntity = entity_id?.includes(',')

  let applicant: FrankieRawApplicant | ApplicantResponse | undefined =
    isBatchedEntity
      ? undefined
      : applicants.find(item => item.entityId === entity_id)

  const { data, isInitialLoading: isLoadingSingle } = useApplicantDataQuery({
    applicantId: entity_id,
    enabled: !isLoading && !applicant && !isBatchedEntity,
  })

  if (!isLoading && !applicant) {
    applicant = data
  }

  let name = ''
  let type = ''
  if (applicant) {
    if ('entityType' in applicant) {
      type = applicant.entityType
      name = applicant.entityName ?? ''
    }
    if ('applicantDetails' in applicant) {
      const { givenName, middleName, familyName, displayName } =
        applicant.applicantDetails.name

      type = applicant.businessInfo
        ? ApplicantEntityTypes.Organisation
        : ApplicantEntityTypes.Individual
      name =
        applicant.businessInfo?.businessName ||
        displayName ||
        [givenName, middleName, familyName].filter(str => str).join(' ')
    }
  }

  const handleClick = () => {
    trackingManager.track(TrackingEventsTypes.NotificationClick)
  }

  const { update } = useSuprSendNotificationStateUpdate()

  useEffect(() => {
    update({
      n_id: notificationData.n_id,
      createdOn: notificationData.created_on,
    })
  }, [notificationData.created_on])

  // handle dont show notif for trust analysis when the feature flag is not enabled
  if (
    !trustAnalyser &&
    event === NotificationEventTypes.TRUST_ANALYSIS_COMPLETE
  )
    return null

  const getNotificationIcon = () => {
    let notificationIcons
    if (isBatchedEntity) {
      notificationIcons = notificationBatchIcons
    } else {
      notificationIcons =
        type === ApplicantEntityTypes.Organisation
          ? notificationBusinessIcons
          : notificationIndividualIcons
    }
    return notificationIcons[event as NotificationEventTypes]
  }

  let dateLabel = ''
  const { today, yesterday, older } = suprSendNotificationTimeLine || {}

  if (today?.n_id === notificationData.n_id) {
    dateLabel = t('title.today')
  } else if (yesterday?.n_id === notificationData.n_id) {
    dateLabel = t('title.yesterday')
  } else if (older?.n_id === notificationData.n_id) {
    dateLabel = t('title.older')
  }

  return (
    <FrankieLoader size="xs" loading={isLoading || isLoadingSingle}>
      <div>
        {dateLabel && (
          <div className="pl-5 pt-5 pb-2  text-tertiary-grey-800 font-semibold">
            {dateLabel}
          </div>
        )}
        <FrankieLink
          notALink={!entity_id}
          onClick={handleClick}
          state={{ text }}
          to={getApplicantPath(entity_id || '', {
            matchStatusFilter: (status
              ? matchStatusFilterMapping[status]
              : []
            ).toString(),
          })}
          className="flex flex-row p-4 gap-2 hover:bg-tertiary-grey-50"
          data-qa={notificationWidgetQa.notificationItem}
        >
          <div className="shrink-0">
            <FrankieAvatar
              name="avatar"
              size="lg"
              className="bg-tertiary-grey-100"
              icon={{
                size: 'md',
                name: getNotificationIcon(),
                className: 'text-tertiary-grey-700',
              }}
            />
          </div>
          <div className="basis-full">
            <div
              className="mb-1.5 leading-tight text-sm text-tertiary-grey-700"
              data-qa={notificationWidgetQa.notificationItemText}
            >
              {event ? (
                <Message
                  entityId={entity_id || ''}
                  name={isBatchedEntity ? text! : name}
                  event={event}
                  status={status}
                  documentName={document_name}
                />
              ) : (
                text
              )}
            </div>
            <div className="text-tertiary-grey-500 leading-none text-xs">
              {isToday(created_on)
                ? t('format.ago', {
                    date: formatDistanceToNow(new Date(created_on)),
                  })
                : formatDate(created_on, DateFormatTypes.FullMonthDate)}
            </div>
          </div>
          <div className="flex shrink gap-0.5  ">
            {!seen_on && (
              <div className="w-3 h-3 mt-2.5 rounded-full bg-primary-800" />
            )}
            <Menu
              onSelect={() => {
                if (notificationData.seen_on) {
                  markUnRead()
                } else {
                  markRead()
                }
              }}
              buttonClassName="rotate-90"
              options={[
                notificationData.seen_on
                  ? { label: 'Mark as unread', value: 'unRead' }
                  : { label: 'Mark as read', value: 'read' },
              ]}
            />
          </div>
        </FrankieLink>

        <FrankieDivider />
      </div>
    </FrankieLoader>
  )
}
