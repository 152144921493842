import { useQuery, useQueryClient } from '@tanstack/react-query'

import {
  InternationalReportType,
  getReportRequestIdQueryKey,
} from 'entities/organisation'

export const useReportRequestId = (
  reportType: InternationalReportType,
  entityId: string,
) => {
  const client = useQueryClient()
  const queryKey = getReportRequestIdQueryKey(reportType, entityId)

  return useQuery<string | null>({
    queryKey,
    queryFn: () => {
      const data = client.getQueryData<string>(queryKey)

      if (!data) return null

      return data
    },
  })
}
