import React from 'react'

import { FrankieButton } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'
import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'

import { useTriggerState } from 'shared/hooks'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { APPLICANT_TRANSACTIONS_KEY } from '../../applicant-transactions.key'
import { applicantTransactionsEn } from '../../locale/applicant-transactions.en'
import { TransactionTypes } from '../../model/applicant-transactions.model'
import { applicantCustomerTransactionsQa } from '../../qa/applicant-transactions.qa'
import { ApplicantTransactionsDataGrid } from '../applicant-transactions-data-grid/applicant-transactions-data-grid'

type Props = {
  applicantId: ApplicantId
  className?: string
}

export function ApplicantCustomerTransactions({
  applicantId,
  className = '',
}: Props) {
  const t = useI18n([APPLICANT_TRANSACTIONS_KEY], {
    keys: applicantTransactionsEn,
  })

  const [createOverlay, closeOverlay] = useOverlay()

  const { hasTxnPermissionCustomer } = useHasPermission({
    hasTxnPermissionCustomer: PermissionTypes.DeviceTransactionData,
  })

  const [refetchState, triggerRefetch] = useTriggerState()

  const handleViewAllActivities = () => {
    createOverlay(
      <ApplicantTransactionsDataGrid
        applicantId={applicantId}
        heading={t('heading.allActivities')}
        searchPlaceholder={t('field.sessionKey')}
        transactionType={[TransactionTypes.Customer]}
        hideBulkAction
        fullView
        invalidateData
      />,
      {
        className: 'p-0 h-[100vh] w-[100vw]',
        closeButtonClassName: '!top-4',
        onClose: () => {
          closeOverlay()
          triggerRefetch()
        },
      },
    )
  }

  if (!hasTxnPermissionCustomer) {
    return t('noAccess')
  }

  return (
    <div className={`${className}`}>
      <ApplicantTransactionsDataGrid
        className="mb-10"
        applicantId={applicantId}
        heading={t('heading.customer')}
        searchPlaceholder={t('field.sessionKey')}
        transactionType={[TransactionTypes.Customer]}
        needsAttention
        refetchState={refetchState}
        testId={{ container: applicantCustomerTransactionsQa.dataGrid }}
      />
      <div className="mb-10">
        <div className="pb-4 text-lg font-bold text-tertiary-grey-800">
          {t('heading.allActivities')}
        </div>
        <FrankieButton
          size="sm"
          className="!bg-mono-100 !outline-mono-50"
          onClick={handleViewAllActivities}
          testId={{ button: applicantCustomerTransactionsQa.btn }}
        >
          {t('viewAllActivities')}
        </FrankieButton>
      </div>
    </div>
  )
}
