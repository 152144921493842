import { useEntityDataQuery } from 'entities/entity'

import { I18nFunction, useI18n } from 'shared/i18n'

import { useAmlData } from './individual-aml-result.state'
import { INDIVIDUAL_AML_RESULT_KEY } from '../individual-aml-result.key'
import { individualAmlResultEn } from '../locale/individual-aml-result.en'

type LabelAndSrc = {
  t: I18nFunction<typeof individualAmlResultEn>
  entityId: string
  processResultId?: string
}

type SrcLabel = { label: string; value: string; isUrl?: boolean }

type SrcLabelGroup = { values: SrcLabel[]; title: string }[]

export type SrcLabelGroupType =
  | 'pepSrcLabel'
  | 'sanctionSrcLabel'
  | 'watchlistSrcLabel'

export const useLabelAndSrc = ({
  t,
  entityId,
  processResultId,
}: LabelAndSrc): Record<SrcLabelGroupType, SrcLabelGroup> => {
  const { amlData } = useAmlData({ entityId, processResultId })

  const { supplementaryData } = amlData
  const pepSrcLabel: SrcLabelGroup =
    supplementaryData?.pepData?.map(pep => ({
      title: pep.sourceName ?? '',
      values: [
        {
          label: t('tabMatchCard.countryCodes'),
          value: pep.countryCode ?? '',
        },
        {
          label: t('tabMatchCard.country'),
          value: pep.countryName ?? '',
        },
        {
          label: t('tabMatchCard.level'),
          value: `${pep.level ?? ''}`,
        },
        {
          label: t('tabMatchCard.srcUrl'),
          value: pep.sourceUrl ?? '',
          isUrl: true,
        },
        {
          label: t('tabMatchCard.politicalPosition'),
          value: pep.sourcePosition ?? '',
        },
        {
          label: t('tabMatchCard.startData'),
          value: pep.listingStart ?? '',
        },
        {
          label: t('tabMatchCard.endDate'),
          value: pep.listingEnd ?? '',
        },
        {
          label: t('tabMatchCard.imageUrl'),
          value: pep.imageUrl ?? '',
          isUrl: true,
        },
        {
          label: t('tabMatchCard.referenceDocs'),
          value: pep.referenceDocs?.at(0)?.url ?? '',
          isUrl: true,
        },
      ],
    })) ?? []

  const sanctionSrcLabel: SrcLabelGroup =
    supplementaryData?.sanctionData?.map(sanctions => ({
      title: sanctions.sourceName ?? '',
      values: [
        {
          label: t('tabMatchCard.countryCodes'),
          value: sanctions.countryCode ?? '',
        },
        {
          label: t('tabMatchCard.country'),
          value: sanctions.countryName ?? '',
        },
        {
          label: t('tabMatchCard.srcUrl'),
          value: sanctions.sourceUrl?.split(',')[0] ?? '',
          isUrl: true,
        },
        {
          label: t('tabMatchCard.srcReason'),
          value: sanctions.sourceReason ?? '',
        },
        {
          label: t('tabMatchCard.startData'),
          value: sanctions.listingStart ?? '',
        },
        {
          label: t('tabMatchCard.endDate'),
          value: sanctions.listingEnd ?? '',
        },

        {
          label: t('tabMatchCard.imageUrl'),
          value: sanctions.imageUrl ?? '',
          isUrl: true,
        },
        {
          label: t('tabMatchCard.referenceDocs'),
          value: sanctions.referenceDocs?.at(0)?.url ?? '',
          isUrl: true,
        },
      ],
    })) ?? []

  const watchlistSrcLabel: SrcLabelGroup =
    supplementaryData?.watchlistData?.map(watchList => ({
      title: watchList.sourceName ?? '',
      values: [
        {
          label: t('tabMatchCard.countryCodes'),
          value: watchList.countryCode ?? '',
        },
        {
          label: t('tabMatchCard.country'),
          value: watchList.countryName ?? '',
        },
        {
          label: t('tabMatchCard.srcUrl'),
          value: watchList.sourceUrl?.split(',')[0] ?? '',
          isUrl: true,
        },

        {
          label: t('tabMatchCard.startData'),
          value: watchList.listingStart ?? '',
        },
        {
          label: t('tabMatchCard.endDate'),
          value: watchList.listingEnd ?? '',
        },

        {
          label: t('tabMatchCard.imageUrl'),
          value: watchList.imageUrl ?? '',
          isUrl: true,
        },
        {
          label: t('tabMatchCard.referenceDocs'),
          value: watchList.referenceDocs?.at(0)?.url ?? '',
          isUrl: true,
        },
      ],
    })) ?? []

  return { pepSrcLabel, sanctionSrcLabel, watchlistSrcLabel }
}

type SummaryLabelProps = {
  entityId: string
  processResultId?: string
}

export const useLabelAndSrcForSummary = ({
  entityId,
  processResultId,
}: SummaryLabelProps) => {
  const { amlData } = useAmlData({ entityId, processResultId })
  const { data: entity } = useEntityDataQuery(entityId, 'base64')
  const t = useI18n([INDIVIDUAL_AML_RESULT_KEY], {
    keys: individualAmlResultEn,
  })
  const { supplementaryData } = amlData
  return [
    {
      label: t('individualAmlResolveSingleSummary.fullName'),
      value: entity?.individual?.name?.displayName,
    },
    {
      label: t('individualAmlResolveSingleSummary.yearOfBirth'),
      value: entity?.individual?.dateOfBirth?.normalized,
    },
    {
      label: t('individualAmlResolveSingleSummary.countryOfResidence'),
      value: amlData.notes?.['aml.search_countries']?.value,
    },

    {
      label: t('individualAmlResolveSingleSummary.residentialAddress'),
      value: amlData.notes?.['aml.address.1']?.value,
    },
    {
      label: t('individualAmlResolveSingleSummary.previousAddresses'),
      value: '121 King St, Greenway ACT 2900, Australia',
    },

    {
      label: t('individualAmlResolveSingleSummary.fuzziness'),
      value: supplementaryData?.fuzziness?.normalized ?? '-',
    },
    {
      label: t('individualAmlResolveSingleSummary.vendorId'),
      value: 'becbeceb-9c55-0371-a63c-985c4a79729f',
    },
  ]
}
